import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { colors } from '../../styles/colors';

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }
  onClickEvent() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
  render() {
    const { name, meta, children, isPrimary, clickable, clicked, classes } = this.props;
    const styles = classes;
    return (
      <div
        onClick={() => {
          if (clickable) {
            this.onClickEvent();
          }
        }}
        className={clicked ? styles.clicked : styles.info}
      >
        <span className={classnames(styles.name, isPrimary && styles.bold)}>
          {name}
          {meta ? <span className={styles.meta}> ({meta})</span> : undefined}
        </span>
        {isPrimary && <span className={styles.primary}>(Primary)</span>}
        <div className={styles.right}>{children}</div>
      </div>
    );
  }
}

const styles = {
  info: {
    fontSize: 12,
    display: 'flex',
    padding: '10px 0',
    borderBottom: `1px dotted ${colors.infoBorder}`,
  },
  clicked: {
    fontSize: 12,
    display: 'flex',
    padding: '10px 0',
    borderBottom: `1px dotted ${colors.infoBorder}`,
    background: 'rgba(113,186,83, 0.25)',
  },
  primary: {
    marginLeft: 5,
  },
  name: {
    color: colors.text,
    fontWeight: 300,
  },
  meta: {
    color: colors.lightText,
  },
  right: {
    marginLeft: 'auto',
    paddingLeft: 15,
  },
  bold: {
    fontWeight: 'normal',
  },
};

// eslint-disable-next-line no-class-assign
Info = withStyles(styles)(Info);
export { Info };
