import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { createTheme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { grey } from '@material-ui/core/colors';

const DeskSelector = ({ desk, setDesk, desks = [], styles }) => {
  const handleDeskChange = event => {
    const { value } = event.target;
    const d = desks.find(desk => {
      return desk._id === value;
    });
    setDesk(d);
  };

  return (
    <ThemeProvider theme={theme}>
      <TextField
        select
        name="DeskSelector"
        variant="outlined"
        label="Desks"
        fullWidth
        value={desk._id}
        onChange={handleDeskChange}
        className={styles.deskSelect}
      >
        <MenuItem value={(desk || {})._id} disabled>
          {(desk || {}).name || 'No Desk'}
        </MenuItem>
        {desks
          .filter(availableDesk => availableDesk.name !== (desk || {}).name)
          .map(d => (
            <MenuItem key={d._id} value={d._id} className={styles.selectItem}>
              {d.name}
            </MenuItem>
          ))}
      </TextField>
    </ThemeProvider>
  );
};

const theme = createTheme({
  palette: {
    primary: grey,
  },
  overrides: {
    MuiSelect: {
      select: {
        color: '​#808080',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#808080',
        letterSpacing: -0.25,
      },
    },
    MuiOutlinedInput: {
      root: {
        color: 'black',
      },
    },
  },
});

export default DeskSelector;
