import React from 'react';
import { Helmet } from 'react-helmet';
import { styled, helpers } from 'react-free-style';
import { withRouter } from 'react-router';

import { elements } from '@united-talent-agency/julius-frontend-components';

const Login = ({ defaultUrl, apiServerUrl, redirectToAzure, styles }) => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <form className={styles.form}>
        <button
          id="login"
          className={styles.button}
          onClick={e => {
            e.preventDefault();
            redirectToAzure(null, null, defaultUrl, apiServerUrl);
          }}
        >
          Log In
        </button>
      </form>
    </div>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 260,
  },
  item: {
    margin: 5,
  },
  button: helpers.merge({ width: '100%' }, elements.button, elements.actionable),
});

export default withRouter(withStyles(Login));
