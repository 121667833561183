export default [
  'Financing',
  'Packaging/Casting',
  'In Production',
  'Sales in Progress',
  'Sold',
  'Development/Tracking',
  'Other Projects',
  'Financed Films/Upcoming Sales',
];
