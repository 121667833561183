import React from 'react';
import { EditButton, colors, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import SalesFinancingItem from './sales-financing-item';
import { styled } from 'react-free-style';
import classnames from 'classnames';

const CardHeader = ({ styles, isDirty, onCancel, onSave, errors }) => (
  <div className={classnames(styles.header, isDirty ? styles.headerEdit : undefined)}>
    <h3 className={styles.title}>Sales and Financing</h3>
    {isDirty && onCancel && <EditButton type={buttonTypes.cancel} className={styles.icon} onClick={onCancel} />}
    {isDirty && onSave && Object.keys(errors).length === 0 && (
      <EditButton
        type={buttonTypes.save}
        className={styles.icon}
        onClick={() => {
          onSave();
        }}
      />
    )}
  </div>
);

const ColumnHeaders = () => (
  <div
    style={{
      fontSize: 12,
      fontWeight: 100,
      marginTop: 2,
      color: 'gray',
      width: '100%',
      display: 'flex',
      marginLeft: 20,
    }}
  >
    <span style={{ flex: 0.4 }}>TYPE</span>
    <span style={{ width: 0.6 }}>COMPANY</span>
  </div>
);

const SalesAndFinancingEntry = ({ item, index, onChanged, onDelete, onSearchCompanies, styles, errors }) => (
  <React.Fragment>
    <hr style={{ marginTop: 0, marginBottom: 0 }} />
    <SalesFinancingItem
      key={`sales-financing-${index}`}
      company={item.company}
      onCompanyChanged={value => {
        onChanged && onChanged('company', value, index);
      }}
      relationshipType={item.relationshipType}
      onRelationshipTypeChanged={value => {
        onChanged && onChanged('relationshipType', value, index);
      }}
      onDelete={
        onDelete &&
        (() => {
          onDelete(index);
        })
      }
      onSearchCompanies={onSearchCompanies}
    />
    {errors[index] &&
      errors[index].map((err, errIndex) => {
        return (
          <div key={`${index}-${errIndex}`} className={styles.errorText}>
            {err}
          </div>
        );
      })}
  </React.Fragment>
);

const SalesFinancingCard = props => {
  const { items = [], onSave, onCancel, onChanged, styles, isDirty, onDelete, onSearchCompanies } = props;

  const errors = items.reduce((obj, cur, index) => {
    const curErrors = [];
    if (cur.relationshipType && (!cur.company || cur.company._id.trim() === '')) {
      curErrors.push('Company Name is Required');
    }
    if (curErrors.length > 0) {
      obj[index] = curErrors;
    }
    return obj;
  }, {});

  const itemsWithNew = items;
  if (
    !itemsWithNew.some(i => {
      return i.company === '' && i.relationshipType === '';
    })
  ) {
    itemsWithNew.push({ company: '', relationshipType: '' });
  }

  return (
    <div className={styles.pane}>
      <CardHeader styles={styles} errors={errors} onSave={onSave} onCancel={onCancel} isDirty={isDirty} />
      <ColumnHeaders />
      <div className={styles.body}>
        {itemsWithNew.map((item, index) => (
          <SalesAndFinancingEntry
            index={index}
            errors={errors}
            key={index}
            item={item}
            onChanged={(field, text, index) => {
              item[field] = text;
              onChanged && onChanged(item, index);
            }}
            onDelete={
              index < itemsWithNew.length - 1 &&
              (index => {
                onDelete && onDelete(index);
              })
            }
            onSearchCompanies={onSearchCompanies}
            styles={styles}
          />
        ))}
      </div>
    </div>
  );
};

const withStyles = styled({
  pane: {
    background: colors.contentBackground,
    marginBottom: 20,
  },
  button: {
    float: 'right',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px 0px 15px',
  },
  headerEdit: {
    background: '#90E2D3',
  },
  body: {
    padding: '5px 15px',
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    margin: '5px 0',
    textTransform: 'uppercase',
  },
  create: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 10px 20px',
  },
  icon: {
    marginLeft: 10,
  },
  errorText: {
    color: 'red',
  },
});

export default withStyles(SalesFinancingCard);
