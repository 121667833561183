import React, { Fragment } from 'react';
import { EditButton, colors, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import { ErrorSummary } from '@united-talent-agency/components';
import ItemSelector from '../item-selector/item-selector';
import { styled } from 'react-free-style';
import classnames from 'classnames';

const Footer = ({ onClick, styles }) => (
  <div className={styles.create}>
    <EditButton type={buttonTypes.new} onClick={onClick} />
  </div>
);

const ItemListCard = props => {
  const {
    items,
    finder,
    title,
    onNew,
    onSave,
    onCancel,
    onChange,
    onSelect,
    styles,
    isDirty,
    onDelete,
    onPrimaryChange,
    invalidItemInCard,
  } = props;
  const [searchIndex, setSearchIndex] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState(null);

  const renderHeader = () => {
    return (
      <div className={classnames(styles.header, isDirty ? styles.headerEdit : undefined)}>
        <h3 className={styles.title}>{title}</h3>
        {isDirty && onCancel && (
          <EditButton
            type={buttonTypes.cancel}
            className={styles.icon}
            onClick={() => {
              onCancel();
            }}
          />
        )}
        {isDirty && onSave && (
          <EditButton
            type={buttonTypes.save}
            className={styles.icon}
            disabled={invalidItemInCard}
            onClick={() => {
              onSave();
            }}
          />
        )}
        {!isDirty && onPrimaryChange && <h3 className={classnames(styles.title, styles.primaryHeader)}>Primary</h3>}
      </div>
    );
  };

  return (
    <div className={styles.pane}>
      {renderHeader()}
      <div className={styles.body}>
        {_buildItemRows({
          items,
          searchResults,
          onChange: (itemName, index) => {
            finder(itemName).then(results => {
              setSearchResults(results);
            });
            onChange && onChange(itemName, index);
          },
          onSelect: (item, index) => {
            setSearchResults([]);
            onSelect && onSelect(item, index);
          },
          searchIndex,
          setSearchIndex,
          onDelete,
          title: _getSingular(title),
          onPrimaryChange,
        })}
      </div>
      {onNew && <Footer onClick={() => onNew()} styles={styles} />}
    </div>
  );
};

const _getSingular = text => {
  if (text.endsWith('ies')) {
    return text.slice(0, -3) + 'y';
  } else if (text.endsWith('s')) {
    return text.slice(0, -1);
  } else {
    return text;
  }
};

const _buildItemRows = ({
  items,
  searchResults,
  onChange,
  onSelect,
  searchIndex,
  setSearchIndex,
  onDelete,
  title,
  onPrimaryChange,
}) => {
  const itemsWithNew = items ? JSON.parse(JSON.stringify(items)) : [];
  if (
    !itemsWithNew.some(i => {
      return i._id === '';
    })
  ) {
    itemsWithNew.push({ _id: '', name: '' });
  }
  const setPrimaryIndex = items.findIndex(item => item.primary);
  const primaryIndex = setPrimaryIndex >= 0 ? setPrimaryIndex : 0;
  const primaryEnabled = itemsWithNew.length > 2;
  return (
    <div>
      {itemsWithNew.map((item, index) => {
        const invalidItem = !item._id && item.name && searchResults && searchResults.length < 1;
        return _buildItemInfo({
          invalid: invalidItem,
          itemName: item.name,
          index,
          searchResults: index === searchIndex && searchResults,
          onChange: (text, index) => {
            setSearchIndex(index);
            onChange(text, index);
          },
          onSelect: (item, index) => {
            onSelect(item, index);
          },
          onDelete,
          title,
          onPrimaryChange:
            index < itemsWithNew.length - 1 &&
            (index => {
              onPrimaryChange(index);
            }),
          primary: primaryIndex === index,
          primaryEnabled,
        });
      })}
    </div>
  );
};

const _buildItemInfo = ({
  invalid,
  itemName,
  index,
  searchResults,
  onChange,
  onSelect,
  onDelete,
  title,
  onPrimaryChange,
  primary,
  primaryEnabled,
}) => {
  return (
    <Fragment key={`item-${index}-frag`}>
      <hr key={`item-${index}-divider`} style={{ marginTop: 4, marginBottom: 4 }} />
      <ErrorSummary message={invalid ? 'Invalid Agent Assignment' : ''} style={{ width: '100%' }}>
        <ItemSelector
          title={title}
          key={`item-${index}`}
          text={itemName}
          short={true}
          items={searchResults}
          onSelect={item => {
            onSelect(item, index);
          }}
          onChange={text => {
            onChange(text, index);
          }}
          onDelete={
            onDelete &&
            itemName &&
            itemName.trim() !== '' &&
            function() {
              onDelete(index);
            }
          }
          placeholder={`Add ${title}`}
          onBlurComplete
          primary={primary}
          invalid={invalid}
          primaryEnabled={primaryEnabled}
          onPrimaryChange={onPrimaryChange && (() => onPrimaryChange(index))}
        />
      </ErrorSummary>
    </Fragment>
  );
};

const withStyles = styled({
  pane: {
    background: colors.contentBackground,
    marginBottom: 20,
  },
  button: {
    float: 'right',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px 0px 15px',
  },
  headerEdit: {
    background: '#90E2D3',
  },
  body: {
    padding: '5px 15px',
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    margin: '5px 0',
    textTransform: 'uppercase',
  },
  create: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 10px 20px',
  },
  icon: {
    marginLeft: 10,
  },
  primaryHeader: {
    textAlign: 'right',
    marginRight: '30px !important',
  },
});

export default withStyles(ItemListCard);
