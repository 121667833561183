export default ['Cameo', 'Ensemble', 'Guest Star', 'Lead', 'Recurring', 'Series Regular', 'Supporting'];

export const castingTypesByProjectType = {
  Any: [
    'Lead',
    'Supporting',
    'Series Regular',
    'Series: Lead',
    'Series: Supporting',
    'Recurring',
    'Cameo',
    'Guest Star',
  ],
  Feature: ['Lead', 'Supporting', 'Cameo', 'Guest Star'],
  Television: ['Lead', 'Recurring', 'Cameo', 'Guest Star'],
  Theatre: ['Lead', 'Supporting'],
  Unscripted: ['Cameo', 'Ensemble', 'Guest Star', 'Lead', 'Recurring', 'Series Regular', 'Supporting'],
};
