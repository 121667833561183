import React, { useState, useEffect, useCallback } from 'react';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import TextareaAutosize from 'react-autosize-textarea';

import { getGroups, groupRoles } from '../../../../../api/groups';
import { rolesByProjectType } from '../../../../../support/items/roles';
import { statusesByProjectType } from '../../../../../support/items/statuses';
import { personProfileLink } from '../../../../../support/person';
import { companyProfileLink } from '../../../../../support/company';
import { optionIsValidCreation } from '../../../../../support/creatableCompanyFilter';
import { searchClient, searchObject } from '../../../../../support/algolia/algolia-search-client';
import { getPeopleIndex } from '../../../../../support/algolia/get-index';
import Verification from '../../../../../components/verification/verification';

import LaunchDarklyWrapper from '../../../../../components/LDWrapper/LaunchDarklyWrapper';
import { getCompaniesIndex } from '../../../../../support/algolia/get-index';
import { iconStyles, userOptionsStyles, getUserTypeStyles } from '../../../../../support/user-type-util';

export const LinkToProfilePage = ({ person, linkClassName, onClick }) => {
  const personLink = personProfileLink(person, false);

  return (
    <a
      href={personLink}
      target="_blank"
      tabIndex={-1}
      rel="noopener noreferrer"
      className={linkClassName}
      onClick={() => {
        setTimeout(onClick, 200);
      }}
    >
      {person.name}
    </a>
  );
};
export const PersonLink = ({ person, linkClassName, onClick = () => {}, onLinkClick = () => {} }) => {
  if (person) {
    return person.emphasizeRolePerson ? (
      <div style={{ fontWeight: 'bold' }} onClick={onClick}>
        <LinkToProfilePage person={person} linkClassName={linkClassName} onClick={onLinkClick} />
      </div>
    ) : (
      <div onClick={onClick}>
        <LinkToProfilePage person={person} linkClassName={linkClassName} onClick={onLinkClick} />
      </div>
    );
  }
  return <div onClick={onClick} />;
};

export const LinkToCompanyProfilePage = ({ companyId, linkClassName, onClick }) => {
  const companyLink = companyProfileLink(companyId, false);

  return (
    <a
      href={companyLink}
      target="_blank"
      tabIndex={-1}
      rel="noopener noreferrer"
      className={linkClassName}
      onClick={() => {
        setTimeout(onClick, 200);
      }}
    >
      {companyId.name}
    </a>
  );
};

export const CompanyLink = ({ companyId, linkClassName, onClick, onLinkClick }) => {
  if (companyId) {
    return (
      <div onClick={onClick}>
        <LinkToCompanyProfilePage companyId={companyId} linkClassName={linkClassName} onClick={onLinkClick} />
      </div>
    );
  }
  return <div onClick={onClick} />;
};

const selectFieldStyle = ({
  controlStyles = {},
  indicatorsContainerStyles,
  placeholderStyles,
  menuListStyles,
  singleValueStyles,
}) => ({
  container: provided => ({
    ...provided,
    marginRight: '3px',
  }),
  control: provided => ({
    ...provided,
    borderRadius: 0,
    borderColor: 'rgba(224, 224, 224, 1)',
    cursor: 'pointer',
    '&:hover': {
      borderColor: controlStyles.borderColor || 'rgba(224, 224, 224, 1)',
    },
    fontSize: '14px',
    backgroundColor: '',
    color: '',
    height: '40px',
    ...controlStyles,
  }),
  menuList: provided => ({
    ...provided,
    fontSize: '14px',
    ...menuListStyles,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    ...indicatorsContainerStyles,
  }),
  placeholder: provided => ({
    ...provided,
    ...placeholderStyles,
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer',
  }),
  singleValue: provided => ({
    ...provided,
    color: '',
    transform: 'none',
    top: 6,
    ...singleValueStyles,
  }),
});

export const TextInput = ({ value, onBlur, styles, onChange }) => {
  return (
    <TextareaAutosize
      onFocus={e => {
        //move cursor to end if tabbing into field
        if (e.target.selectionStart === 0) {
          e.target.selectionStart = e.target.value.length;
          e.target.selectionEnd = e.target.value.length;
        }
      }}
      style={styles}
      value={value}
      onChange={e => onChange(e.target.value)}
      onBlur={() => onBlur()}
    />
  );
};

const CustomOption = props => {
  const { data, selectProps } = props;
  const selectedValues = (selectProps.value || []).map(({ value }) => value);
  const display =
    selectedValues.length > 0 && selectedValues.indexOf(data.value) !== -1 ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <i className="fa fa-check" aria-hidden="true" style={{ marginRight: '3px' }} />
        {data.label}
      </div>
    ) : (
      data.label
    );
  return <components.Option {...props}>{display}</components.Option>;
};

export const PositionsInput = ({
  onSelect,
  isLoading,
  type,
  flex,
  selectedOptions,
  disabledOptions = [],
  placeholder = 'Auspice',
  onBlur,
  onFocus,
  controlStyles = {},
  indicatorsContainerStyles = {},
  menuListStyles = {},
  placeholderStyles = {},
  showError = false,
  errorMessage = '',
  dataCy,
}) => {
  const availableRoles = (rolesByProjectType[type] || []).map(role => ({
    value: role,
    label: role,
  }));

  return (
    <div style={{ flex }}>
      <div
        onBlur={e => {
          onBlur && onBlur(e);
        }}
        onFocus={e => {
          onFocus && onFocus(e);
        }}
        data-cy={dataCy}
      >
        <Select
          title={placeholder}
          options={availableRoles}
          onChange={onSelect}
          disabledOptions={disabledOptions}
          hideSelectedOptions={false}
          loading={isLoading}
          isMulti
          styles={selectFieldStyle({ controlStyles, indicatorsContainerStyles, placeholderStyles, menuListStyles })}
          isDisabled={isLoading}
          placeholder={placeholder}
          noOptionsMessage={() => 'No matching position found'}
          controlShouldRenderValue={false}
          value={selectedOptions}
          isOptionDisabled={option => {
            return disabledOptions.length > 0 && disabledOptions.indexOf(option.value) !== -1;
          }}
          components={{ Option: CustomOption }}
        />
        {showError && <div className="text-danger">{errorMessage}</div>}
      </div>
      <div style={{ marginTop: 8 }}>
        {(selectedOptions || []).map(({ value, label }, idx) => {
          if (disabledOptions.length > 0 && disabledOptions.indexOf(value) !== -1) {
            const newItems = selectedOptions.filter(selectedOption => selectedOption.value !== value);
            onSelect(newItems);
            return null;
          }
          return (
            <div key={idx} style={{ display: 'inline-block', marginRight: 5 }}>
              <input
                checked
                readOnly
                type="checkbox"
                style={{ marginRight: 3 }}
                onClick={() => {
                  const newItems = selectedOptions.filter(selectedOption => selectedOption.value !== value);
                  onSelect(newItems);
                }}
              />
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const PositionInput = ({
  onChange,
  isLoading,
  type,
  flex,
  value,
  placeholder = 'Auspice',
  onBlur,
  onFocus,
  controlStyles = {},
  indicatorsContainerStyles = {},
  menuListStyles = {},
  placeholderStyles = {},
  showError = false,
  errorMessage = '',
}) => {
  const availableRoles = (rolesByProjectType[type] || []).map(role => ({
    value: role,
    label: role,
  }));

  return (
    <div
      style={{ flex }}
      onBlur={e => {
        onBlur && onBlur(e);
      }}
      onFocus={e => {
        onFocus && onFocus(e);
      }}
    >
      <Select
        onChange={onChange}
        options={availableRoles}
        styles={selectFieldStyle({ controlStyles, indicatorsContainerStyles, placeholderStyles, menuListStyles })}
        isLoading={isLoading}
        isDisabled={isLoading}
        value={value && { value, label: value }}
        placeholder={placeholder}
        noOptionsMessage={() => 'No matching position found'}
      />
      {showError && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
};

export const StatusInput = ({
  flex,
  onChange,
  isLoading,
  type,
  value,
  placeholder = 'Status',
  onBlur,
  onFocus,
  controlStyles = {},
  indicatorsContainerStyles = {},
  placeholderStyles = {},
  menuListStyles = {},
  errorMessage = '',
  showError,
  dataCy,
}) => {
  const availableStatuses = (statusesByProjectType[type] || []).map(status => ({
    value: status,
    label: status,
  }));
  return (
    <div
      style={{ flex }}
      onBlur={e => {
        onBlur && onBlur(e);
      }}
      onFocus={e => {
        onFocus && onFocus(e);
      }}
      data-cy={dataCy}
    >
      <Select
        onChange={onChange}
        options={availableStatuses}
        styles={selectFieldStyle({ controlStyles, indicatorsContainerStyles, placeholderStyles, menuListStyles })}
        isLoading={isLoading}
        isDisabled={isLoading}
        value={value && { value, label: value }}
        placeholder={placeholder}
        noOptionsMessage={() => 'No matching status found'}
      />
      {showError && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
};

const AlgoliaTalentAgencyInput = ({
  onChange,
  isLoading,
  value,
  flex,
  onBlur,
  linkClassName,
  placeholder = 'Agency',
  controlStyles = {},
  indicatorsContainerStyles = {},
  placeholderStyles = {},
  hidePlaceholder,
  menuListStyles = {},
  singleValueStyles = {},
  errorMessage = '',
  showError,
  disableCompanyProfileLink,
  dataCy,
}) => {
  const [synonym, setSynonym] = useState('');

  const fetchTalentAgencies = name => {
    name = name.trim();

    const index = getCompaniesIndex();
    const filters = '(roles:talentAgency)';
    // the synonym works only for one way algolia synonyms
    index.searchSynonyms(name).then(({ hits }) => {
      setSynonym(hits[0]?.input);
    });
    return searchClient(index, name, { filters }).then(results => {
      return results.hits?.map(company => ({ value: company, label: company.name })) || [];
    });
  };

  const determineAgencyDropdownLabel = value =>
    disableCompanyProfileLink ? (
      value.name
    ) : (
      <CompanyLink linkClassName={linkClassName} companyId={value} onClick={() => {}} onLinkClick={() => {}} />
    );

  const emptyAgencyValue = hidePlaceholder ? { value: '', label: '' } : '';
  const computedAgencyValue = value
    ? {
        value,
        label: determineAgencyDropdownLabel(value),
      }
    : emptyAgencyValue;

  return (
    <div style={{ flex }} onBlur={onBlur} data-cy={dataCy}>
      <AsyncCreatableSelect
        onChange={onChange}
        isClearable
        cacheOptions
        escapeClearsValue
        loadOptions={fetchTalentAgencies}
        styles={selectFieldStyle({
          controlStyles,
          indicatorsContainerStyles,
          placeholderStyles,
          menuListStyles,
          singleValueStyles,
        })}
        value={computedAgencyValue}
        isLoading={isLoading}
        isDisabled={isLoading}
        placeholder={placeholder}
        noOptionsMessage={() => 'Please search for a talent agency'}
        isValidNewOption={(inputValue, _values, options) => optionIsValidCreation(inputValue, options, synonym)}
      />
      {showError && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
};

const CurrentTalentAgencyInput = ({
  onChange,
  isLoading,
  value,
  defaultAgencies = [],
  flex,
  onBlur,
  linkClassName,
  placeholder = 'Agency',
  controlStyles = {},
  indicatorsContainerStyles = {},
  placeholderStyles = {},
  hidePlaceholder,
  menuListStyles = {},
  singleValueStyles = {},
  errorMessage = '',
  showError,
  disableCompanyProfileLink,
  dataCy,
}) => {
  const [talentAgencies, setTalentAgencies] = useState([]);
  const fetchTalentAgencies = name => {
    name = name.trim();
    if (name === '') {
      setTalentAgencies([]);
      return;
    }
    const role = groupRoles['Talent Agencies'];
    getGroups(name, { role }).then(({ data = [] }) =>
      setTalentAgencies(data.map(agency => ({ value: agency, label: agency.name })))
    );
  };

  const determineAgencyDropdownLabel = value =>
    disableCompanyProfileLink ? (
      value.name
    ) : (
      <CompanyLink linkClassName={linkClassName} companyId={value} onClick={() => {}} onLinkClick={() => {}} />
    );

  const emptyAgencyValue = hidePlaceholder ? { value: '', label: '' } : '';
  const computedAgencyValue = value
    ? {
        value,
        label: determineAgencyDropdownLabel(value),
      }
    : emptyAgencyValue;

  return (
    <div style={{ flex }} onBlur={onBlur} data-cy={dataCy}>
      <Creatable
        onChange={onChange}
        options={
          talentAgencies && talentAgencies.length > 0
            ? talentAgencies
            : defaultAgencies.map(agency => ({ value: agency, label: agency.name }))
        }
        isClearable
        escapeClearsValue
        onInputChange={fetchTalentAgencies}
        styles={selectFieldStyle({
          controlStyles,
          indicatorsContainerStyles,
          placeholderStyles,
          menuListStyles,
          singleValueStyles,
        })}
        isLoading={isLoading}
        isDisabled={isLoading}
        value={computedAgencyValue}
        placeholder={placeholder}
        noOptionsMessage={() => 'Please search for a talent agency'}
        isValidNewOption={(inputValue, _values, options) => optionIsValidCreation(inputValue, options)}
      />
      {showError && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
};

export const TalentAgencyInput = props => {
  return (
    <LaunchDarklyWrapper
      render={flags => {
        return (
          <>
            {flags?.algoliaCompanySearch ? (
              <AlgoliaTalentAgencyInput {...props} />
            ) : (
              <CurrentTalentAgencyInput {...props} />
            )}
          </>
        );
      }}
    />
  );
};

export const PersonInput = ({
  onChange,
  isLoading,
  value,
  flex,
  onBlur,
  linkClassName,
  placeholder = 'Person',
  controlStyles = {},
  indicatorsContainerStyles = {},
  placeholderStyles = {},
  hidePlaceholder,
  menuListStyles = {},
  singleValueStyles = {},
  errorMessage = '',
  showError,
  disableProfileLink,
  dataCy,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value && value?.verifiedBy) {
      fetchData();
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const getVerificationData = async () => {
    const index = getPeopleIndex();
    const res = await searchObject(index, value._id);
    return res;
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await getVerificationData();
    setData(data);
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchPeople = (query, cb) => {
    const index = getPeopleIndex();
    const indexSetting = {
      filters: '(projectRoles.k: staff)',
      hitsPerPage: 20,
      page: 0,
      restrictSearchableAttributes: ['name'],
    };
    searchClient(index, query, {
      ...indexSetting,
    }).then(res => {
      const data = res.hits.map(person => ({ value: person, label: person.name }));
      cb(data);
    });
  };

  const determineDropdownLabel = value => {
    return disableProfileLink ? (
      <div className="d-flex">
        {value.name}
        {data && <Verification data={data} containerStyles="ml-1" />}
      </div>
    ) : (
      <div className="d-flex">
        <PersonLink linkClassName={linkClassName} person={value} onClick={() => {}} onLinkClick={() => {}} />
        {data && <Verification data={data} containerStyles="ml-1" />}
      </div>
    );
  };

  const emptyValue = hidePlaceholder ? { value: '', label: '' } : '';
  const computedValue = value
    ? {
        value,
        label: determineDropdownLabel(value),
      }
    : emptyValue;

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ flex }} onBlur={onBlur}>
          {''}
        </div>
      ) : (
        <div style={{ flex }} onBlur={onBlur} data-cy={dataCy}>
          <AsyncCreatableSelect
            cacheOptions
            onChange={onChange}
            loadOptions={fetchPeople}
            styles={selectFieldStyle({
              controlStyles,
              indicatorsContainerStyles,
              placeholderStyles,
              menuListStyles,
              singleValueStyles,
            })}
            isLoading={isLoading}
            isDisabled={isLoading}
            value={computedValue}
            placeholder={!hidePlaceholder && placeholder}
            noOptionsMessage={() => 'Please search for a person'}
            components={{ Option: CustomPersonLabel }}
          />
          {showError && <div className="text-danger">{errorMessage}</div>}
        </div>
      )}
    </React.Fragment>
  );
};

const CustomPersonLabel = props => {
  const { data } = props;
  const label = data.label;
  const firstLetter = data && data?.value?.type?.charAt(0).toUpperCase();
  const isVerified = data?.value?.verifiedBy;

  return (
    <components.Option {...props}>
      <div className="d-flex flex-row align-items-baseline flex-nowrap">
        {firstLetter && (
          <div className={userOptionsStyles.icon}>
            <span
              onClick={() => {}}
              style={{
                ...iconStyles,
                ...{
                  color: getUserTypeStyles(firstLetter)?.color,
                  backgroundColor: getUserTypeStyles(firstLetter)?.background,
                },
              }}
            >
              {firstLetter}
            </span>
          </div>
        )}
        <div className={`${userOptionsStyles.label} ml-1`}>{label}</div>
        {isVerified && <Verification data={data.value} containerStyles="ml-2" />}
      </div>
    </components.Option>
  );
};
