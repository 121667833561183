import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { isEmpty } from 'lodash';
import Modal from 'react-modal';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import { grey, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { ArchiveButton, IconButton, EditButton, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import { loadIntercom } from '../../support/intercom-loader';
import Tooltip from '@material-ui/core/Tooltip';
import cypressTags from '../../support/cypressTags';

Modal.setAppElement(document.getElementById('delete-project-modal'));

export class Title extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSaved: false,
      isEditing: false,
      name: this.props.project ? this.props.project.name : '',
      missingNameInput: false,
      showDeleteModal: false,
      intercomBooted: false,
    };
  }

  render() {
    const { styles, editing, project, handleDeleteProject, user = {}, _loadIntercom = loadIntercom } = this.props;
    const { isEditing, name, missingNameInput, showDeleteModal, intercomBooted } = this.state;
    const isStrikeout = project && !project.active ? styles.strikeout : '';
    const isBold = project && project.newInformation ? styles.bold : '';

    if (!isEmpty(user) && !intercomBooted) {
      _loadIntercom(user.first_name, user.last_name, user.email);
      this.setState({ intercomBooted: true });
    }

    if (isEditing || editing) {
      return (
        <div className={styles.container}>
          <ThemeProvider theme={theme}>
            <div className={styles.projectNameInput} data-cy={cypressTags.PROJECT.PROJECT_NAME_EDIT_INPUT}>
              <TextField
                value={name}
                error={missingNameInput}
                id="project-name"
                label="Name"
                placeholder="Name"
                margin="dense"
                variant="outlined"
                onChange={event => {
                  this.setState({ name: event.target.value.trimLeft(), missingNameInput: false });
                }}
              />
            </div>
          </ThemeProvider>
          <div>
            <EditButton type={buttonTypes.cancel} className={styles.icon} onClick={() => this.cancel()} />
          </div>
          <Tooltip
            title={
              this.isValidUTF8(this.state.name) ? (
                <h4 style={{ fontSize: '18px' }}>
                  Project name cannot contain restricted characters like: é, ä, ô, “ etc. Please remove them to
                  continue.
                </h4>
              ) : (
                ''
              )
            }
          >
            <span data-cy={cypressTags.PROJECT.PROJECT_NAME_EDIT_SAVE_BUTTON}>
              <EditButton
                type={buttonTypes.save}
                className={styles.icon}
                onClick={() => this.save()}
                disabled={this.isValidUTF8(this.state.name)}
              />
            </span>
          </Tooltip>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <span data-cy={cypressTags.PROJECT.PROJECT_TITLE} className={classnames(styles.name, isStrikeout, isBold)}>
          {name}
        </span>
        <span data-cy={cypressTags.PROJECT.PROJECT_NAME_EDIT_BUTTON}>
          <EditButton
            type={buttonTypes.edit}
            className={styles.icon}
            onClick={() => {
              this.setState({ isEditing: true });
              this.props.setEdit(true);
            }}
          />
        </span>

        <div className={styles.toggleButtons}>
          <IconButton
            onClick={() => this.setState({ showDeleteModal: true })}
            falseIcon="fa-trash"
            falseText="DELETE"
          />
          <ArchiveButton isArchived={project ? !project.active : false} onClick={() => this.toggleArchive()} />
          <IconButton
            toggle={project ? project.newInfo : false}
            onClick={() => this.toggleNewInformation()}
            falseText="New Information"
            falseIcon="fa-square-o"
            trueText="New Information"
            trueIcon="fa-check-square-o"
          />
        </div>
        <DeleteModal
          showDeleteModal={showDeleteModal}
          closeDeleteModal={() => this.setState({ showDeleteModal: false })}
          handleDelete={handleDeleteProject}
        />
        <div id="delete-project-modal" />
      </div>
    );
  }

  cancel() {
    this.setState(resetState(this.props));
    this.props.setEdit(false);
  }

  save() {
    const { project } = this.props;

    if (!this.state.name) {
      this.setState({ missingNameInput: true });
    } else {
      if (this.state.name !== project.name) {
        const changeToSave = { name: this.state.name };
        this.saveChanges(changeToSave);
      }

      this.setState({ isEditing: false });
      this.props.setEdit(false);
    }
  }
  isValidUTF8 = str => {
    try {
      decodeURIComponent(escape(str));
      return false;
    } catch (e) {
      return true;
    }
  };

  toggleArchive() {
    const { project } = this.props;
    const changeToSave = { active: !project.active };
    this.saveChanges(changeToSave);
  }

  toggleNewInformation() {
    const { project } = this.props;
    const changeToSave = { newInfo: !project.newInfo };
    this.saveChanges(changeToSave);
  }

  saveChanges(change) {
    const { project, saveChanges } = this.props;
    saveChanges({
      updates: {
        project: {
          [project._id]: change,
        },
      },
    });
  }
}

const DeleteModal = ({ showDeleteModal, closeDeleteModal, handleDelete }) => {
  return (
    <React.Fragment>
      <Modal
        contentLabel="Confirm Deletion"
        onRequestClose={() => closeDeleteModal()}
        isOpen={showDeleteModal}
        style={{
          content: {
            ...modalStyles.container,
          },
        }}
      >
        <div style={modalStyles.xButtonContainer}>
          <i onClick={() => closeDeleteModal()} className="fa fa-times" style={modalStyles.xButton}></i>
        </div>
        <div style={modalStyles.contentContainer}>
          <p style={modalStyles.headerText}>DELETE CONFIRMATION</p>
          <div style={modalStyles.promptText}>Are you sure you want to permanently delete this item?</div>
          <div style={modalStyles.buttonContainer}>
            <button style={modalStyles.cancelButton} onClick={() => closeDeleteModal()}>
              <i className="fa fa-times action-menu-icon" aria-hidden="true"></i>CANCEL
            </button>
            <button style={modalStyles.deleteButton} onClick={() => handleDelete()}>
              <i className="fa fa-trash action-menu-icon" aria-hidden="true"></i>DELETE
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export function resetState(props) {
  return {
    isEditing: false,
    name: props && props.project ? props.project.name : '',
    missingNameInput: false,
  };
}

const theme = createTheme({
  palette: {
    primary: grey,
  },
  overrides: {
    MuiFormControl: {
      root: {
        minWidth: '100%',
      },
    },
  },
});

const withStyles = styled({
  container: {
    display: 'flex',
    margin: '15px 0 0px 0',
    alignItems: 'center',
  },
  input: {
    flex: 1,
  },
  name: {
    fontSize: 24,
    fontWeight: 300,
  },
  icon: {
    marginLeft: 10,
  },
  strikeout: {
    textDecorationLine: 'line-through',
  },
  bold: {
    fontWeight: 400,
  },
  toggleButtons: {
    display: 'inherit',
    marginLeft: 'auto',
  },
  invalid: {
    border: `1px solid ${red}`,
  },
  projectNameInput: {
    width: '100%',
  },
});

const modalStyles = {
  container: {
    top: '50%',
    left: '50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    fontFamily: 'aktiv-grotesk',
    border: '1px solid #CCCCCC',
    borderRadius: '1px',
    backgroundColor: '#F5F5F5',
    width: '425px',
    height: '200px',
    padding: '0',
  },
  xButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  xButton: {
    padding: '5px 5px 0px 0px',
    cursor: 'pointer',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerText: {
    color: '#212529',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'aktiv-grotesk',
  },
  promptText: { flex: 1, fontSize: '14px' },
  buttonContainer: { display: 'flex', justifyContent: 'space-evenly', paddingTop: '40px', width: '100%' },
  cancelButton: {
    color: '#212529',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    border: '1px solid black',
    marginHorizontal: '30px',
    width: '100px',
    height: '35px',
  },
  deleteButton: {
    backgroundColor: '#212529',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    border: '1px solid black',
    marginHorizontal: '30px',
    width: '100px',
    height: '35px',
  },
};

export default withRouter(withStyles(Title));
