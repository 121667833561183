import React from 'react';
import { styled } from 'react-free-style';
import Checkbox from 'react-three-state-checkbox';
import moment from 'moment';
import { AgentColumn } from '@united-talent-agency/julius-frontend-components';
import ActionButtons from '../../../../../components/action-buttons/action-buttons';
import { CastingBadges } from '../../../../../components/filter-badges/filter-casting-badges';
import SortableColumnHeader from '../../../../../components/sortable-column-header/sortable-column-header';
import './current-castings-list.css';

class CastingsList extends React.Component {
  // eslint-disable-next-line no-undef
  scrollListener = async () => {
    const { maxCount, resultCount, castings, onFetchMore } = this.props;

    if (onFetchMore && castings && castings.length < maxCount && castings.length < resultCount) {
      const scrollHeight = document.scrollingElement.scrollHeight;
      const scrollTop = document.scrollingElement.scrollTop;
      const clientHeight = document.scrollingElement.clientHeight;
      const triggerPercentage = 0.2;

      if (scrollHeight - (scrollTop + clientHeight) < scrollHeight * triggerPercentage) {
        onFetchMore();
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener);
  }
  componentWillUnmount() {
    if (window) {
      window.removeEventListener('scroll', this.scrollListener);
    }
  }

  render() {
    const {
      buttons = [],
      castings = [],
      filterBadges,
      select = true,
      styles,
      filterModel,
      maxCount,
      resultCount,
    } = this.props;
    const { selectAll, selection, sortDirection, sortColumn, onSortChanged } = this.props;
    const dateFilterTypes = { 'Last Updated': 'Updated', 'Start Date': 'Start', Created: 'Created' };
    const dateType = filterModel && filterModel.castingDateType;
    const currentDateFilterType = dateType ? dateFilterTypes[dateType] : 'Start';
    const castingsLength = castings ? castings.length : 0;
    return (
      <React.Fragment>
        <ActionButtons buttons={buttons} />
        <CastingBadges resetFilters={this.props.resetFilters} badges={filterBadges} />
        <table
          className={`table table-light table-bordered table-hover table-sm table-responsive ${styles.infinteGrid}`}
        >
          <thead>
            <tr>
              {select ? (
                <th width="25" style={{ textAlign: 'center' }}>
                  <Checkbox
                    id="select_all"
                    type={`checkbox`}
                    style={{ height: 14, width: 14 }}
                    onChange={() => {
                      this.onSelectAllChanged();
                    }}
                    checked={selectAll}
                  />
                </th>
              ) : null}
              <th scope="col" width="425">
                <SortableColumnHeader
                  text="Project"
                  id={`column-header-Project`}
                  direction={sortColumn === 'Project' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Project', direction);
                  }}
                />
              </th>
              <th scope="col" width="125">
                <SortableColumnHeader
                  text="Role"
                  id={`column-header-Role`}
                  direction={sortColumn === 'Role' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Role', direction);
                  }}
                />
              </th>
              <th scope="col" width="200">
                <SortableColumnHeader
                  text="Role Type"
                  id={`column-header-Role-Type`}
                  direction={sortColumn === 'RoleType' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('RoleType', direction);
                  }}
                />
              </th>
              <th scope="col" width="250">
                <SortableColumnHeader
                  text={currentDateFilterType}
                  id={`column-header-${currentDateFilterType}`}
                  direction={sortColumn === currentDateFilterType && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged(currentDateFilterType, direction);
                  }}
                />
              </th>
              <th scope="col" width="250">
                <SortableColumnHeader
                  text="Talent"
                  id={`column-header-Talent`}
                  direction={sortColumn === 'Talent' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Talent', direction);
                  }}
                />
              </th>
              <th scope="col" width="250">
                <SortableColumnHeader
                  text="Executives"
                  id={`column-header-Executives`}
                  direction={sortColumn === 'Executives' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Executives', direction);
                  }}
                />
              </th>
              <th scope="col" width="250">
                <SortableColumnHeader
                  text="Agents"
                  id={`column-header-Agents`}
                  direction={sortColumn === 'Agents' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Agents', direction);
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {castings &&
              castings.map((casting, index) => {
                const { _id, ageMin, ageMax, name, personId, projectId, status, type } = casting;

                return (
                  <tr key={index} className={styles.tr}>
                    {select ? (
                      <td style={{ paddingLeft: '6px', paddingTop: '3px' }}>
                        <Checkbox
                          id={'selected_'.concat(_id)}
                          checked={(selectAll && !selection[_id]) || (!selectAll && selection[_id])}
                          title={``}
                          onChange={() => {
                            this.onSelectedChanged(casting);
                          }}
                        />
                      </td>
                    ) : null}
                    {/* Column Name: PROJECT */}
                    <td
                      onClick={() => {
                        this.onRowDataClicked(casting);
                      }}
                    >
                      {projectId && (
                        <React.Fragment>
                          {projectId.name}
                          <br />
                          {projectId.studios &&
                            projectId.studios
                              .map(studio => {
                                return studio.name;
                              })
                              .join('/')}
                        </React.Fragment>
                      )}
                    </td>
                    {/* Column Name: ROLE */}
                    <td
                      onClick={() => {
                        this.onRowDataClicked(casting);
                      }}
                    >
                      {name}
                      <br />
                      {ageMin && ageMax && `${ageMin}-${ageMax}`}
                    </td>
                    {/* Column Name: ROLE TYPE */}
                    <td
                      onClick={() => {
                        this.onRowDataClicked(casting);
                      }}
                    >
                      {type}
                      <br />
                      {status}
                    </td>
                    {/* Column Name: START or UPDATED or CREATED (...date column) */}
                    <td
                      onClick={() => {
                        this.onRowDataClicked(casting);
                      }}
                    >
                      {this.getDate(currentDateFilterType, casting)}
                    </td>
                    {/* Column Name: TALENT */}
                    <td
                      onClick={() => {
                        this.onRowDataClicked(casting);
                      }}
                    >
                      {personId && personId.name}
                    </td>
                    {/* Column Name: EXECUTIVES */}
                    <td
                      onClick={() => {
                        this.onRowDataClicked(casting);
                      }}
                    >
                      {projectId.executives.directors.length > 0 && (
                        <div>D: {projectId.executives.directors.join(', ')}</div>
                      )}
                      {projectId.executives.castingDirectors.length > 0 && (
                        <div>CD: {projectId.executives.castingDirectors.join(', ')}</div>
                      )}
                      {projectId.executives.producers.length > 0 && (
                        <div>P: {projectId.executives.producers.join(', ')}</div>
                      )}
                    </td>
                    {/* Column Name: AGENTS */}
                    <td
                      onClick={() => {
                        this.onRowDataClicked(casting);
                      }}
                    >
                      {projectId && <AgentColumn value={projectId.talentAgents} />}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {castingsLength < maxCount && this.renderPageCount(castingsLength, resultCount)}
        {castingsLength >= maxCount && (
          <div id="limit-reached-message" style={{ textAlign: 'center' }}>
            <p style={{ display: 'inline-block', fontWeight: 600 }}>
              Showing the first {resultCount} results of {maxCount} matching your search.
            </p>
            <br />
            <p style={{ display: 'inline-block', fontWeight: 600 }}>Please narrow your search to show all results.</p>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderPageCount(currentCastingsAmount, totalCastings) {
    return (
      <div style={{ float: 'right' }}>
        <span>
          {currentCastingsAmount} / {totalCastings}
        </span>
      </div>
    );
  }

  getDate(dateFilterType, casting) {
    switch (dateFilterType) {
      case 'Start':
        return (
          (casting &&
            casting.projectId &&
            casting.projectId.opened_date &&
            moment(casting.projectId.opened_date).format('MM/DD/YYYY')) ||
          ''
        );
      case 'Updated':
        return (casting && casting.last_updated_date && moment(casting.last_updated_date).format('MM/DD/YYYY')) || '';
      case 'Created':
        return (casting && casting.created_date && moment(casting.created_date).format('MM/DD/YYYY')) || '';
      default:
        return '';
    }
  }

  // eslint-disable-next-line no-undef
  onRowDataClicked = casting => {
    const url = `/project/${casting.projectId._id}`;
    window.open(url);
  };

  // eslint-disable-next-line no-undef
  onSelectAllChanged = () => {
    const { onSelectAllChanged } = this.props;
    onSelectAllChanged && onSelectAllChanged();
  };

  // eslint-disable-next-line no-undef
  onSelectedChanged = casting => {
    const { onSelectedChanged } = this.props;
    onSelectedChanged && onSelectedChanged({ [casting._id]: casting.projectId._id });
  };
}

const withStyles = styled({
  infinteGrid: {
    fontWeight: 'lighter',
    fontSize: '12px',
  },
  tr: {
    '&:hover': {
      '>td': {
        backgroundColor: '#F9F9F9',
        cursor: 'pointer',
      },
    },
  },
});

export default withStyles(CastingsList);