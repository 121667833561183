/**
 * Grid/View displays the results of a search performed with a Grid. This view
 * loads the Grid, overwriting any existing filter set stored in the user/desk
 * session.
 */
import React from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { notify } from 'react-notify-toast';
import { elements } from '@united-talent-agency/julius-frontend-components';
import { loadProjectGrid, loadCastingGrid } from '@united-talent-agency/julius-frontend-store';
import ProjectGrid from './project-grid/project-grid';
import CastingGrid from './casting-grid/casting-grid';

export class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gridId: props && props.match && props.match.params.id,
      gridType: props && props.location && props.location.pathname.includes('casting') ? 'casting' : 'project',
    };
    this.show = notify.createShowQueue();
  }

  async componentDidMount() {
    const { gridType } = this.state;
    const gridId = this.props.match && this.props.match.params.id;
    if (gridType === 'casting') {
      const castingGrid = (await this.props.loadCastingGrid(gridId)).body;
      this.setState({ castingGrid });
    } else if (gridType === 'project') {
      const projectGrid = (await this.props.loadProjectGrid(gridId)).body;
      this.setState({ projectGrid });
    }
  }

  render() {
    const { gridType, projectGrid, castingGrid } = this.state;
    const { user, desk } = this.props;
    return (
      <React.Fragment>
        {gridType === 'project' && projectGrid && (
          <ProjectGrid grid={projectGrid} filterModel={projectGrid.projectFilter} desk={desk} user={user} />
        )}
        {gridType === 'casting' && castingGrid && (
          <CastingGrid grid={castingGrid} filterModel={castingGrid.castingFilter} desk={desk} user={user} />
        )}
      </React.Fragment>
    );
  }
}

const withStyles = styled({
  body: {
    margin: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    display: 'flex',
    margin: '0 18px',
  },
  menuItem: helpers.merge(elements.menuItem, {
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
  }),
  activeItem: elements.activeMenuItem,
});

export default connect(null, { loadProjectGrid, loadCastingGrid })(withStyles(Grid));
