import React from 'react';
import { IconContext } from 'react-icons';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import CollapseIcon from '../collapse-icon/collapse-icon';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CollapsedFilters = props => {
  const { expandEvent } = props;

  const flags = useFlags();

  return (
    <div className={`m-0 p-0 column`}>
      <div className={`ml-1 row`}>
        <div
          style={{ marginTop: '1em', position: 'relative', right: '10px', bottom: '5px', cursor: 'pointer' }}
          onClick={expandEvent}
        >
          {flags?.algoliaFeatureFlag ? (
            <CollapseIcon
              iconDirection={
                <IconContext.Provider value={{ size: '1.2em' }}>
                  <MdKeyboardDoubleArrowRight />
                </IconContext.Provider>
              }
            />
          ) : (
            <CollapseIcon iconDirection={'>'} />
          )}
        </div>
      </div>
      <div className={`ml-1 row`}>
        <div style={{ width: 3, height: 950, marginRight: 5 }} />
      </div>
    </div>
  );
};

export default CollapsedFilters;
