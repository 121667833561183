import React from 'react';
import LaunchDarklyWrapper from '../../../../../components/LDWrapper/LaunchDarklyWrapper';
import SearchProjectsViewAgolia from './algolia-search-projects-index';
import SearchProjectsView from './search-projects-current.js';

function index(props) {
  return (
    <LaunchDarklyWrapper
      render={flags => {
        return (
          <>
            {flags?.algoliaFeatureFlag ? (
              <SearchProjectsViewAgolia {...props} featureFlags={flags} />
            ) : (
              <SearchProjectsView {...props} />
            )}
          </>
        );
      }}
    />
  );
}

export default index;
