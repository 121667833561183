export const sort = ({ direction, category }, roles = []) => {
  const order = direction === 'ASC' ? 1 : -1;
  switch (category) {
    case 'Auspice':
      roles.sort((a, b) => a.type.localeCompare(b.type) * order);
      break;
    case 'Status':
      roles.sort((a, b) => a.status.localeCompare(b.status) * order);
      break;
    case 'Agency':
      roles.sort((a, b) => {
        const aAgency = (a.agency && a.agency.name) || '';
        const bAgency = (b.agency && b.agency.name) || '';
        return aAgency.localeCompare(bAgency) * order;
      });
      break;
    case 'Person':
      roles.sort((a, b) => {
        let aName = a.personId ? (a.personId.name ? a.personId.name : a.personId) : '';
        let bName = b.personId ? (b.personId.name ? b.personId.name : b.personId) : '';
        return aName.localeCompare(bName) * order;
      });
      break;
    default:
      throw Error('Should not get here');
  }
};
