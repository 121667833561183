import algoliasearch from 'algoliasearch';

export const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID || ' ',
  process.env.REACT_APP_ALGOLIA_API_KEY || ' '
);

export const searchClient = async (searchIndex, searchQuery, searchParams) => {
  if (!searchIndex) return;
  const result = await searchIndex.search(searchQuery, searchParams);
  return result;
};

export const searchObject = async (index, id) => {
  const result = await index.getObject(id);
  return result;
};