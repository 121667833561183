import { elements } from '@united-talent-agency/julius-frontend-components';

export const userOptionsStyles = {
  icon: {
    width: '10%',
  },
  label: {
    width: '90%',
    textWrap: 'wrap',
  },
};

export const iconStyles = {
  fontWeight: 400,
  fontSize: 10,
  padding: 2,
  verticalAlign: 'middle',
  textAlign: 'center',
  marginRight: 3,
  marginLeft: 3,
  marginTop: -3,
  display: 'inline-block',
  height: 18,
  width: 16,
};

export const getUserTypeStyles = type => {
  let styles = {};
  switch (type) {
    case 'C':
      styles = {
        background: elements.clientMarker.background,
        color: elements.clientMarker.color,
      };
      break;
    case 'I':
      styles = {
        background: elements.industryMarker.background,
        color: elements.industryMarker.color,
      };
      break;
    case 'O':
      styles = {
        background: elements.outlookMarker.background,
        color: elements.outlookMarker.color,
      };
      break;
    case 'S':
      styles = {
        background: elements.sharedMarker.background,
        color: elements.sharedMarker.color,
      };
      break;
    case 'E':
      styles = {
        background: elements.employeeMarker.background,
        color: elements.employeeMarker.color,
      };
      break;
    default:
      return;
  }
  return styles;
};
