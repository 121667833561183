import React from 'react';

import Checkbox from '../checkbox/checkbox';

const FilterCheckbox = props => {
  const { checked, id, title, onChange, disabled } = props;
  return (
    <div style={{ marginTop: -3 }} className="ml-2 row">
      <Checkbox id={id} disabled={disabled} checked={checked} title={title} onChange={onChange} />
    </div>
  );
};

export default FilterCheckbox;
