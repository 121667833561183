export const defaultBudgetValues = [
  'Unknown',
  '< $1m',
  '$1m - $5m',
  '$6m - $10m',
  '$11m - $15m',
  '$16m - $20m',
  '$21m - $30m',
  '$31m - $40m',
  '$41m - $60m',
  '$61m - $80m',
  '$81m - $100m',
  '> $100m',
];

export const theatreBudgetValues = ['Commercial', 'Non-Profit'];
