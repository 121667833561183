import React from 'react';
import moment from 'moment';
import { AgentColumn, CompanyColumn } from '@united-talent-agency/julius-frontend-components';

const columnFormatter = [
  {
    name: 'Name',
    width: '425',
    exclude: [],
    format: project => {
      return project?.name;
    },
  },
  {
    name: 'Type',
    width: '125',
    exclude: ['Feature', 'Television', 'Unscripted', 'Theatre', 'Fairs and Festivals'],
    format: project => {
      return project?.type;
    },
  },
  {
    name: 'State',
    width: '125',
    exclude: ['Any', 'Feature', 'Television', 'Unscripted', 'Theatre'],
    include: ['Fairs and Festivals'],
    format: project => {
      return project?.locationState;
    },
  },
  {
    name: 'Classification',
    width: '125',
    exclude: ['Any', 'Feature', 'Television', 'Unscripted', 'Theatre'],
    include: ['Fairs and Festivals'],
    format: project => {
      return project?.classification;
    },
  },
  {
    name: 'Genre',
    width: '125',
    exclude: ['Any', 'Feature', 'Television', 'Unscripted', 'Theatre'],
    include: ['Fairs and Festivals'],
    format: project => {
      return Array.isArray(project?.genre) && project?.genre.join(', ');
    },
  },
  {
    name: 'Capacity',
    width: '125',
    exclude: ['Any', 'Feature', 'Television', 'Unscripted', 'Theatre'],
    include: ['Fairs and Festivals'],
    format: project => {
      return project?.capacity;
    },
  },
  {
    name: 'Start',
    width: 125,
    exclude: ['Last Updated', 'Created'],
    localSortValue: project => {
      return project?.opened_date ? moment(project?.opened_date).format('YYYYMMDD') : '';
    },
    format: project => {
      return project?.opened_date && moment(project?.opened_date).format('MM/DD/YYYY');
    },
  },
  {
    name: 'Updated',
    width: 125,
    exclude: ['Start Date', 'Created'],
    localSortValue: project => {
      return project?.last_updated_date ? moment(project?.last_updated_date).format('YYYYMMDD') : '';
    },
    format: project => {
      return project?.last_updated_date && moment(project?.last_updated_date).format('MM/DD/YYYY');
    },
  },
  {
    name: 'Created',
    width: 125,
    exclude: ['Start Date', 'Last Updated'],
    localSortValue: project => {
      return project?.created_date ? moment(project?.created_date).format('YYYYMMDD') : '';
    },
    format: project => {
      return project?.created_date && moment(project?.created_date).format('MM/DD/YYYY');
    },
  },
  {
    name: 'Production',
    width: 200,
    exclude: ['Fairs and Festivals'],
    localSortValue: project => {
      return project?.productionCompanies && project?.productionCompanies[0]
        ? project?.productionCompanies[0].name
        : '';
    },
    // eslint-disable-next-line react/display-name
    format: project => {
      return <CompanyColumn value={project?.productionCompanies} />;
    },
  },
  {
    name: 'Network',
    width: 250,
    exclude: ['Fairs and Festivals', 'Feature', 'Theatre'],
    localSortValue: project => {
      return project?.networks && project?.networks[0] ? project?.networks[0].name : '';
    },
    // eslint-disable-next-line react/display-name
    format: project => {
      return <CompanyColumn value={project?.networks} />;
    },
  },
  {
    name: 'Studio',
    width: 250,
    exclude: ['Fairs and Festivals', 'Theatre'],
    localSortValue: project => {
      return project?.studios && project?.studios[0] ? project?.studios[0].name : '';
    },
    // eslint-disable-next-line react/display-name
    format: project => {
      return <CompanyColumn value={project?.studios} />;
    },
  },
  {
    name: 'Director',
    width: 250,
    exclude: ['Fairs and Festivals', 'Theatre', 'Unscripted'],
    localSortValue: project => {
      return project?.executives.directors && project?.executives?.directors[0]
        ? project?.executives?.directors[0]
        : '';
    },
    // eslint-disable-next-line react/display-name
    format: project => {
      return project?.executives?.directors.join(', ');
    },
  },
  {
    name: 'Talent',
    width: 250,
    exclude: ['Fairs and Festivals'],
    localSortValue: project => {
      return project?.talentAgents && project?.talentAgents[0] ? project?.talentAgents[0].name : '';
    },
    // eslint-disable-next-line react/display-name
    format: project => {
      return <AgentColumn value={project?.talentAgents} />;
    },
  },
  {
    name: 'Literary',
    width: 250,
    exclude: ['Fairs and Festivals'],
    localSortValue: project => {
      return project?.literaryAgents && project?.literaryAgents[0] ? project?.literaryAgents[0].name : '';
    },
    // eslint-disable-next-line react/display-name
    format: project => {
      return <AgentColumn value={project?.literaryAgents} />;
    },
  },
  {
    name: 'Promoters',
    width: 250,
    include: ['Fairs and Festivals'],
    exclude: ['Any', 'Feature', 'Television', 'Unscripted', 'Theatre'],
    // eslint-disable-next-line react/display-name
    format: project => {
      return <CompanyColumn value={project?.promoters} />;
    },
  },
  {
    name: 'Agent',
    width: 250,
    include: ['Fairs and Festivals'],
    exclude: ['Any', 'Feature', 'Television', 'Unscripted', 'Theatre'],
    // eslint-disable-next-line react/display-name
    format: project => {
      return <AgentColumn value={project?.pointAgents} />;
    },
  },
];

export default columnFormatter;
