/* eslint-disable */
import React, { useState, useEffect } from 'react';

import { Select } from '@united-talent-agency/components';

const DropdownOptions = ({ label, checked, disabled }) => {
  const styles = {
    input: { padding: '2px' },
    label: { marginLeft: '5px', marginBottom: '0px' },
  };

  return (
    <React.Fragment>
      <input
        readOnly
        name={label}
        type="checkbox"
        style={styles.input}
        checked={checked || disabled}
        disabled={disabled}
      />
      <label htmlFor={label} style={styles.label}>
        {label}
      </label>
    </React.Fragment>
  );
};

export const Multiselect = ({
  title,
  options,
  onSelect,
  selectedOptions,
  disabledOptions = [],
  selectAll,
  loading = false,
}) => {
  const [multiselectOptions, setMultiselectOptions] = useState([]);

  const isDisabled = option => {
    return disabledOptions.includes(option);
  };

  const onClick = option => {
    !isDisabled(option) && onSelect && onSelect(option);
  };

  useEffect(() => {
    // The Select component requires an array of objects with key, content, and onClick properties
    const formattedOptions = options.map(option => ({
      key: option,
      content: (
        <DropdownOptions label={option} checked={selectedOptions.includes(option)} disabled={isDisabled(option)} />
      ),
      onClick: () => onClick(option),
    }));

    if (selectAll) {
      const allOptions = {
        key: 'All',
        content: (
          <DropdownOptions label="All" checked={options.length > 0 && options.length === selectedOptions.length} />
        ),
        onClick: () => onSelect(options),
      };
      setMultiselectOptions([allOptions, ...formattedOptions]);
    } else {
      return setMultiselectOptions(formattedOptions);
    }
  }, [options, selectAll, selectedOptions, onSelect]);

  if (loading) return <Select title="Loading..." items={[]} chevronDisabled />;

  return (
    <React.Fragment>
      <Select title={title} items={multiselectOptions} dropdownStyle={{ padding: '5px 10px' }} />
      {selectedOptions.map((selectedOption)=>(
  
        <div
          key={selectedOption}
          style={{ marginRight: '15px', display: 'inline-block' }}
          onClick={() => onSelect(selectedOption)}
        >
          <DropdownOptions label={selectedOption} checked disabled={isDisabled(selectedOption)} />
        </div>
      ))}
    </React.Fragment>
  );
};
