import React, { Component } from 'react';
import { styled } from 'react-free-style';
import Linkify from 'react-linkify';
import classnames from 'classnames';

import { EditDeleteButtons, colors } from '@united-talent-agency/julius-frontend-components';

import { personProfileLink } from '../../support/person';

class Act extends Component {
  // eslint-disable-next-line no-undef
  state = {
    showEdit: false,
  };

  componentWillUnmount() {
    if (this.editTimeout) clearTimeout(this.editTimeout);
  }

  render() {
    const { act, index, isActive, isFocused, onDeleteItem, onEditItem, setFocused, styles } = this.props;
    const { showEdit } = this.state;
    const performance =
      act && act.pertainingToId && act.pertainingToId.name ? act.pertainingToId.name : 'Name Not Available';
    let _note = act && act.note ? act.note : 'N/A';
    _note = _note.split('\n');

    const isSteward = false;

    return (
      <tr
        className={classnames(
          styles.td,
          isActive ? styles.tdActive : undefined,
          isFocused ? styles.tdFocus : undefined
        )}
        onMouseEnter={() => {
          setFocused(index);
          setTimeout(() => {
            this.setState({ showEdit: true });
          }, 700);
        }}
        onMouseLeave={() => {
          this.setState({ showEdit: false });
          if (this.editTimeout) clearTimeout(this.editTimeout);
        }}
        onDoubleClick={() => {
          onEditItem(act);
        }}
        key={act ? act._id : ''}
      >
        <td className={styles.personName}>
          <span className={styles.person}>
            {performance ? (
              <a
                className={styles.profileLink}
                href={personProfileLink(act.personId, isSteward)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {performance}
              </a>
            ) : (
              performance
            )}
          </span>
        </td>
        {/* <td className={styles.numberColumn}>
          <NumberFormat value={fee} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </td> */}
        <td>
          <span className={styles.act}>
            {_note.map((act, index) => (
              <div key={index}>
                <Linkify>{act}</Linkify>
              </div>
            ))}
          </span>
        </td>
        {isFocused && showEdit && (
          <EditDeleteButtons entity={act} onEditItem={onEditItem} onDeleteItem={onDeleteItem} />
        )}
      </tr>
    );
  }
}

const withStyles = styled({
  td: {
    '> td': {
      padding: 10,
      fontSize: 11,
      fontWeight: 300,
      color: colors.text,
    },
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
  },
  tdFocus: {
    backgroundColor: colors.focusBackground,
  },
  tdActive: styles => ({
    border: `1px solid ${colors.focusBorder}`,
    [`& + .${styles.td}`]: {
      borderTop: 0,
    },
  }),
  person: {
    display: 'flex',
    flex: 0.4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
  },
  personName: {
    width: 125,
  },
  column: {
    width: 85,
  },
  numberColumn: {
    width: 85,
    textAlign: 'right',
  },
  act: {
    flex: 1,
  },
  profileLink: {
    cursor: 'pointer',
    color: '#000',
  },
});

export default withStyles(Act);
