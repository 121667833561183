import React, { Component } from 'react';
import { styled } from 'react-free-style';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import TextField from '@material-ui/core/TextField';
import { colors } from '../../styles/colors';
import FilterCheckbox from '../filter-checkbox/filter-checkbox';
import { hasAccess } from '../../support/permissions';
import Select from 'react-select';
const indexOptions = [
  { type: '', label: 'None' },
  { type: 'Project_Title_Index', label: 'Project Title' },
  { type: 'Literary_Agent_Index', label: 'Literary Agent' },
  { type: 'Talent_Agent_Index', label: 'Talent Agent' },
  { type: 'Indie_Agent_Index', label: 'Indie Agent' },
  { type: 'Indie_Status_Index', label: 'Indie Status', hasAccess: hasAccess('printing', 'groupByIndieStatus') },
  { type: 'Production_Company_Index', label: 'Production Company' },
  { type: 'Studio_Index', label: 'Studio' },
  { type: 'Network_Index', label: 'Network' },
  { type: 'Director_Index', label: 'Director' },
  { type: 'Casting_Director_Index', label: 'Casting Director' },
  { type: 'Television_Status_Index', label: 'Television Status' },
];

export class PrintOptions extends Component {
  constructor(props) {
    super(props);

    this.state = props.savedOptions || {
      executives: true,
      includeLiteraryExecs: true,
      includeTalentExecs: true,
      staff: true,
      staffByPerson: false,
      includeStaffAgencies: false,
      cast: true,
      index: '',
      talentNotes: false,
      literaryNotes: false,
      indieNotes: false,
      includeInternalLiteraryNotes: false,
      includeDatesLiteraryNotes: false,
      showRolesTable: props.userInTalent || false,
      showRolesSelected: !!props.isCastingResults,
      showRolesTableAgency: true,
      suppressFormatting: false,
      firstElement: false,
      watermark: true,
      repAgreements: false,
      budget: true,
      internalHeader: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      agents: true,
      talentAgents: true,
      literaryAgents: true,
      indieAgents: true,
      budgetNotes: false,
      director: false,
      castingDirector: false,
      sortBy: 'Project Title',
    };
    this.state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    this.props.onSelectionsChanged && this.props.onSelectionsChanged(this.state);
  }

  // eslint-disable-next-line no-undef
  onCheckboxesChanged = async (field, value) => {
    let newValue;

    switch (field) {
      case 'executives':
        newValue = { executives: value, includeLiteraryExecs: value, includeTalentExecs: value };
        break;
      case 'includeLiteraryExecs':
        newValue = { includeLiteraryExecs: value };
        break;
      case 'includeTalentExecs':
        newValue = { includeTalentExecs: value };
        break;
      case 'staff':
        newValue = { staff: value, includeStaffAgencies: false, staffByPerson: false };
        break;
      case 'includeStaffAgencies':
        newValue = { includeStaffAgencies: value };
        break;
      case 'staffByPerson':
        newValue = { staffByPerson: value };
        break;
      case 'cast':
        newValue = { cast: value };
        break;
      case 'talentNotes':
        newValue = { talentNotes: value };
        break;
      case 'indieNotes':
        newValue = { indieNotes: value };
        break;
      case 'literaryNotes':
        newValue = { literaryNotes: value, includeInternalLiteraryNotes: false, includeDatesLiteraryNotes: false };
        break;
      case 'includeInternalLiteraryNotes':
        newValue = { includeInternalLiteraryNotes: value };
        break;
      case 'includeDatesLiteraryNotes':
        newValue = { includeDatesLiteraryNotes: value };
        break;
      case 'showRolesTable':
        newValue = {
          showRolesTable: value,
          showRolesTableStatus: value,
          showRolesTableName: value,
          showRolesTableAgency: value,
        };
        break;
      case 'showRolesSelected':
        newValue = { showRolesSelected: value };
        break;
      case 'showRolesTableAgency':
        newValue = { showRolesTableAgency: value };
        break;
      case 'clientBold':
        newValue = { suppressFormatting: !value };
        break;
      case 'watermark':
        newValue = { watermark: value };
        break;
      case 'firstElement':
        newValue = { firstElement: value };
        break;
      case 'repAgreements':
        newValue = { repAgreements: value };
        break;
      case 'agents':
        newValue = { agents: value, talentAgents: value, literaryAgents: value, indieAgents: value };
        break;
      case 'talentAgents':
        newValue = { talentAgents: value };
        break;
      case 'literaryAgents':
        newValue = { literaryAgents: value };
        break;
      case 'indieAgents':
        newValue = { indieAgents: value };
        break;
      case 'budget':
        newValue = { budget: value, budgetNotes: false };
        break;
      case 'budgetNotes':
        newValue = { budgetNotes: value };
        break;
      case 'director':
        newValue = { director: value };
        break;
      case 'castingDirector':
        newValue = { castingDirector: value };
        break;
      default:
        newValue = { [field]: value };
    }

    this.setState(newValue, () => {
      this.props.onSelectionsChanged(this.state);
    });
  };

  // Changes to the radio group to select an index (or none)
  // update the print configuration
  // eslint-disable-next-line no-undef
  onIndexRadioGroupChanged = async (event, value) => {
    this.setState({ sortBy: 'Project Title' }, () => {
      this.props.onSelectionsChanged(this.state);
    });
    this.setState({ index: value }, () => {
      this.props.onSelectionsChanged(this.state);
    });
  };
  // When External Preset button is clicked
  // print configuration is updated
  handleExternalPresetClicked = async () => {
    if (!this.state.externalPresetClicked) {
      this.setState(
        {
          internalHeader: false,
          cast: false,
          showRolesTable: true,
          showRolesTableAgency: false,
          showRolesTableStatus: false,
          showRolesTableName: false,
          externalPresetClicked: true,
          agents: false,
          talentAgents: false,
          literaryAgents: false,
          indieAgents: false,
        },
        () => {
          this.props.onSelectionsChanged(this.state);
        }
      );
    } else {
      this.setState(
        {
          internalHeader: true,
          cast: true,
          showRolesTable: this.props.userInTalent || false,
          showRolesTableAgency: true,
          showRolesTableStatus: true,
          showRolesTableName: true,
          externalPresetClicked: false,
          agents: true,
          talentAgents: true,
          literaryAgents: true,
          indieAgents: true,
        },
        () => {
          this.props.onSelectionsChanged(this.state);
        }
      );
    }
  };
  onSortByChange = event => {
    this.setState({ sortBy: event.value }, () => {
      this.props.onSelectionsChanged(this.state);
    });
  };
  render() {
    const {
      styles,
      indexOptionsDisabled,
      isCastingResults,
      onSelectionsChanged,
      _hasAccess = hasAccess,
      showSortOptions,
    } = this.props;
    const {
      index,
      showRolesTable,
      showRolesSelected,
      showRolesTableAgency,
      firstElement,
      includeStaffAgencies,
      literaryNotes,
      includeInternalLiteraryNotes,
      includeDatesLiteraryNotes,
      includeLiteraryExecs,
      includeTalentExecs,
      staff,
      staffByPerson,
      cast,
      executives,
      talentNotes,
      indieNotes,
      suppressFormatting,
      watermark,
      repAgreements,
      agents,
      talentAgents,
      literaryAgents,
      indieAgents,
      budget,
      budgetNotes,
      director,
      internalHeader,
      externalPresetClicked,
      showRolesTableName,
      showRolesTableStatus,
    } = this.state;
    const primaryIndexOptionDisabled =
      index === '' ||
      index === 'Project_Title_Index' ||
      index === 'Indie_Status_Index' ||
      index === 'Television_Status_Index';
    const indexDisplayNameMap = {
      Project_Title_Index: 'project',
      Talent_Agent_Index: 'agent',
      Literary_Agent_Index: 'agent',
      Indie_Agent_Index: 'agent',
      Studio_Index: 'studio',
      Production_Company_Index: 'production company',
      Network_Index: 'network',
      Director_Index: 'director',
      Casting_Director_Index: 'casting director',
      Indie_Status_Index: 'indie status',
      Television_Status_Index: 'television status',
    };
    const indexDisplayName = index && indexDisplayNameMap[index];

    const handleIndexChange = indexDisplayName => {
      let options = {
        agent: ['Project Title', 'Network', 'Studio'],
        'indie status': ['Project Title', 'Network', 'Studio'],
        'production company': ['Project Title', 'Network', 'Studio'],
        studio: ['Project Title', 'Network'],
        network: ['Project Title', 'Studio'],
        director: ['Project Title', 'Network', 'Studio'],
        'casting director': ['Project Title', 'Network', 'Studio'],
        'television status': ['Project Title', 'Network', 'Studio'],
        project: ['Project Title', 'Network', 'Studio'],
        '': ['Project Title', 'Network', 'Studio'],
      };
      options = options[indexDisplayName] || [];
      return options.map(option => ({ value: option, label: option }));
    };

    return (
      <React.Fragment>
        <TextField
          label="Save As (Optional)"
          onChange={event => onSelectionsChanged({ ...this.state, ...{ saveAs: event.target.value } })}
        />
        {_hasAccess('printing', 'watermark') && (
          <button
            className={externalPresetClicked ? styles.buttonChecked : styles.button}
            onClick={this.handleExternalPresetClicked}
          >
            <span className={styles.buttonText}>External Preset</span>
          </button>
        )}
        <div className={styles.subHeader}>Include:</div>
        <div className={styles.container}>
          {_hasAccess('printing', 'watermark') && (
            <FilterCheckbox
              id="internalHeader"
              checked={internalHeader}
              title="Internal Header"
              onChange={this.onCheckboxesChanged}
            />
          )}
          <FilterCheckbox id="executives" checked={executives} title="Executives" onChange={this.onCheckboxesChanged} />
          {executives && (
            <div className={styles.subCheckbox}>
              <FilterCheckbox
                id="includeLiteraryExecs"
                checked={includeLiteraryExecs}
                title="Include Literary "
                onChange={this.onCheckboxesChanged}
              />
            </div>
          )}
          {executives && (
            <div className={styles.subCheckbox}>
              <FilterCheckbox
                id="includeTalentExecs"
                checked={includeTalentExecs}
                title="Include Talent "
                onChange={this.onCheckboxesChanged}
              />
            </div>
          )}
          <FilterCheckbox id="staff" checked={staff} title="Staff" onChange={this.onCheckboxesChanged} />
          {staff && (
            <React.Fragment>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="staffByPerson"
                  checked={staffByPerson}
                  title="Staff By Person"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="includeStaffAgencies"
                  checked={includeStaffAgencies}
                  title="Include Agencies"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            </React.Fragment>
          )}
          <FilterCheckbox id="cast" checked={cast} title="Cast" onChange={this.onCheckboxesChanged} />
          <FilterCheckbox
            id="literaryNotes"
            checked={literaryNotes}
            title="Literary Notes"
            onChange={this.onCheckboxesChanged}
          />
          {literaryNotes && (
            <>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="includeInternalLiteraryNotes"
                  checked={includeInternalLiteraryNotes}
                  title="Include Internal"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="includeDatesLiteraryNotes"
                  checked={includeDatesLiteraryNotes}
                  title="Include Dates"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            </>
          )}
          <FilterCheckbox
            id="talentNotes"
            checked={talentNotes}
            title="Talent Notes"
            onChange={this.onCheckboxesChanged}
          />
          {_hasAccess('printing', 'indieNotes') && (
            <FilterCheckbox
              id="indieNotes"
              checked={indieNotes}
              title="Indie Notes"
              onChange={this.onCheckboxesChanged}
            />
          )}
          <FilterCheckbox
            id="showRolesTable"
            checked={showRolesTable}
            title="Role Descriptions"
            onChange={this.onCheckboxesChanged}
          />
          {isCastingResults && (
            <div className={styles.subCheckbox}>
              <FilterCheckbox
                id="showRolesSelected"
                disabled={!showRolesTable}
                checked={showRolesSelected}
                title="Selected Roles Only"
                onChange={this.onCheckboxesChanged}
              />
            </div>
          )}
          <div className={styles.subCheckbox}>
            {showRolesTable && (
              <>
                {_hasAccess('printing', 'watermark') && (
                  <FilterCheckbox
                    id="showRolesTableStatus"
                    checked={showRolesTableStatus}
                    title="Status"
                    onChange={this.onCheckboxesChanged}
                  />
                )}
                {_hasAccess('printing', 'watermark') && (
                  <FilterCheckbox
                    id="showRolesTableName"
                    checked={showRolesTableName}
                    title="Talent Name"
                    onChange={this.onCheckboxesChanged}
                  />
                )}
                <FilterCheckbox
                  id="showRolesTableAgency"
                  checked={showRolesTableAgency}
                  title="Talent Competitive Agency"
                  onChange={this.onCheckboxesChanged}
                />
              </>
            )}
          </div>
          {_hasAccess('printing', 'repAgreements') && (
            <FilterCheckbox
              id="repAgreements"
              checked={repAgreements}
              title="Rep Agreements"
              onChange={this.onCheckboxesChanged}
            />
          )}
          <FilterCheckbox id="budget" checked={budget} title="Budget" onChange={this.onCheckboxesChanged} />
          {budget && (
            <div className={styles.subCheckbox}>
              <FilterCheckbox
                id="budgetNotes"
                checked={budgetNotes}
                title="Budget Notes"
                onChange={this.onCheckboxesChanged}
              />
            </div>
          )}
          <FilterCheckbox id="agents" checked={agents} title="Agents" onChange={this.onCheckboxesChanged} />
          {agents && (
            <>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="talentAgents"
                  checked={talentAgents}
                  title="Talent Agents"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="literaryAgents"
                  checked={literaryAgents}
                  title="Literary Agents"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="indieAgents"
                  checked={indieAgents}
                  title="Indie Agents"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.subHeader}>Formatting:</div>
        <div className={styles.container}>
          <FilterCheckbox
            id="clientBold"
            checked={!suppressFormatting}
            title="Bold Clients"
            onChange={() => this.onCheckboxesChanged('clientBold', suppressFormatting)}
          />
        </div>
        {_hasAccess('printing', 'watermark') && (
          <div className={styles.container}>
            <FilterCheckbox
              id="watermark"
              checked={watermark}
              title="Watermark Pages"
              onChange={this.onCheckboxesChanged}
            />
          </div>
        )}

        <div className={styles.subHeader}>Table of Contents:</div>
        {!indexOptionsDisabled && (
          <React.Fragment>
            <FormControl>
              <RadioGroup aria-label="indices" name="indices" value={index} onChange={this.onIndexRadioGroupChanged}>
                {indexOptions.map(indexOption => {
                  const { type, label, hasAccess = true } = indexOption;
                  if (hasAccess) {
                    return (
                      <div key={type}>
                        <FormControlLabel
                          key={type}
                          value={type}
                          control={
                            <Radio
                              color="default"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                              style={{
                                color: this.state.index === type ? colors.primary : colors.unselected,
                                paddingLeft: 10,
                                marginTop: -6,
                                marginBottom: -6,
                              }}
                            />
                          }
                          label={label}
                        />
                      </div>
                    );
                  }
                  return undefined;
                })}
              </RadioGroup>
            </FormControl>
            {indexDisplayName && showSortOptions && (
              <div className={styles.inFormCheckbox} style={{ widht: '50px', display: 'flex', alignItems: 'center' }}>
                <label style={{ marginTop: '4px' }}>Sort By</label>
                <span style={{ flex: 1, marginLeft: '8px' }}>
                  <Select
                    options={handleIndexChange(indexDisplayName)}
                    defaultValue={handleIndexChange(indexDisplayName)[0]}
                    value={{ label: this.state.sortBy, value: this.state.sortBy }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={customStyles}
                    onChange={this.onSortByChange}
                    menuPlacement="auto"
                  />
                </span>
              </div>
            )}

            {indexDisplayName && indexDisplayName !== 'director' && (
              <div className={styles.inFormCheckbox}>
                <FilterCheckbox
                  id="director"
                  checked={director}
                  title="Show Director information next to Project's Title"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            )}

            {!primaryIndexOptionDisabled ? (
              <div className={styles.inFormCheckbox}>
                <FilterCheckbox
                  id="firstElement"
                  checked={firstElement}
                  title={`Show project under primary ${indexDisplayName} only`}
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            ) : (
              <div className={styles.spacer} />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const customStyles = {
  control: base => ({
    ...base,
    borderRadius: 0,
    border: '1px solid #EBEBEB',
    fontSize: '13px',
    boxShadow: 'none',
    cursor: 'text',
    '&:hover': {
      border: 'none',
    },
  }),
  menu: base => ({
    ...base,
    backgroundColor: '#FFF',
    border: '1px solid #EBEBEB',
    boxShadow: 'none',
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 0,
  }),
  menuList: base => ({
    ...base,
    fontSize: '13px',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#f5f5f5' : '#FFF',
    color: state.isFocused ? '#000' : '#333',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
    },
  }),
};

const withStyles = styled({
  container: {
    marginLeft: 5,
  },
  subHeader: {
    marginTop: 10,
  },
  inFormCheckbox: {
    marginTop: 15,
    marginLeft: 8, // horizontally aligns with radio buttons
  },
  spacer: {
    marginTop: 37, // is same size of checkbox when present so print / cancel buttons don't jump upon appear
  },
  radioSelection: {
    paddingLeft: 10,
  },
  subCheckbox: {
    marginLeft: '20px',
  },
  button: {
    textTransform: 'uppercase',
    borderColor: 'black',
    color: 'black',
    marginTop: 15,
    width: 160,
    lineHeight: '40px',
    padding: 0,
    backgroundColor: 'white!important',
  },
  buttonChecked: {
    textTransform: 'uppercase',
    borderColor: 'black',
    color: 'black',
    marginTop: 15,
    width: 160,
    backgroundColor: '#fffff',
    lineHeight: '40px',
    padding: 0,
  },
  buttonText: {
    width: '40px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    'font-weight': 'lighter',
    fontSize: '12px',
    backgroundColor: 'inherit',
  },
});

export default withStyles(PrintOptions);
