import React, { Fragment } from 'react';

import SortableColumnHeader from '../../../../../components/sortable-column-header/sortable-column-header';

const SortableTableHeader = ({ header, sortBy, changeSort, customStyle = {} }) => (
  <div className="sortable-auspices-table-header" style={customStyle}>
    <SortableColumnHeader
      text={header}
      direction={sortBy.category === header ? (sortBy.direction === 'ASC' ? 1 : -1) : 0}
      onClick={changeSort}
    />
  </div>
);

const UnSortableTableHeader = ({ header, customStyle = {} }) => (
  <div className="unsortable-auspices-table-header" style={{ ...customStyle }}>
    <span>{header}</span>
  </div>
);

export const GroupByHeaders = ({ groupBySort, changeGroupBySort, sortBy, changeSort, groupByTerm }) => {
  const orderedHeaders = [
    {
      type: {
        groupedBy: (
          <SortableTableHeader header="Auspice" sortBy={groupBySort} changeSort={() => changeGroupBySort('Auspice')} />
        ),
        displayed: (
          <SortableTableHeader
            header="Auspice"
            sortBy={sortBy}
            changeSort={() => changeSort('Auspice')}
            customStyle={{ flex: 0.19 }}
          />
        ),
      },
    },
    {
      status: {
        displayed: (
          <SortableTableHeader
            header="Status"
            sortBy={sortBy}
            changeSort={() => changeSort('Status')}
            customStyle={{ flex: 0.19 }}
          />
        ),
        groupedBy: (
          <SortableTableHeader header="Status" sortBy={groupBySort} changeSort={() => changeGroupBySort('Status')} />
        ),
      },
    },

    {
      person: {
        displayed: (
          <SortableTableHeader
            header="Person"
            sortBy={sortBy}
            changeSort={() => changeSort('Person')}
            customStyle={{ flex: 0.28 }}
          />
        ),
        groupedBy: (
          <SortableTableHeader
            header="Person"
            sortBy={groupBySort}
            changeSort={() => changeGroupBySort('Person')}
            customStyle={{ flex: 0.28 }}
          />
        ),
      },
    },
    {
      agency: {
        displayed: <SortableTableHeader header="Agency" sortBy={sortBy} changeSort={() => changeSort('Agency')} />,
        groupedBy: (
          <SortableTableHeader header="Agency" sortBy={groupBySort} changeSort={() => changeGroupBySort('Agency')} />
        ),
      },
    },
    {
      notes: {
        displayed: <UnSortableTableHeader header="Notes" customStyle={{ paddingRight: '20px' }} />,
      },
    },
    {
      actions: {
        displayed: <UnSortableTableHeader header="Actions" customStyle={{ textAlign: 'end', paddingRight: '15px' }} />,
      },
    },
  ];

  orderedHeaders.sort((a, b) => {
    if (a[groupByTerm] === b[groupByTerm]) return 0;
    return a[groupByTerm] ? -1 : 1;
  });
  return (
    <div className="auspices-table-header-container">
      {orderedHeaders.map((possibleComponents, idx) => {
        const { groupedBy, displayed } = Object.values(possibleComponents)[0];
        if (idx === 0 && groupedBy) {
          return <Fragment key={idx}>{groupedBy}</Fragment>;
        }
        return <Fragment key={idx}>{displayed}</Fragment>;
      })}
    </div>
  );
};
