import React, { Component } from 'react';
import { styled } from 'react-free-style';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Card } from '@united-talent-agency/julius-frontend-components';
import { getProjectCastings } from '@united-talent-agency/julius-frontend-store';
import { sortCastings } from '../../../../../support/helpers';
export const CLASSNAMES = {
  cardHeader: 'casting-header',
  cardEntry: 'casting-entry',
};

class CastingsCard extends Component {
  componentDidMount() {
    const { projectId } = this.props;
    this.props.dispatch(getProjectCastings(projectId));
  }

  render() {
    const { projectCastings, styles } = this.props;
    let castings = (projectCastings && projectCastings.projectCastings) || [];
    castings = sortCastings(castings);
    return (
      <Card title="CASTINGS">
        {() => {
          return (
            <div>
              <Row className={`${styles.header} ${CLASSNAMES.cardHeader}`}>
                <Col>ROLE</Col>
                <Col>STATUS</Col>
                <Col>TALENT</Col>
              </Row>
              {castings
                .filter(({ status, name }) => {
                  return name && status && status !== 'Open';
                })
                .map(({ status, name, personId }, index) => (
                  <React.Fragment key={index}>
                    <hr style={{ margin: 0 }} />
                    <Row className={`${styles.rows} ${CLASSNAMES.cardEntry}`} key={index}>
                      <Col>{name}</Col>
                      <Col>{status}</Col>
                      <Col className={personId && personId.type === 'Client' ? styles.boldedCastingName : ''}>
                        {(personId && personId.name) || 'Unknown'}
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
            </div>
          );
        }}
      </Card>
    );
  }
}

const withStyles = styled({
  header: {
    fontSize: 12,
    fontWeight: 100,
    marginTop: 2,
    marginBottom: 2,
    color: 'gray',
  },
  rows: {
    fontWeight: 300,
    fontSize: 12,
    marginTop: 6,
    marginBottom: 6,
  },
  castingDetails: {
    fontWeight: 300,
  },
  boldedCastingName: {
    fontWeight: 800,
  },
});

const withState = connect(state => ({ projectCastings: state.projectCastings }));

export default withState(withStyles(CastingsCard));
