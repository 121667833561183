import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { helpers, styled } from 'react-free-style';
import { Helmet } from 'react-helmet';
import Notifications from 'react-notify-toast';
import { connect } from 'react-redux';

import { colors } from '@united-talent-agency/julius-frontend-components';
import { PrivateRoute, LoginCallback, redirectToAzure } from '@united-talent-agency/julius-frontend-store';
import { errors, notifications } from '@united-talent-agency/components';

import { defaultUrl, apiServerUrl } from './support/urls';
import Projects from './containers/projects';
import Login from './components/login/login';

import aktivGroteskLight from './assets/fonts/aktiv-grotesk-light.woff';
import aktivGroteskRegular from './assets/fonts/aktiv-grotesk-regular.woff';
import aktivGroteskBold from './assets/fonts/aktiv-grotesk-bold.woff';

import { withLDProvider } from 'launchdarkly-react-client-sdk';

const localVersion = require('./git.json').sha;
const { NewRelease } = notifications;
const { REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS, REACT_APP_RELEASE_CHECK_FORCE_RELOAD } = process.env;
const forceReload = REACT_APP_RELEASE_CHECK_FORCE_RELOAD === true.toString();

class Routes extends React.Component {
  render() {
    const { props } = this;
    const { styles, project } = props;
    const LoginComponent = () => {
      return <Login defaultUrl={defaultUrl} apiServerUrl={apiServerUrl} redirectToAzure={redirectToAzure} />;
    };

    return (
      <Router>
        <div className={styles.container}>
          <Helmet>
            <title>{(project && project.name) || 'PROJECTS'}</title>
          </Helmet>
          <Notifications />
          <NewRelease
            currentVersion={localVersion}
            intervalSeconds={REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS}
            forceReload={forceReload}
          />
          <Switch>
            <Route path="/login" component={LoginComponent} />
            <Route path="/login-callback" component={() => LoginCallback(props)} />
            <PrivateRoute
              exact
              path={[
                '/',
                '/project-not-found',
                '/project/:projectId',
                '/project/:projectId/:tab',
                '/searchCastings',
                '/grids',
                '/grid/project_grid/:gridId',
                '/grid/casting_grid/:gridId',
                '/grids',
                '/lists',
                '/list/:listId',
                '/list/:listId/:tab',
              ]}
              component={Projects}
              dispatch={props.dispatch}
              apiServerUrl={apiServerUrl}
              deskRequiredComponent={errors.DeskRequired}
            />
            <Route path="*">{errors.NotFound}</Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

const withStyles = styled(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.background,
      fontFamily: 'aktiv-grotesk, sans-serif',
    },
  },
  {
    css: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        backgroundColor: colors.background,
        fontFamily: 'aktiv-grotesk, sans-serif',
        minWidth: 320,
        fontSize: 15,
        lineHeight: 1.3,
      },
    },
    rules: [
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskRegular),
          fontWeight: 400,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskBold),
          fontWeight: 700,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskLight),
          fontWeight: 300,
          fontStyle: 'normal',
        },
      ],
    ],
  }
);

const withState = connect(state => {
  const { user } = state;
  return { user };
});

export default withState(
  withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    user: {
      anonymous: true,
    },
    options: {
      bootstrap: 'localStorage',
      disableSyncEventPost: true,
    },
  })(withStyles(Routes))
);
