export const auspiceWeights = [
  'Creator',
  'Writer',
  'Showrunner',
  'Director',
  'Ex-Producer (Non-Writing)',
  'Ex-Producer',
  'Co Ex-Producer (Non-Writing)',
  'Co-Ex Producer',
  'Supervising Producer',
  'Consulting Producer',
  'Producer (Non-Writing)',
  'Producer',
  'Co-Producer',
  'Ex-Story Editor',
  'Story Editor',
  'Staff Writer',
  'Staffed',
  'Writing Assistant',
  'Talent',
  'Assistant Director',
  'Casting Associate',
  'Casting Director',
  'Consultant',
  'Dir of Photography',
  'Editor',
  'Freelance Script',
  'Line Producer',
  'Other',
  'Show Pitch',
  'Source Material By',
].reduce((acc, auspice, index) => {
  acc[auspice] = index + 1;
  return acc;
}, {});
