import React, { useReducer } from 'react';
import { every } from 'lodash';

import { GroupByHeaders } from './groupby-headers';
import { AuspicesEntry } from './groupby-auspice-entry';
import { sort as roleSort } from './sorter';

export const GroupByAuspicesTable = ({
  roles,
  loadRoles,
  project,
  updateRole,
  projectDeleteRole,
  allRoles,
  groupByTerm,
}) => {
  const [sortBy, setSortBy] = useReducer((state, newState) => ({ ...state, ...newState }), {
    direction: 'ASC',
    category: groupByTerm === 'status' ? 'Auspice' : 'Status',
  });
  const [groupBySort, setGroupBySort] = useReducer((state, newState) => ({ ...state, ...newState }), {
    direction: 'ASC',
    category: groupByTerm === 'type' ? 'Auspice' : 'Status',
  });

  const changeSort = newSortCategory => {
    if (newSortCategory !== sortBy.category) {
      sortBy.category = newSortCategory;
    } else {
      sortBy.direction = sortBy.direction === 'ASC' ? 'DSC' : 'ASC';
    }
    setSortBy(sortBy);
  };

  const changeGroupBySort = newSortCategory => {
    if (newSortCategory !== groupBySort.category) {
      groupBySort.category = newSortCategory;
    } else {
      groupBySort.direction = groupBySort.direction === 'ASC' ? 'DSC' : 'ASC';
    }
    setGroupBySort(groupBySort);
  };

  const determineGroup = (groupByTerm, role) => {
    const empty = ' ';
    if (groupByTerm === 'person') {
      return role.personId ? role.personId.name : empty;
    } else if (groupByTerm === 'agency') {
      return role.agency ? role.agency.name : empty;
    } else {
      return role[groupByTerm];
    }
  };

  const groupedRoles = roles.reduce((roleGrouping, role) => {
    let group = determineGroup(groupByTerm, role);
    if (roleGrouping[group]) {
      roleGrouping[group].roles = roleGrouping[group].roles.concat(role);
    } else {
      roleGrouping[group] = { allArchived: false, roles: [role] };
    }
    return roleGrouping;
  }, {});

  const order = groupBySort.direction === 'ASC' ? 1 : -1;
  const sortedGroupedRoles = Object.keys(groupedRoles)
    .sort((a, b) => {
      if (a === ' ') return order;
      if (b === ' ') return order * -1;
      return a.localeCompare(b) * order;
    })
    .reduce((acc, key) => {
      acc[key] = groupedRoles[key];
      return acc;
    }, {});

  const _determineIfAssociatedWithUTA = groupedRole => groupedRole.agency && groupedRole.agency.name === 'UTA';
  sortedGroupedRoles &&
    Object.keys(sortedGroupedRoles).forEach(key => {
      const groupedRoles = sortedGroupedRoles[key].roles;
      if (groupByTerm === 'person' && groupedRoles.some(_determineIfAssociatedWithUTA)) {
        sortedGroupedRoles[key].personEmphasis = true;
      }
      roleSort(sortBy, groupedRoles);
      if (every(groupedRoles, { active: false })) {
        sortedGroupedRoles[key].allArchived = true;
      }
    });

  return (
    <React.Fragment>
      <GroupByHeaders
        groupBySort={groupBySort}
        changeGroupBySort={changeGroupBySort}
        sortBy={sortBy}
        changeSort={changeSort}
        groupByTerm={groupByTerm}
      />
      <div className="auspices-table">
        {sortedGroupedRoles &&
          Object.keys(sortedGroupedRoles).map((key, idx) => {
            const { roles, allArchived, personEmphasis } = sortedGroupedRoles[key];
            const roleComps = roles.map((role, roleNumber) => {
              return (
                <AuspicesEntry
                  allArchived={allArchived}
                  isFirst={roleNumber === 0}
                  role={role}
                  key={role._id}
                  loadRoles={loadRoles}
                  project={project}
                  updateRole={updateRole}
                  projectDeleteRole={projectDeleteRole}
                  existingRoles={allRoles}
                  groupByTerm={groupByTerm}
                  personEmphasis={personEmphasis}
                />
              );
            });
            return (
              <div key={idx}>
                {roleComps}
                <div style={{ height: '9px' }} />
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
};
