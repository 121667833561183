import React, { Component } from 'react';

import { Input } from 'reactstrap';

class Checkbox extends Component {
  // eslint-disable-next-line no-undef
  state = {
    value: this.props.value,
    checked: this.props.checked,
  };

  render() {
    const { checked, disabled, id, name, title, value, extraStyles = {} } = this.props;

    const _id = id || name;
    const _name = name || id;

    const onChange = e => {
      const checked = e.target.checked;
      this.setState({ checked });
      this.props.onChange && this.props.onChange(id, checked);
    };

    return (
      <span>
        <Input
          id={_id}
          name={_name}
          type="checkbox"
          style={{ ...{ height: '14px', width: '14px' }, ...extraStyles }}
          onChange={onChange}
          checked={checked}
          value={value}
          disabled={disabled}
        />
        <p style={{ margin: '0 5px 0 0', fontWeight: 300 }}>{title}</p>
      </span>
    );
  }
}

export default Checkbox;
