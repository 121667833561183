import { datadogRum } from '@datadog/browser-rum';

const HOST_MAP = {
  'projects.unitedtalent.com': 'prod',
  'projects.staging.unitedtalent.com': 'staging',
  'projects.qa.unitedtalent.com': 'dev',
  localhost: 'local',
};

/**
 * Initializes data dog for the appropriate host (environment)
 *
 * @param { string } host:
 * @param { string[] } allowableEnvs: a list of allowable environments to track.
 */
export const initializeDataDog = (host, allowableEnvs) => {
  const env = HOST_MAP[host];
  if (allowableEnvs.includes(env)) {
    datadogRum.init({
      env,
      version: '0.3.1',
      service: 'projects-united-talent',
      site: 'us3.datadoghq.com',
      applicationId: '5c6e4223-e359-4cb4-8226-cc748e5dab21',
      clientToken: 'pub571a2e08c947591c30220bbe28bea315',
      allowedTracingOrigins: ['https://api.unitedtalent.com', /https:\/\/.*\.unitedtalent\.com/],
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });
  }
};
