import React, { useState } from 'react';
import { styled } from 'react-free-style';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { TextareaAutosize } from '@material-ui/core';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';

import moment from 'moment';
import validUrl from 'valid-url';
import ItemSelector from '../item-selector/item-selector';
import { icons } from '../../styles/icons';
import { statuses } from '../../support/items/rep-agreements';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    zIndex: 1,
    font: { input: { size: 12, weight: 300 } },
    color: {
      ...DefaultTheme.reactDates.color,
      text: 'black',
      outside: { color_hover: 'blue' },
      selected: { backgroundColor: 'rgb(87, 140, 247)', color: 'white' },
    },
  },
});

const RepAgreementItem = props => {
  const {
    styles,
    key,
    fileLocation,
    onFileLocationChanged,
    status,
    onStatusChanged,
    summary,
    onSummaryChanged,
    executionDate,
    onExecutionDateChanged,
    amended,
    onAmendedChanged,
  } = props;
  const [calendarFocused, setCalendarFocused] = useState();

  return (
    <React.Fragment>
      <div className={styles.container} key={key}>
        {/* Box Link */}
        <div style={{ width: '25%', paddingTop: 5, paddingBottom: 10 }}>File Location</div>
        <div style={{ width: '75%', textAlign: 'right' }}>
          <div>
            <input
              className={styles.input}
              value={fileLocation || ''}
              onChange={e => {
                onFileLocationChanged && onFileLocationChanged(e.target.value);
              }}
              placeholder="Insert Box Link to the Agreement."
            />
          </div>
          {validUrl.isHttpsUri(fileLocation) && (
            <div>
              <a href={fileLocation} target="_blank" rel="noopener noreferrer">
                View Agreement
              </a>
            </div>
          )}
        </div>
      </div>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <div className={styles.container} key={key}>
        <div style={{ width: '50%', paddingTop: 5, paddingBottom: 10 }}>Status</div>
        <div style={{ width: '50%', textAlign: 'right', alignItems: 'right', paddingRight: 0 }}>
          {/* Status */}

          <ItemSelector
            placeholder="Agreement Status"
            className={styles.inputShort}
            text={status}
            items={statuses.map(s => {
              return { name: s, _id: s };
            })}
            onSelect={item => {
              onStatusChanged && onStatusChanged(item.name);
              item.name === 'Fully Executed' && onExecutionDateChanged && onExecutionDateChanged(moment());
            }}
            onBlurComplete={true}
            style={{
              fontWeight: 400,
              textAlign: 'right',
              display: 'inline-block',
              left: 25,
              color: '#212529',
            }}
          />

          {/* Execution Date */}
          {status === 'Fully Executed' && (
            <div>
              <SingleDatePicker
                id="execution-date"
                date={moment(executionDate)}
                onDateChange={date => {
                  if (date) {
                    onExecutionDateChanged && onExecutionDateChanged(date);
                  }
                }}
                onFocusChange={({ focused }) => setCalendarFocused(focused)}
                noBorder={true}
                numberOfMonths={1}
                focused={calendarFocused}
                small
                isOutsideRange={() => false}
              />
            </div>
          )}
        </div>
      </div>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />

      <div className={styles.container} key={key}>
        <div style={{ width: '25%', paddingTop: 5, paddingBottom: 10 }}>Deal Summary</div>
        <div style={{ width: '75%', textAlign: 'right', alignItems: 'right' }}>
          {/* Deal Summary */}
          <div style={{ display: 'flex', textAlign: 'right', alignItems: 'right' }}>
            <TextareaAutosize
              className={styles.textArea}
              value={summary || ''}
              onChange={e => {
                onSummaryChanged && onSummaryChanged(e.target.value);
              }}
              placeholder="Deal Summary (Add Lines as Needed)"
            />
          </div>
        </div>
      </div>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <div className={styles.container} key={key}>
        <div style={{ width: '50%', paddingTop: 5, paddingBottom: 10 }}>Amended</div>
        <div style={{ width: '50%', textAlign: 'right', alignItems: 'right', paddingRight: 0 }}>
          {/* Amended */}
          <ItemSelector
            placeholder="Amendment to Agreement?"
            className={styles.inputShort}
            text={(amended === true || amended === false) && (amended ? 'Yes' : 'No')}
            items={[
              { name: 'Yes', _id: 'Yes' },
              { name: 'No', _id: 'No' },
            ]}
            onSelect={item => {
              onAmendedChanged && onAmendedChanged(item.name === 'Yes');
            }}
            onBlurComplete={true}
            style={{
              fontWeight: 400,
              textAlign: 'right',
              display: 'inline-block',
              left: 25,
              color: '#212529',
            }}
          />
        </div>
      </div>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
    </React.Fragment>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    fontWeight: 300,
    fontSize: '12px',
    paddingTop: 5,
    paddingRight: 0,
  },
  input: {
    position: 'relative',
    display: 'inline-block',
    paddingTop: 7,
    paddingBottom: 7,

    fontWeight: 400,
    color: '#212529',
    fontSize: 12,
    border: '1px solid rgba(0, 0, 0, 0)',
    width: '95%',
    '&:hover': {
      border: '1px solid rgb(87, 140, 247)',
    },
    '&:focus': {
      textAlign: 'left',
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'right',
  },
  textArea: {
    border: '1px solid rgba(0, 0, 0, 0)',
    '&:hover': {
      border: '1px solid rgb(87, 140, 247)',
    },
    '&:focus': {
      textAlign: 'left',
    },
    paddingTop: 7,
    resize: 'none',
    fontWeight: 400,
    marginRight: 0,
    width: '100%',
    textAlign: 'right',
    color: '#212529',
  },
  delete: {
    cursor: 'pointer',
    padding: '10px 0',
    '&+&': {
      marginLeft: '10px',
    },
  },
  hidden: {
    color: 'rgba(0,0,0,0)',
  },
  deleteIcon: icons.smallCrossBlack,
});

export default withStyles(RepAgreementItem);
