import React, { Component } from 'react';
import { styled } from 'react-free-style';
import { icons } from '../../styles/icons';
import Select from 'react-select';
import { rolesByProjectType as roles } from '../../support/items/roles';
import { statusesByProjectType } from '../../support/items/statuses';
import ItemSelector from '../item-selector/item-selector';

class RoleListItem extends Component {
  render() {
    const {
      onDelete,
      styles,
      key,
      onPersonChange,
      onPersonSelect,
      onPositionSelect,
      onStatusSelect,
      people,
      position,
      status,
      person,
      personType,
      short,
      projectType,
      readonly,
    } = this.props;

    return (
      <div className={styles.container} key={key}>
        <div style={{ width: '30%', display: 'flex' }}>
          {readonly && position}
          {!readonly && (
            <Select
              className={styles.autocomplete}
              value={{ value: position, label: position }}
              onChange={value => {
                onPositionSelect(value);
              }}
              options={
                roles &&
                projectType &&
                roles[projectType].map(r => {
                  return { value: r, label: r };
                })
              }
            />
          )}
        </div>
        <div style={{ width: '30%', display: 'flex' }}>
          {readonly && status}
          {!readonly && (
            <Select
              className={styles.autocomplete}
              value={{ value: status, label: status }}
              onChange={value => {
                onStatusSelect(value);
              }}
              options={
                statusesByProjectType &&
                projectType &&
                statusesByProjectType[projectType].map(r => {
                  return { value: r, label: r };
                })
              }
            />
          )}
        </div>
        <div style={{ width: '40%', display: 'flex' }}>
          {readonly && person}
          {!readonly && (
            <ItemSelector
              placeholder="Add Person"
              className={short ? styles.inputShort : styles.input}
              text={person ? person : ''}
              style={{ fontWeight: personType && personType === 'Client' ? 800 : 100 }}
              items={people}
              onChange={name => {
                onPersonChange && onPersonChange(name);
              }}
              onSelect={item => {
                onPersonSelect && onPersonSelect(item);
              }}
              onDelete={onDelete}
              onBlurComplete={true}
              disabled={status === 'Open'}
            />
          )}
        </div>
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    fontWeight: 300,
    fontSize: '12px',
  },
  input: {
    flex: '1 1 auto',
    marginRight: '10px',
    '& input': {
      paddingTop: '2em',
      paddingBottom: '2em',
    },
  },
  inputShort: {
    flex: '1 1 auto',
    marginRight: '10px',
  },
  autocomplete: {
    width: '200px',
    fontWeight: 100,
    fontSize: '12px',
  },
  section: {
    '&+&': {
      marginLeft: '10px',
    },
  },
  delete: {
    cursor: 'pointer',
    padding: '10px 0',
  },
  hidden: {
    color: 'rgba(0,0,0,0)',
  },
  deleteIcon: icons.smallCrossBlack,
});

export default withStyles(RoleListItem);
