import React from 'react';
import { PolarityBadge } from './polarity-badge';
import { styled } from 'react-free-style';
import cypressTags from '../../support/cypressTags';

const ProjectBadgesSansStyles = props => {
  return props && props.badges && props.badges.length > 0 ? (
    <div className="badges">
      <button
        data-cy={cypressTags.SEARCH_PROJECTS.RESET_BUTTON}
        label="reset"
        className={props.styles.clearFiltersButton}
        onClick={() => props.resetFilters()}
      >
        Reset
      </button>
      {props.badges &&
        props.badges.length > 0 &&
        props.badges.map((badge, i) => <PolarityBadge key={i} badge={badge} />)}
    </div>
  ) : null;
};
const withStyles = styled({
  clearFiltersButton: {
    marginTop: '0.5em',
    fontSize: '80%',
    color: 'gray',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none !important',
  },
});
const ProjectBadges = withStyles(ProjectBadgesSansStyles);
export { ProjectBadges };
