import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  createProjectCasting,
  updateProjectCasting,
  updateProjectCastingForm,
  updateProject,
} from '@united-talent-agency/julius-frontend-store';
import { getGroups, groupRoles } from '../../../api/groups';
import { DoneButtons } from '../../../components/done-buttons/done-buttons';
import ItemSelector from '../../../components/item-selector/item-selector';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline';
import { toUnixTime } from '../../../support/date';
import buildSelectItems from '../../../support/build-select-items';
import genders from '../../../support/items/genders';
import ethnicities from '../../../support/items/ethnicities';
import { statusesByProjectType as statuses, defaultStatuses } from '../../../support/items/statuses';
import defaultCastingTypes, { castingTypesByProjectType as castingTypes } from '../../../support/items/casting-types';
import { getRepresentedByAgencies } from '../../../dao/person-agencies';

import LaunchDarklyWrapper from '../../../components/LDWrapper/LaunchDarklyWrapper';
import { getPeopleIndex, getCompaniesIndex } from '../../../support/algolia/get-index';
import { searchClient } from '../../../support/algolia/algolia-search-client';
import cypressTags from '../../../support/cypressTags';

const ProjectCastingForm = ({ projectCastingForm, dispatch, isEdit, project, onDone }) => {
  const { _id } = projectCastingForm;
  const [isSaving, setSaving] = useState(false);
  const [ageMin, setAgeMin] = useState(projectCastingForm.ageMin || '');
  const [ageMax, setAgeMax] = useState(projectCastingForm.ageMax || '');
  const [description, setDescription] = useState(projectCastingForm.description || '');
  const [gender, setGender] = useState(projectCastingForm.gender || '');
  const [ethnicity, setEthnicity] = useState(projectCastingForm.ethnicity || '');
  const [notes, setNotes] = useState(projectCastingForm.notes || '');
  const [priority, setPriority] = useState(projectCastingForm.priority || '');
  const [status, setStatus] = useState(projectCastingForm.status || '');
  const [type, setType] = useState(projectCastingForm.type || '');
  const [person, setPerson] = useState(projectCastingForm._person || {});
  const [peopleResults, setPeopleResults] = useState([]);
  const [role, setRole] = useState(projectCastingForm.name || '');
  const [ageInputError, setAgeInputError] = useState(
    ageMax && Number(ageMax) < Number(ageMin) ? 'Age max must be greater than age min' : ''
  );
  const [representedByAgency, setRepresentedByAgency] = useState(projectCastingForm._representedByAgency);
  const [representedByAgencyName, setRepresentedByAgencyName] = useState(
    (projectCastingForm._representedByAgency && projectCastingForm._representedByAgency.name) || ''
  );
  const [talentAgencies, setTalentAgencies] = useState([]);
  const [active, setActive] = useState(projectCastingForm.active !== undefined ? projectCastingForm.active : true);
  const buildByProjectType = (field, values, defaultVal, currentVal, onClick) => {
    const { type } = project;
    return buildSelectItems(field, values[type] ? values[type] : defaultVal, currentVal, onClick);
  };

  const finished = () => {
    setSaving(false);
    onDone();
  };
  const submit = async e => {
    e.preventDefault();
    setSaving(true);

    const projectCasting = {
      projectId: project._id,
      personId: person,
      ageMax,
      ageMin,
      description,
      gender,
      ethnicity,
      notes,
      name: role,
      priority: parseInt(priority),
      status,
      type,
      representedByAgency,
      representedByAgencyName,
      created_date: toUnixTime(new Date()),
      active,
    };

    if (!project.castings) {
      project.castings = [];
    }

    if (!projectCasting.personId || !projectCasting.personId._id) {
      projectCasting.personId = null;
    }

    const castings = project.castings;
    if (isEdit) {
      await dispatch(updateProjectCasting(_id, projectCasting));
    } else {
      const _projectCasting = await dispatch(createProjectCasting(projectCasting));
      castings.push(_projectCasting.body);
      await dispatch(updateProject(project._id, { castings }));
    }

    finished();
  };

  const onClick = (field, item) => {
    dispatch(updateProjectCastingForm(field, item));
  };

  const genderItems = buildSelectItems('gender', genders, gender, onClick);
  const ethnicityItems = buildSelectItems('ethnicity', ethnicities, ethnicity, onClick);

  // The array thing just returns [['1', '1']..['20', '20']]
  const priorityItems = buildSelectItems(
    'priority',
    [...Array(21).keys()].slice(1).map(number => {
      const entry = number.toString();
      return [entry, entry];
    })
  );
  const typeItems = buildByProjectType('type', castingTypes, defaultCastingTypes, type, onClick);
  const statusItems = buildByProjectType('status', statuses, defaultStatuses, status, onClick);

  const handleChange = event => {
    const { name, value } = event.target;
    event.persist();
    switch (name) {
      case 'Role':
        setRole(value);
        break;
      case 'Age Min':
        setAgeMin(value);
        break;
      case 'Age Max':
        if (Number(value) < Number(ageMin)) {
          setAgeInputError('Age max must be greater than age min');
        } else if (ageInputError && Number(value) >= Number(ageMin)) {
          setAgeInputError('');
        }
        setAgeMax(value);
        break;
      case 'Description':
        setDescription(value);
        break;
      case 'Notes':
        setNotes(value);
        break;
      case 'Gender':
        setGender(value);
        break;
      case 'Ethnicity':
        setEthnicity(value);
        break;
      case 'Status':
        setStatus(value);
        break;
      case 'Priority':
        setPriority(value.toString());
        break;
      case 'Type':
        setType(value);
        break;
      case 'Archived':
        setActive(value === 'No');
        break;
      default:
        break;
    }
    if (value === 'Open') {
      setTalentAgencies(null);
      setPeopleResults(null);
      setPerson(null);
      setRepresentedByAgency(null);
      setRepresentedByAgencyName(null);
      setTalentAgencies(null);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={e => submit(e)}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Role"
              name="Role"
              placeholder="Role"
              value={role}
              onChange={handleChange}
              data-cy={cypressTags.PROJECT.CASTING_ROLE_INPUT}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Gender"
              name="Gender"
              value={gender}
              onChange={handleChange}
              data-cy={cypressTags.PROJECT.CASTING_GENEDER_INPUT}
            >
              {genderItems.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Ethnicity"
              name="Ethnicity"
              value={ethnicity}
              onChange={handleChange}
            >
              {ethnicityItems.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Type"
              value={type}
              name="Type"
              onChange={handleChange}
              data-cy={cypressTags.PROJECT.CASTING_TYPE_INPUT}
            >
              {typeItems.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Priority"
              name="Priority"
              value={priority}
              onChange={handleChange}
            >
              {priorityItems.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="number"
              label="Age Min"
              value={ageMin}
              name="Age Min"
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Age Max"
              value={ageMax}
              error={!!(ageMax && Number(ageMax) < Number(ageMin))} // Otherwise, interpreted as a string
              fullWidth
              name="Age Max"
              variant="outlined"
              type="number"
              helperText={ageInputError}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={4}
              fullWidth
              name="Description"
              label="Description"
              variant="outlined"
              value={description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Status"
              value={status}
              name="Status"
              onChange={handleChange}
              data-cy={cypressTags.PROJECT.CASTING_STATUS_INPUT}
            >
              {statusItems.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {status && status !== 'Open' ? (
            <Grid item xs={9}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div style={{ position: 'relative' }}>
                    <InputLabel htmlFor={'talentWrapper'} variant="outlined" shrink>
                      Talent
                    </InputLabel>
                    <div style={{ marginTop: -10, paddingBottom: 10 }}>
                      <div id="talentWrapper">
                        <ItemSelector
                          title="Talent"
                          key="personId"
                          text={person && person.name}
                          items={peopleResults}
                          onSelect={p => {
                            setPerson(p);
                            setPeopleResults(null);
                            getRepresentedByAgencies(p).then(result => {
                              if (result.primary) {
                                setRepresentedByAgency(result.primary);
                                setRepresentedByAgencyName(result.primary.name);
                              } else {
                                setRepresentedByAgency(null);
                                setRepresentedByAgencyName(null);
                              }

                              return setTalentAgencies(result.agencies);
                            });
                          }}
                          onChange={text => {
                            setRepresentedByAgency(null);
                            setRepresentedByAgencyName(null);
                            setTalentAgencies(null);
                            if (person) {
                              person.name = text;
                              person._id = text;
                              setPerson(person);
                            } else {
                              setPerson({ _id: text, name: text });
                            }
                            const index = getPeopleIndex();
                            const indexSetting = {
                              filters: '(projectRoles.k: talent)',
                              hitsPerPage: 10,
                              page: 0,
                              restrictSearchableAttributes: ['name'],
                            };
                            if (text !== '') {
                              searchClient(index, text, indexSetting).then(results => {
                                const people = results.hits?.map(person => ({
                                  _id: person._id,
                                  name: person.name,
                                  type: person.type,
                                  personVerified: person.verifiedBy && person.verifiedOn,
                                }));
                                setPeopleResults(people);
                              });
                            } else {
                              setPerson({ _id: text, name: text });
                              setPeopleResults([]);
                            }
                          }}
                          onDelete={() => {
                            setPerson(null);
                            setRepresentedByAgency(null);
                            setRepresentedByAgencyName(null);
                            setTalentAgencies(null);
                          }}
                          onBlurComplete={true}
                          placeholder="Set Talent"
                          style={{ fontSize: 14, fontWeight: person && person.type === 'Client' ? 400 : 100 }}
                          dataCy={cypressTags.PROJECT.CASTING_TALENT_INPUT}
                        />
                        <NotchedOutline notched labelWidth={45} style={{ borderColor: '#CCC' }} />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ position: 'relative' }}>
                    <InputLabel htmlFor={'repByWrapper'} variant="outlined" shrink>
                      Represented By
                    </InputLabel>
                    <div style={{ marginTop: -10, paddingBottom: 10 }}>
                      <div id="repByWrapper">
                        <LaunchDarklyWrapper
                          render={flags => {
                            return (
                              <>
                                {flags?.algoliaCompanySearch ? (
                                  <ItemSelector
                                    title="Represented By"
                                    key="representedByAgency"
                                    text={representedByAgencyName}
                                    items={talentAgencies}
                                    onSelect={company => {
                                      setRepresentedByAgency(company);
                                      setRepresentedByAgencyName(company.name);
                                    }}
                                    onChange={text => {
                                      setRepresentedByAgency(null);
                                      setRepresentedByAgencyName(text);
                                      const index = getCompaniesIndex();
                                      const filters = '(roles:talentAgency)';
                                      searchClient(index, text, { filters }).then(results =>
                                        setTalentAgencies(results.hits)
                                      );
                                    }}
                                    onDelete={() => {
                                      setRepresentedByAgency(null);
                                      setRepresentedByAgencyName(null);
                                    }}
                                    onBlurComplete={true}
                                    placeholder="Set Representing Agency"
                                    style={{ fontSize: 14 }}
                                  />
                                ) : (
                                  <ItemSelector
                                    title="Represented By"
                                    key="representedByAgency"
                                    text={representedByAgencyName}
                                    items={talentAgencies}
                                    onSelect={company => {
                                      setRepresentedByAgency(company);
                                      setRepresentedByAgencyName(company.name);
                                    }}
                                    onChange={text => {
                                      setRepresentedByAgency(null);
                                      setRepresentedByAgencyName(text);
                                      return getGroups(text.trim(), {
                                        role: groupRoles['Talent Agencies'],
                                      }).then(({ data }) => setTalentAgencies(data));
                                    }}
                                    onDelete={() => {
                                      setRepresentedByAgency(null);
                                      setRepresentedByAgencyName(null);
                                    }}
                                    onBlurComplete={true}
                                    placeholder="Set Representing Agency"
                                    style={{ fontSize: 14 }}
                                  />
                                )}
                              </>
                            );
                          }}
                        />
                        <NotchedOutline notched labelWidth={45} style={{ borderColor: '#CCC' }} />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={9} />
          )}
          <Grid item xs={10}>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              margin="dense"
              rows={5}
              label="Notes"
              name="Notes"
              value={notes}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid item xs={12} style={{ paddingTop: 8 }}>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="Archived"
                name="Archived"
                value={active ? 'No' : 'Yes'}
                onChange={handleChange}
              >
                {[
                  { key: 'Yes', content: 'Yes' },
                  { key: 'No', content: 'No' },
                ].map(option => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.content}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <div style={{ paddingTop: 12 }} data-cy={cypressTags.PROJECT.SAVE_CASTING_BUTTON}>
                <DoneButtons
                  disabled={ageMax && ageMin && Number(ageMax) < Number(ageMin)}
                  isActive={true}
                  isSaving={isSaving}
                  onDone={() => finished()}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} /> {/* Empty grid to add spacing */}
        </Grid>
      </form>
    </ThemeProvider>
  );
};

const theme = createTheme({
  palette: {
    primary: grey,
  },
  overrides: {
    MuiSelect: {
      select: {
        color: '​#808080',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#808080',
        letterSpacing: -0.25,
      },
    },
    MuiOutlinedInput: {
      root: {
        color: '#808080',
      },
    },
  },
});

const withState = connect(state => {
  const { projectCastings, projects } = state;
  return { project: projects.project, projectCastingForm: projectCastings.projectCastingForm };
});

export default withState(ProjectCastingForm);
