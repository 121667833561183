import React, { Component } from 'react';
import Checkbox from 'react-three-state-checkbox';
import { styled } from 'react-free-style';
import classnames from 'classnames';

const openProjectView = (event, project) => {
  const target = event && (event.ctrlKey || event.metaKey) ? '_blank' : 'project_detail';
  window.open(`/project/${project._id}`, target);
};

class Project extends Component {
  render() {
    const { project, styles, key, columns, onSelectedChanged, selected } = this.props;
    const nameClasses = [!project.active && styles.archived, project.newInfo && styles.newInfo].filter(c => {
      return !!c;
    });

    return (
      <tr key={key} className={styles.tr}>
        <td>
          <Checkbox
            checked={selected}
            className={`selected_${project._id}`}
            onChange={() => {
              onSelectedChanged && onSelectedChanged(project);
            }}
          />
        </td>

        {columns &&
          columns.map((column, index) => {
            return (
              <td
                key={index}
                className={column.name === 'Name' ? classnames(...nameClasses) : ''}
                onClick={e => openProjectView(e, project)}
              >
                {column.format(project)}
              </td>
            );
          })}
      </tr>
    );
  }
}

const withStyles = styled({
  tr: {
    '&:hover': {
      '>td': {
        backgroundColor: '#F9F9F9',
        cursor: 'pointer',
      },
    },
  },
  archived: {
    textDecoration: 'line-through',
  },
  newInfo: {
    fontWeight: 400,
  },
});

export default withStyles(Project);
