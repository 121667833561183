export default [
  'Artistic Director',
  'Assistant Director',
  'Associate Director',
  'Book & Lyrics',
  'Book Writer',
  'Cast',
  'Casting Associate',
  'Casting Director',
  'Choreographer',
  'Co-Ex-Producer',
  'Co-Producer',
  'Consultant',
  'Costume Designer',
  'Designer',
  'Dir of Photography',
  'Director',
  'Director of Photography',
  'Editor',
  'Ex-Producer',
  'Ex-Story Editor',
  'Executive',
  'Executive Producer',
  'Financier',
  'Freelance Script',
  'General Manager',
  'Light Designer',
  'Line Producer',
  'Lyricist',
  'Music',
  'Music & Lyrics',
  'Musical Director',
  'Musical Supervisor',
  'Other',
  'Playwright',
  'Producer',
  'Projection Designer',
  'Set Designer',
  'Show Pitch',
  'Sound Designer',
  'Source Material By',
  'Staff Writer',
  'Story Editor',
  'Supervising Producer',
  'Talent',
  'Writer',
  'Writer (Adapted)',
  'Writer (Current)',
  'Writer (Previous)',
  'Writer/Director',
];

const featureRoles = [
  'Assistant Director',
  'Casting Assistant',
  'Casting Associate',
  'Casting Director',
  'Co-Producer',
  'Co-Director',
  'Director',
  'Director of Photography',
  'Editor',
  'Executive',
  'Ex-Producer',
  'Financier',
  'Other',
  'Producer',
  'Source Material By',
  'Writer (Current)',
  'Writer (Previous)',
];

const tvRoles = [
  'Assistant Director',
  'Casting Assistant',
  'Casting Associate',
  'Casting Director',
  'Co Ex-Producer',
  'Co Ex-Producer (Non-Writing)',
  'Co-Producer',
  'Consultant',
  'Consulting Producer',
  'Creator',
  'Dir of Photography',
  'Director',
  'Editor',
  'Ex-Producer',
  'Ex-Producer (Non-Writing)',
  'Ex-Story Editor',
  'Freelance Script',
  'Line Producer',
  'Other',
  'Producer',
  'Producer (Non-Writing)',
  'Show Pitch',
  'Showrunner',
  'Source Material By',
  'Staff Writer',
  'Staffed',
  'Story Editor',
  'Supervising Producer',
  'Talent',
  'Writer',
  'Writing Assistant',
];

const realityRoles = [
  'Ex-Producer',
  'Co Ex-Producer',
  'Showrunner',
  'Supervising Producer',
  'Line Producer',
  'Director',
  'Host',
  'Co-Host',
  'Judge',
  'Correspondent',
  'Participant',
];

const theatreRoles = [
  'Artistic Director',
  'Associate Director',
  'Book & Lyrics',
  'Book Writer',
  'Casting Assistant',
  'Casting Director',
  'Choreographer',
  'Costume Designer',
  'Designer',
  'Director',
  'General Manager',
  'Lighting Designer',
  'Lyricist',
  'Music',
  'Music & Lyrics',
  'Musical Director',
  'Musical Supervisor',
  'Playwright',
  'Producer',
  'Projection Designer',
  'Set Designer',
  'Sound Designer',
  'Writer',
  'Writer (Adapted)',
];

export const rolesByProjectType = {
  Feature: featureRoles.sort(),
  Television: tvRoles.sort(),
  Unscripted: realityRoles.sort(),
  'Independent Film Dept.': featureRoles.sort(),
  Theatre: theatreRoles.sort(),
};
