import React, { useState } from 'react';
import { styled } from 'react-free-style';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { TextareaAutosize } from '@material-ui/core';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';

import moment from 'moment';
import validUrl from 'valid-url';
import ItemSelector from '../item-selector/item-selector';
import { icons } from '../../styles/icons';
import { statuses } from '../../support/items/rep-agreements';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    zIndex: 1,
    font: { input: { size: 12, weight: 300 } },
    color: {
      ...DefaultTheme.reactDates.color,
      text: 'black',
      outside: { color_hover: 'blue' },
      selected: { backgroundColor: 'rgb(87, 140, 247)', color: 'white' },
    },
  },
});

const RepAgreementsItem = props => {
  const {
    styles,
    key,
    fileLocation,
    onFileLocationChanged,
    status,
    onStatusChanged,
    summary,
    onSummaryChanged,
    executionDate,
    onExecutionDateChanged,
    amended,
    onAmendedChanged,
    onDelete,
  } = props;
  const [calendarFocused, setCalendarFocused] = useState();

  return (
    <div className={styles.container} key={key}>
      {/* Box Link */}
      <div style={{ width: '30%' }}>
        <div>
          <input
            className={styles.input}
            value={fileLocation}
            onChange={e => {
              onFileLocationChanged && onFileLocationChanged(e.target.value);
            }}
            placeholder="File Location"
          />
        </div>
        {validUrl.isHttpsUri(fileLocation) && (
          <div style={{ paddingLeft: 5, marginTop: 10 }}>
            <a href={fileLocation} target="_blank" rel="noopener noreferrer">
              View Agreement
            </a>
          </div>
        )}
        <div style={{ paddingLeft: 10, verticalAlign: 'top' }}>
          <input
            type="checkbox"
            id="amended"
            style={{ marginTop: 2 }}
            checked={amended}
            onChange={() => {
              onAmendedChanged && onAmendedChanged(!amended);
            }}
          />
          <label htmlFor="amended" style={{ marginLeft: 6 }}>
            Amended
          </label>
        </div>
      </div>

      <div style={{ width: 135 }}>
        {/* Status */}
        <div>
          <ItemSelector
            placeholder="Status"
            className={styles.inputShort}
            text={status}
            items={statuses.map(s => {
              return { name: s, _id: s };
            })}
            onSelect={item => {
              onStatusChanged && onStatusChanged(item.name);
              item.name === 'Fully Executed' && onExecutionDateChanged && onExecutionDateChanged(moment());
            }}
            //onDelete={onDelete}
            onBlurComplete={true}
            disabled={status === 'Open'}
          />
        </div>

        {/* Execution Date */}
        {status === 'Fully Executed' && (
          <div>
            <SingleDatePicker
              id="execution-date"
              date={moment(executionDate)}
              onDateChange={date => {
                if (date) {
                  onExecutionDateChanged && onExecutionDateChanged(date);
                }
              }}
              onFocusChange={({ focused }) => setCalendarFocused(focused)}
              noBorder={true}
              numberOfMonths={1}
              focused={calendarFocused}
              small
              isOutsideRange={() => false}
            />
          </div>
        )}
      </div>
      {/* Deal Summary */}
      <div style={{ display: 'flex' }}>
        <TextareaAutosize
          className={styles.textArea}
          value={summary}
          onChange={e => {
            onSummaryChanged && onSummaryChanged(e.target.value);
          }}
          placeholder="Deal Summary"
        />
      </div>
      {/* Delete  */}
      {onDelete && (
        <div
          className={styles.delete}
          onClick={() => {
            onDelete && onDelete();
          }}
          key={`${key}-delete`}
        >
          <i className={styles.deleteIcon} />
        </div>
      )}
    </div>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    fontWeight: 300,
    fontSize: '12px',
    paddingTop: 5,
  },
  input: {
    position: 'relative',
    display: 'flex',
    padding: 7,
    fontWeight: 300,
    fontSize: 12,
    border: '1px solid rgba(0, 0, 0, 0)',
    width: '95%',
    '&:hover': {
      border: '1px solid rgb(87, 140, 247)',
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textArea: {
    border: '1px solid rgba(0, 0, 0, 0)',
    '&:hover': {
      border: '1px solid rgb(87, 140, 247)',
    },
    paddingTop: 7,
    resize: 'none',
    fontWeight: 300,
    marginRight: 10,
  },
  delete: {
    cursor: 'pointer',
    padding: '10px 0',
    '&+&': {
      marginLeft: '10px',
    },
  },
  hidden: {
    color: 'rgba(0,0,0,0)',
  },
  deleteIcon: icons.smallCrossBlack,
});

export default withStyles(RepAgreementsItem);
