import download from 'downloadjs';
import { apiServerUrl } from './urls';
import { requestPromise } from '@united-talent-agency/julius-frontend-store';

export async function downloadFile(
  url,
  fileName,
  user,
  desk,
  setIsDownloading,
  options,
  query = [],
  performRequest = requestPromise
) {
  /**
   * Generate the appropriate request for a file and pass the blob to downloadjs
   * @param {string} url - the path portion of the URL
   * @param {string} fileName - the name of the file when it's actually downloaded
   * @param {object} user - the user object
   * @param {object} desk - to supply the desk ID if applicable
   * @param {function} setIsDownloading - to update the component when the download begins and ends
   * @param {map} options - map of options supplied to the API
   * @param {array} query - a list of dictionaries for query parameters.
   * @param {function} performRequest - requestPromise from the store, injected for testing purposes.
   */
  setIsDownloading(url, true);

  // Options become the body of the request, so it changes the HTTP method to POST.
  const method = options ? 'POST' : 'GET';
  if (desk && desk._id) {
    query.push({ deskId: desk._id });
  }

  const fileRequest = await performRequest(user, apiServerUrl, `/${url}`, method, options, query, 'application/pdf');

  const responseBlob = await fileRequest.blob();

  download(responseBlob, `${fileName}.pdf`, 'application/pdf');

  setIsDownloading(url, false);
}
