import React, { useState } from 'react';
import moment from 'moment';
import { EditProjectInfo } from './edit-project-info';
import Card from '../card/card';
import { Info } from '../info/info';
import { hasAccess } from '../../support/permissions';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { isValidUrl } from './edit-project-info';
const ProjectInfo = props => {
  const { project, title, saveChanges, onCancel } = props;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Card
      title={title}
      canEdit
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      saveChanges={saveChanges}
      onCancel={onCancel}
    >
      {({ updateBy, isEditing }) => {
        const updateProject = data => {
          updateBy('project', project._id, data);
        };
        return (
          <div>
            {!isEditing && (
              <React.Fragment>
                <Info type="text" name="Last Updated">
                  {project.last_updated_date && moment(project.last_updated_date).format('YYYY-MM-DD')}
                </Info>
                {project.type === 'Television' && (
                  <Info type="text" name="Status">
                    {project['tvStatus']}
                  </Info>
                )}
                <Info type="text" name="Type">
                  {project['type']}
                </Info>
                {(project.type === 'Feature' || project.type === 'Unscripted') && hasAccess('details', 'indieStatus') && (
                  <Info type="text" name="Indie Status">
                    {project.indieStatus}
                  </Info>
                )}
                <Info type="text" name="Starts">
                  {!!project['opened_date'] && typeof project['opened_date'] === 'string'
                    ? moment(project['opened_date']).format('YYYY-MM-DD')
                    : ''}
                </Info>
                <Info type="text" name="Thru">
                  {!!project['thru_date'] && typeof project['thru_date'] === 'string'
                    ? moment(project['thru_date']).format('YYYY-MM-DD')
                    : ''}
                </Info>
                <Info type="text" name="Date Notes">
                  {project['date_notes']}
                </Info>
                {project.type === 'Theatre' ? null : (
                  <Info type="text" name="Budget">
                    {project['budget']}
                  </Info>
                )}
                {project.type === 'Theatre' ? null : (
                  <Info type="text" name="Budget Notes">
                    {project['budget_notes']}
                  </Info>
                )}
                <Info type="text" name="Location">
                  {project['location']}
                </Info>
                <Info type="text" name="Script">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: '270px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {project['script']}
                    </div>
                    {(isValidUrl(project['script']) && (
                      <a className="ml-2" target="_blank" rel="noopener noreferrer" href={project['script']}>
                        <DownloadIcon />
                      </a>
                    )) ||
                      project['script']}
                  </div>
                </Info>
                <Info type="text" name="Blurb">
                  {project['blurb']}
                </Info>
              </React.Fragment>
            )}
            {isEditing && <EditProjectInfo testId="EditProjectInfo" project={project} updateProject={updateProject} />}
          </div>
        );
      }}
    </Card>
  );
};

export { ProjectInfo };
