import React, { useState, useReducer } from 'react';
import { TextArea } from '@united-talent-agency/julius-frontend-components';

import { StatusInput, PersonInput, TalentAgencyInput, PositionsInput } from './inputs';
import { getRepresentedByAgencies } from '../../../../../dao/person-agencies';
import { DoneButtons } from '../../../../../components/done-buttons/done-buttons';
import cypressTags from '../../../../../support/cypressTags';

const TEXT_DANGER_RED = '#dc3545';

export const AuspiceAdder = ({ project, loadRoles, createRole, allRoles, onDone }) => {
  const [newPositionState, setNewPositionState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    positions: [],
    status: null,
    person: null,
    agency: null,
    note: '',
  });
  const [personAgencies, setPersonAgencies] = useState([]);

  const [errorState, setErrorState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    showPositionError: false,
    showStatusError: false,
    showPersonError: false,
    duplicateError: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const addPositions = ({ status, personId, types, agency, note }) => {
    const payload = {
      active: true,
      status,
      personId,
      projectId: project._id,
      types,
      agency: agency && agency._id,
      note,
    };
    setErrorState({
      showPositionError: false,
      duplicateError: false,
      showStatusError: false,
      showPersonError: false,
    });
    setIsLoading(true);
    createRole(payload).then(() => {
      setNewPositionState({ positions: [], status: null, person: null, agency: null, note: '' });
      loadRoles().then(() => setIsLoading(false));
    });
  };

  const checkForErrors = () => {
    let errors = { errorsFound: false };
    const { status, positions, person } = newPositionState;
    const potentialDuplicateIndex =
      person &&
      allRoles.findIndex(role => {
        if (role.personId && role.personId.name) {
          return positions.includes(role.type) && role.personId.name === person.name;
        }
        return positions.includes(role.type) && role.personId === person.name;
      });
    const isDuplicate = person && potentialDuplicateIndex !== -1;
    if (isDuplicate) {
      const duplicateErrorMessage = allRoles[potentialDuplicateIndex].active
        ? 'Please specify a unique entry.'
        : 'Please specify a unique entry. Check archived positions for duplicate.';
      errors.duplicateError = duplicateErrorMessage;
      errors.errorsFound = true;
    }
    !status && Object.assign(errors, { showStatusError: true, errorsFound: true });
    !positions.length && Object.assign(errors, { showPositionError: true, errorsFound: true });
    !person && status !== 'Open' && Object.assign(errors, { showPersonError: true, errorsFound: true });
    return errors;
  };

  const save = () => {
    const { errorsFound, duplicateError, showPositionError, showStatusError, showPersonError } = checkForErrors();
    if (errorsFound) {
      setErrorState({ duplicateError, showPositionError, showStatusError, showPersonError });
      return;
    }
    const { status, positions, person, agency = '', note = '' } = newPositionState;

    if (positions.some(pos => pos.value) && status === 'Open') {
      addPositions({ status, personId: null, types: positions.map(pos => pos.value), agency, note });
      onDone();
      return;
    }
    addPositions({ status, personId: person._id, types: positions.map(pos => pos.value), agency, note });
    onDone();
  };

  const determineControlStyle = error => {
    return error
      ? { borderColor: TEXT_DANGER_RED, minHeight: null, backgroundColor: 'white' }
      : { minHeight: null, backgroundColor: 'white' };
  };

  const determinePlaceholderStyle = error => {
    return error
      ? { fontSize: '.7em', top: '8px', margin: 0, color: TEXT_DANGER_RED }
      : { fontSize: '.7em', top: '8px', margin: 0 };
  };

  const { type } = project;
  const { showPositionError, duplicateError, showStatusError, showPersonError } = errorState;
  const { positions, status, person, note, agency } = newPositionState;

  positions.length && showPositionError && setErrorState({ showPositionError: false });
  status && showStatusError && setErrorState({ showStatusError: false });
  person && showPersonError && setErrorState({ showPersonError: false });

  const getDisabledPositions = person => {
    if (person) {
      return allRoles
        .filter(role => role.personId && (person.name === role.personId || person.name === role.personId.name))
        .map(role => role.type);
    }
    return [];
  };

  const onSelectPerson = selected => {
    const update = { person: {} };
    if (selected.__isNew__) {
      update.person = { _id: selected.value, name: selected.value };
    } else {
      update.person = { _id: selected.value._id, name: selected.value.name };
    }

    if (positions.length > 0) {
      const disabledPositions = getDisabledPositions(update.person);
      update.positions = positions.filter(position => !disabledPositions.includes(position));
    }

    setNewPositionState(update);
    getRepresentedByAgencies(update.person).then(personAgencies => {
      if (personAgencies.primary) {
        setNewPositionState({ agency: personAgencies.primary });
      }
      setPersonAgencies(personAgencies.agencies);
    });
  };

  return (
    <div className="add-auspice-form-wrapper">
      <form
        className="add-auspice-form"
        onSubmit={e => {
          e.preventDefault();
          save();
        }}
      >
        <div className="positions-adder-row">
          <PersonInput
            onChange={onSelectPerson}
            isLoading={isLoading}
            value={person}
            flex=".34"
            indicatorsContainerStyles={{ display: 'none' }}
            placeholderStyles={determinePlaceholderStyle(showPersonError)}
            controlStyles={determineControlStyle(showPersonError)}
            errorMessage="Unless it's an 'Open' auspice, this field is required."
            showError={showPersonError}
            disableProfileLink
            dataCy={cypressTags.PROJECT.AUSPICE_PERSON_INPUT}
          />
          <StatusInput
            onChange={({ value }) => setNewPositionState({ status: value })}
            isLoading={isLoading}
            value={status}
            type={type}
            flex=".33"
            controlStyles={determineControlStyle(showStatusError)}
            indicatorsContainerStyles={{ display: 'none' }}
            placeholderStyles={determinePlaceholderStyle(showStatusError)}
            errorMessage="This field is required."
            showError={showStatusError}
            dataCy={cypressTags.PROJECT.AUSPICE_STATUS_INPUT}
          />
          <TalentAgencyInput
            onChange={change => {
              if (!change) {
                setNewPositionState({ agency: '' });
                return;
              }
              const { value = '' } = change;
              if (typeof value === 'string') {
                setNewPositionState({ agency: { name: value, label: value, _id: value } });
              } else {
                setNewPositionState({ agency: value });
              }
            }}
            isLoading={isLoading}
            value={agency}
            flex=".33"
            defaultAgencies={personAgencies}
            controlStyles={determineControlStyle()}
            placeholderStyles={determinePlaceholderStyle()}
            indicatorsContainerStyles={{ display: 'none' }}
            disableCompanyProfileLink
            dataCy={cypressTags.PROJECT.AUSPICE_AGENCY_INPUT}
          />
        </div>
        <div className="positions-adder-row">
          <PositionsInput
            onSelect={positions => setNewPositionState({ positions })}
            isLoading={isLoading}
            selectedOptions={positions}
            disabledOptions={getDisabledPositions(person)}
            flex=".34"
            type={type}
            indicatorsContainerStyles={{ display: 'none' }}
            placeholderStyles={determinePlaceholderStyle(showPositionError)}
            controlStyles={determineControlStyle(showPositionError)}
            errorMessage="This field is required."
            showError={showPositionError}
            dataCy={cypressTags.PROJECT.AUSPICE_POSITION_INPUT}
          />
          <div className="positions-adder-notes">
            <TextArea title="Notes" onChange={note => setNewPositionState({ note })} value={note} />
          </div>
        </div>
        <div className="form-done-buttons" data-cy={cypressTags.PROJECT.AUSPICE_SAVE_BUTTON}>
          <DoneButtons
            // isSaving={isSaving}
            isActive={true}
            onDone={onDone}
          />
        </div>
        {duplicateError && <div className="text-danger auspices-table-duplicate-error">{duplicateError}</div>}
      </form>
    </div>
  );
};
