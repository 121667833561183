export const onRemoveBadge = (badge, filterModel, dispatch, user, recordEvent) => {
  const removeItemFromArray = (array, item, key = null) => {
    if (!array) return array;
    return array.filter(element => (key ? element[key] !== item : element !== item));
  };

  const removalActions = {
    ageRange: () => {
      delete filterModel['ageMin'];
      delete filterModel['ageMax'];
      delete filterModel['ageRange'];
    },
    gender: () => {
      filterModel.gender.include = removeItemFromArray(filterModel.gender?.include, badge.name);
      filterModel.gender.exclude = removeItemFromArray(filterModel.gender?.exclude, badge.name);
    },
    ethnicities: () => {
      filterModel.ethnicities.include = removeItemFromArray(filterModel.ethnicities?.include, badge.name);
      filterModel.ethnicities.exclude = removeItemFromArray(filterModel.ethnicities?.exclude, badge.name);
    },
    statuses: () => {
      filterModel.statuses.include = removeItemFromArray(filterModel.statuses?.include, badge.name);
      filterModel.statuses.exclude = removeItemFromArray(filterModel.statuses?.exclude, badge.name);
    },
    profiles: () => {
      filterModel.profiles.include = removeItemFromArray(filterModel.profiles?.include, badge.name, 'name');
      filterModel.profiles.exclude = removeItemFromArray(filterModel.profiles?.exclude, badge.name, 'name');
    },
    networks: () => {
      filterModel.networks.include = removeItemFromArray(filterModel.networks?.include, badge.name, 'name');
      filterModel.networks.exclude = removeItemFromArray(filterModel.networks?.exclude, badge.name, 'name');
    },
    studios: () => {
      filterModel.studios.include = removeItemFromArray(filterModel.studios?.include, badge.name, 'name');
      filterModel.studios.exclude = removeItemFromArray(filterModel.studios?.exclude, badge.name, 'name');
    },
    productionCompanies: () => {
      filterModel.productionCompanies.include = removeItemFromArray(
        filterModel.productionCompanies?.include,
        badge.name,
        'name'
      );
      filterModel.productionCompanies.exclude = removeItemFromArray(
        filterModel.productionCompanies?.exclude,
        badge.name,
        'name'
      );
    },
    distribution: () => {
      filterModel.distribution.include = removeItemFromArray(filterModel.distribution?.include, badge.name);
      filterModel.distribution.exclude = removeItemFromArray(filterModel.distribution?.exclude, badge.name);
    },
    types: () => {
      filterModel.types.include = removeItemFromArray(filterModel.types.include, badge.name);
      filterModel.types.exclude = removeItemFromArray(filterModel.types.exclude, badge.name);
    },
    default: () => {
      delete filterModel[badge.property];
    },
  };

  (removalActions[badge.property] || removalActions.default)();

  dispatch(
    recordEvent(
      'projects.search.remove-badge',
      'projects-web',
      'info',
      '1',
      [{ data: `${badge.name}_${badge.label}` }],
      user._id
    )
  );

  return filterModel;
};
