import React from 'react';
import { Helmet } from 'react-helmet';
import { styled } from 'react-free-style';
import { Link } from 'react-router-dom';

const ProjectNotFound = ({ styles }) => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>PROJECT NOT FOUND</title>
      </Helmet>

      <div className={styles.content}>
        <div className={styles.title}>404</div>
        <div className={styles.subTitle}>Project not found</div>
        <Link className={styles.backLink} to="/">
          Back to homepage
        </Link>
      </div>
    </div>
  );
};

const withStyles = styled({
  container: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '90px',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: '18px',
  },
  backLink: {
    marginTop: '20px',
    fontSize: '18px',
  },
});

export default withStyles(ProjectNotFound);
