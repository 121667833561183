import React, { Component } from 'react';
import { helpers, styled } from 'react-free-style';
import { connect } from 'react-redux';
import { NavLink, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { notify } from 'react-notify-toast';

import {
  colors,
  elements,
  sizes,
  IconButton,
  EditButton,
  buttonTypes,
} from '@united-talent-agency/julius-frontend-components';
import { Spinner } from '@united-talent-agency/components';
import {
  loadProject,
  updateProject,
  deleteProject,
  createProjectNote,
  updateProjectNote,
  projectLoadRoles,
  updateRole,
  createRole,
  loadTalentNotes,
  loadLiteraryNotes,
  loadTouringNotes,
  loadActs,
  getProjectCastings,
} from '@united-talent-agency/julius-frontend-store';

import Title from '../../../components/title/title';
import ExceptionHandler from '../../../components/exception-handler/exception-handler';
import CastingsView from './views/castings/castings';
import InformationView from './views/information/information';
import TouringInformationView from './views/touring-information/touring-information';
import TrackingsView from './views/trackings/trackings';
import ActivityView from './views/activity/activity';
import { AuspicesView } from './views/auspices/';
import TalentNotesView from './views/notes/talentNotes/talentNotes';
import LiteraryNotesView from './views/notes/literaryNotes/literaryNotes';
import TouringNotesView from './views/notes/touringNotes/touringNotes';
import IndieNotesView from './views/notes/indieNotes/indieNotes';
import ActsView from './views/notes/acts/acts';
import { downloadFile } from '../../../support/download';
import PrintManager from '../../../components/print-manager/print-manager';
import { defaultUrl } from '../../../support/urls';
import { hasAccess } from '../../../support/permissions';
import cypressTags from '../../../support/cypressTags';

const allProjectTypes = ['Feature', 'Television', 'Unscripted', 'Theatre', 'Fairs and Festivals'];
const standardTypes = ['Feature', 'Television', 'Unscripted', 'Theatre'];
const indieTypes = ['Feature', 'Unscripted'];
const touringTypes = ['Fairs and Festivals'];

const updates = {
  project: updateProject,
  note: updateProjectNote,
};

const creates = {
  note: createProjectNote,
};

const showPositionsTab = process.env.REACT_APP_SHOW_SEPARATE_POSITIONS_TAB === 'true';

const FairsAndFestivalsMenu = ({ project, styles }) => (
  <React.Fragment>
    <NavLink
      exact={true}
      to={`/project/${project._id}`}
      className={styles.menuItem}
      activeClassName={styles.activeItem}
    >
      General
    </NavLink>
    <NavLink to={`/project/${project._id}/acts`} activeClassName={styles.activeItem} className={styles.menuItem}>
      Confirmed Acts
    </NavLink>
    <NavLink
      to={`/project/${project._id}/touring-notes`}
      activeClassName={styles.activeItem}
      className={styles.menuItem}
    >
      Notes
    </NavLink>
    <NavLink to={`/project/${project._id}/activity`} className={styles.menuItem} activeClassName={styles.activeItem}>
      Activity
    </NavLink>
  </React.Fragment>
);

export const DefaultProjectMenu = ({ project, styles = {}, canSeeIndieNotes }) => (
  <React.Fragment>
    <NavLink
      exact={true}
      to={`/project/${project._id}`}
      className={styles.menuItem}
      activeClassName={styles.activeItem}
    >
      General
    </NavLink>
    {showPositionsTab && (
      <NavLink to={`/project/${project._id}/auspices`} className={styles.menuItem} activeClassName={styles.activeItem}>
        Auspices
      </NavLink>
    )}
    <NavLink to={`/project/${project._id}/castings`} className={styles.menuItem} activeClassName={styles.activeItem}>
      Castings
    </NavLink>
    <NavLink to={`/project/${project._id}/trackings`} className={styles.menuItem} activeClassName={styles.activeItem}>
      Trackings
    </NavLink>
    {canSeeIndieNotes && (
      <NavLink
        to={`/project/${project._id}/indie-notes`}
        className={styles.menuItem}
        activeClassName={styles.activeItem}
      >
        Indie Notes
      </NavLink>
    )}
    <NavLink
      to={`/project/${project._id}/literary-notes`}
      className={styles.menuItem}
      activeClassName={styles.activeItem}
    >
      Literary Notes
    </NavLink>
    <NavLink
      to={`/project/${project._id}/talent-notes`}
      className={styles.menuItem}
      activeClassName={styles.activeItem}
    >
      Talent Notes
    </NavLink>
    <NavLink to={`/project/${project._id}/activity`} className={styles.menuItem} activeClassName={styles.activeItem}>
      Activity
    </NavLink>
  </React.Fragment>
);

const FairsAndFestivalsRoutes = ({ viewProps, acts, touringNotes, project }) => (
  <React.Fragment>
    <Route
      exact={true}
      path="/project/:id"
      render={routerProps => <TouringInformationView {...routerProps} {...viewProps} visibleForTypes={touringTypes} />}
    />
    <Route
      path="/project/:id/acts"
      render={routerProps => acts && <ActsView {...routerProps} project={project} touringNotes={touringNotes} />}
    />
    <Route
      path="/project/:id/touring-notes"
      render={routerProps =>
        touringNotes && <TouringNotesView {...routerProps} project={project} touringNotes={touringNotes} />
      }
    />
    <Route
      path="/project/:id/activity"
      render={routerProps => <ActivityView {...routerProps} {...viewProps} visibleForTypes={allProjectTypes} />}
    />
  </React.Fragment>
);

const DefaultRoutes = ({ viewProps, literaryNotes, project, talentNotes, desk, canSeeIndieNotes, user }) => {
  return (
    <React.Fragment>
      <Route
        exact={true}
        path="/project/:id"
        render={routerProps => <InformationView {...routerProps} {...viewProps} visibleForTypes={standardTypes} />}
      />
      <Route
        path="/project/:id/activity"
        render={routerProps => <ActivityView {...routerProps} {...viewProps} visibleForTypes={allProjectTypes} />}
      />
      {canSeeIndieNotes && (
        <Route
          path="/project/:id/indie-notes"
          render={routeProps => (
            <IndieNotesView
              {...routeProps}
              {...viewProps}
              project={project}
              visibleForTypes={indieTypes}
              desk={desk}
              user={user}
            />
          )}
        />
      )}
      <Route
        path="/project/:id/literary-notes"
        render={routeProps =>
          literaryNotes && <LiteraryNotesView {...routeProps} project={project} literaryNotes={literaryNotes} />
        }
      />
      <Route
        path="/project/:id/talent-notes"
        render={routeProps =>
          talentNotes && <TalentNotesView {...routeProps} project={project} talentNotes={talentNotes} />
        }
      />
      <Route
        path="/project/:id/trackings"
        render={routerProps => <TrackingsView {...routerProps} {...viewProps} visibleForTypes={standardTypes} />}
      />
      <Route
        path="/project/:id/castings"
        render={routerProps => <CastingsView {...routerProps} {...viewProps} visibleForTypes={standardTypes} />}
      />
      {showPositionsTab && (
        <Route
          path="/project/:id/auspices"
          render={routerProps => <AuspicesView {...routerProps} {...viewProps} visibleForTypes={standardTypes} />}
        />
      )}
    </React.Fragment>
  );
};

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: 0,
      view: 'information',
      isEdit: false,
      isPrintManagerCollapsed: true,
      originals: {},
      changedItems: [],
      lastChangeFrom: null,
    };
    this.onChange = this.onChange.bind(this);
    this._projectEntities = [
      'networks',
      'studios',
      'productionCompanies',
      'talentAgents',
      'literaryAgents',
      'pointAgents',
      'indieAgents',
      'contacts',
      'parentCompanies',
      'promoters',
      'repAgreements',
      'salesFinancing',
      'readBy',
    ];
  }

  componentDidMount() {
    const { dispatch, projectId } = this.props;
    projectId && this.setForm(dispatch, projectId);
  }

  async setForm(dispatch, projectId) {
    const projectLoader = dispatch(loadProject(projectId));
    const roleLoader = dispatch(projectLoadRoles(projectId));
    const castingsLoader = dispatch(getProjectCastings(projectId));
    const talentNotesLoader = dispatch(loadTalentNotes(projectId));
    const literaryNotesLoader = dispatch(loadLiteraryNotes(projectId));
    const touringNotesLoader = dispatch(loadTouringNotes(projectId));
    const actNotesLoader = dispatch(loadActs(projectId));

    return await Promise.all([
      projectLoader,
      roleLoader,
      castingsLoader,
      talentNotesLoader,
      literaryNotesLoader,
      touringNotesLoader,
      actNotesLoader,
    ]).then(results => {
      if (!results[0].ok) return;
      const project = results[0].body;
      const roles = results[1].body;
      const castings = results[2].body;
      const { originals } = this.state;
      this._projectEntities.forEach(pe => {
        originals[pe] = project[pe] ? JSON.parse(JSON.stringify(project[pe])) : [];
      });
      originals.roles = roles ? JSON.parse(JSON.stringify(roles)) : [];
      originals.castings = castings ? JSON.parse(JSON.stringify(castings)) : [];
      this.setState({ originals: originals });
    });
  }

  onChange(item) {
    const { project, roles } = this.props;
    const { originals } = this.state;

    if (item === 'roles') {
      this.changedListValidator(this.rolesHaveChanges, roles, originals.roles, item);
    } else if (
      item === 'repAgreements' ||
      item === 'salesFinancing' ||
      item === 'networks' ||
      item === 'studios' ||
      item === 'productionCompanies' ||
      item === 'readBy'
    ) {
      const jsonComparitor = (list1, list2) => {
        const hasChanges = JSON.stringify(list1) !== JSON.stringify(list2);
        return hasChanges;
      };
      this.changedListValidator(jsonComparitor, project[item], originals[item], item);
    } else if (
      this._projectEntities.some(i => {
        return i === item;
      })
    ) {
      this.changedListValidator(this.arrayHasNameChanges, project[item], originals[item], item);
    } else {
      throw Error(`Unsupported change element: ${item}`);
    }
  }

  // eslint-disable-next-line no-undef
  changedListValidator = function(comparator, list1, list2, item) {
    const { changedItems } = this.state;
    const hasChanges = comparator(list1, list2);
    const knownChange = changedItems.some(i => {
      return i === item;
    });
    if (hasChanges && !knownChange) {
      changedItems.push(item);
      this.setState({ changedItems });
    } else if (!hasChanges && knownChange) {
      this.setState({
        changedItems: changedItems.filter(i => {
          return i !== item;
        }),
      });
    } else {
      //this is stupid, but it forces a for the child making the change
      this.setState({ lastChangeFrom: item });
    }
  };

  arrayHasNameChanges(array1, array2) {
    if ((!array1 && array2) || (array1 && !array2) || array1.length !== array2.length) {
      return true;
    }
    return array1.some((a, index) => {
      return (
        a.name.trim().toLowerCase() !== array2[index].name.trim().toLowerCase() ||
        (index > 0 && a.primary !== array2[index].primary)
      );
    });
  }

  // eslint-disable-next-line no-undef
  rolesHaveChanges = function(array1, array2) {
    if ((!array1 && array2) || (array1 && !array2)) {
      return true;
    }
    const actives1 = array1.filter(a => {
      return a.active;
    });
    const actives2 = array2.filter(a => {
      return a.active;
    });
    if (actives1.length !== actives2.length) {
      return true;
    }
    return actives1.some((a, index) => {
      return (
        a.status.trim().toLowerCase() !== actives2[index].status.trim().toLowerCase() ||
        (a.type ? a.type.trim().toLowerCase() : '') !==
          (actives2[index].type ? actives2[index].type.trim().toLowerCase() : '') ||
        (a.personId && !actives2[index].personId) ||
        (!a.personId && actives2[index].personId) ||
        (a.personId ? a.personId.name.trim().toLowerCase() : '') !==
          (actives2[index].personId ? actives2[index].personId.name.trim().toLowerCase() : '')
      );
    });
  };

  cancelAll() {
    const { changedItems, originals } = this.state;
    const { project, roles } = this.props;
    changedItems.forEach(ci => {
      if (ci === 'roles') {
        roles.splice(0, roles.length);
        JSON.parse(JSON.stringify(originals.roles)).forEach(r => {
          roles.push(r);
        });
      } else if (
        this._projectEntities.some(pe => {
          return pe === ci;
        })
      ) {
        project[ci] = JSON.parse(JSON.stringify(originals[ci]));
      }
    });
    this.setState({ changedItems: [] });
  }

  saveAll() {
    const { changedItems, originals } = this.state;
    const { dispatch, project, roles } = this.props;
    const projectUpdates = {};
    const projectUpdatedEntities = [];
    const updatePromises = [];
    changedItems.forEach(ci => {
      if (ci === 'roles') {
        const rolesCopy = JSON.parse(JSON.stringify(roles));
        const rolePromises = rolesCopy.map(r => {
          if (r.personId) {
            if (r.personId.name.trim() === '') {
              r.personId = null;
            } else {
              r.personId = r.personId._id;
            }
          }

          r.projectId = project._id;
          if (!r._id) {
            // Create the role
            r.projectId = project._id;
            return dispatch(createRole(r));
          } else {
            // Update the role
            return dispatch(updateRole(r._id, r));
          }
        });
        const roleUpdatePromise = Promise.all(rolePromises)
          .then(() => {
            return dispatch(projectLoadRoles(project._id));
          })
          .then(results => {
            const freshRoles = results.body;
            roles.splice(0, roles.length);
            freshRoles.forEach(r => {
              roles.push(r);
            });
            originals.roles = JSON.parse(JSON.stringify(roles));
          });
        updatePromises.push(roleUpdatePromise);
      } else if (
        this._projectEntities.some(pe => {
          return pe === ci;
        })
      ) {
        projectUpdatedEntities.push(ci);
        projectUpdates[ci] = project[ci];
      }
    });
    if (Object.keys(projectUpdates).length > 0) {
      const projectUpdatePromise = dispatch(updateProject(project._id, projectUpdates)).then(result => {
        const p = result.body;
        projectUpdatedEntities.forEach(pue => {
          project[pue] = p[pue];
          originals[pue] = JSON.parse(JSON.stringify(p[pue]));
        });
      });
      updatePromises.push(projectUpdatePromise);
    }

    return Promise.all(updatePromises).then(() => {
      this.setState({ originals, changedItems: [] });
      notify.show('Project Saved', 'custom', 2000, { background: '#000000', text: '#FFFFFF' });
    });
  }

  saveChanges(changes) {
    const { project, dispatch } = this.props;

    const actions = [];

    Object.keys(changes.creates || {}).forEach(type => {
      const action = creates[type];
      const payloads = changes.creates[type] || [];

      payloads.forEach(payload => {
        actions.push(action(payload));
      });
    });

    Object.keys(changes.updates || {}).forEach(type => {
      if (type === 'projectRole') {
        const roles = changes.updates['projectRole'] || {};
        Object.keys(roles).forEach(id => {
          if (id.includes('NEW_ROLE')) {
            dispatch(createRole(roles[id]));
          } else {
            dispatch(updateRole(id, roles[id]));
          }
        });
      } else {
        const action = updates[type];
        const entities = changes.updates[type] || {};

        Object.keys(entities).forEach(id => {
          actions.push(action(id, entities[id]));
        });
      }
    });

    actions.push(loadProject(project._id));

    return actions.reduce((p, action) => {
      return p.then(() => this.props.dispatch(action));
    }, Promise.resolve());
  }

  shareLinkInEmail(project) {
    const trackingLink = `${process.env.REACT_APP_PROJECTS_URL}/project/${project._id}/information`;
    const emailString = `mailto:?subject=${project.name}&body=${trackingLink}`;

    window.location.href = emailString;
  }

  handleDeleteProject() {
    const { dispatch, projectId } = this.props;
    dispatch(deleteProject(projectId)).then(() => {
      // after deleting the project, navigate back to the home screen
      window.location.href = defaultUrl;
    });
  }

  getFocusItem() {
    const { castings } = this.props;
    return castings && castings[this.state.focused];
  }

  print = (options = {}) => {
    const { project } = this.props;
    this.performDownload(
      'v2/print-report',
      options.saveAs || project.name,
      {
        options,
        projects: [project._id],
      },
      [{ printType: 'project_detail' }]
    );
  };

  // eslint-disable-next-line no-undef
  setIsDownloading = (url, isDownloading) => {
    this.setState({ isPrinting: isDownloading });
  };

  performDownload(url, fileName, options, query) {
    const { user, desk } = this.props;
    if (this.state.isPrinting) {
      return;
    }

    downloadFile(url, fileName, user, desk, this.setIsDownloading, options, query);
  }

  // eslint-disable-next-line no-undef
  renderSaveCancel = isDirty => {
    const { styles } = this.props;
    const onCancel = () => {
      this.cancelAll();
    };
    const onSave = () => {
      this.saveAll();
    };
    return isDirty ? (
      <div className={styles.headerEdit}>
        <div className={styles.headerButtons}>
          {isDirty && onCancel && (
            <EditButton
              type={buttonTypes.cancel}
              className={styles.icon}
              onClick={() => {
                onCancel();
              }}
            />
          )}
          {isDirty && onSave && (
            <EditButton
              type={buttonTypes.save}
              className={styles.icon}
              onClick={() => {
                onSave();
              }}
            />
          )}
        </div>
      </div>
    ) : (
      <div style={{ height: 25 }} />
    );
  };

  render() {
    const {
      history,
      dispatch,
      notes,
      project,
      projectId,
      styles,
      user,
      profileTags,
      projects,
      roles,
      repAgreements,
      salesFinancing,
      literaryNotes,
      talentNotes,
      touringNotes,
      acts,
      desk,
      _hasAccess = hasAccess,
    } = this.props;
    const { isPrintManagerCollapsed, originals, changedItems } = this.state;
    const castings = originals.castings;

    if (!project) {
      return (
        <div className={styles.container}>
          <div className={styles.spinner}>
            <Spinner />
          </div>
        </div>
      );
    }
    const focused = this.getFocusItem();

    const viewProps = {
      project,
      roles,
      repAgreements,
      salesFinancing,
      profileTags,
      castings,
      dispatch,
      notes,
      focused,
      projects,
      setFocused: focused => this.setState({ focused }),
      saveChanges: x => {
        this.setState({ isEdit: false });
        this.saveChanges(x);
        this.setForm(dispatch, projectId);
      },
      onCancel: () => {
        this.setState({ isEdit: false });
        this.setForm(dispatch, projectId);
      },
      originals,
      onChange: this.onChange,
      changedItems,
      handleDeleteProject: this.handleDeleteProject.bind(this),
    };

    const isFairAndFestivals = project?.type === 'Fairs and Festivals';
    const canSeeIndieNotes =
      _hasAccess('details', 'indieNotes') && (project?.type === 'Feature' || project?.type === 'Unscripted');
    if (project?.message) {
      notify.show('Unable to load content, Please try later!', 'custom', 2000, {
        background: '#000000',
        text: '#FFFFFF',
      });
    }
    return (
      <ExceptionHandler history={history}>
        <div className={classnames(styles.container, styles.pageScrollArea)}>
          <Helmet>
            <title>{(project && project.name) || 'PROJECT DETAIL'}</title>
          </Helmet>
          <div className={styles.header}>
            <div className={styles.content}>
              <Title
                {...viewProps}
                editing={this.state.isEdit}
                setEdit={isEdit => {
                  this.setState({ isEdit });
                }}
                user={user}
              />
              <div className={classnames(styles.menu, 'projectMenu')}>
                {isFairAndFestivals ? (
                  <FairsAndFestivalsMenu styles={styles} project={project} />
                ) : (
                  <DefaultProjectMenu styles={styles} project={project} canSeeIndieNotes={canSeeIndieNotes} />
                )}
                <div className={classnames(styles.actionButtons, 'actionButtons')}>
                  <button
                    className={styles.printButton}
                    hidden={!isPrintManagerCollapsed}
                    onClick={() => this.setState({ isPrintManagerCollapsed: false })}
                    data-cy={cypressTags.PROJECT_ACTIONS.TOGGLE_PRINT_BUTTON}
                  >
                    <i className={classnames('fa fa-fw fa-print', 'printButton')} />
                    Print
                  </button>
                  <IconButton
                    className={classnames(styles.shareButton, 'shareButton')}
                    toggle={true}
                    onClick={() => this.shareLinkInEmail(project)}
                    trueText="Share"
                    trueIcon="fa-share-alt"
                  />
                </div>
              </div>
            </div>
          </div>
          {this.renderSaveCancel(changedItems.length > 0)}
          {!isPrintManagerCollapsed && (
            <PrintManager
              className={styles.printDrawer}
              collapseEvent={() => this.setState({ isPrintManagerCollapsed: true })}
              print={this.print.bind(this)}
              userInTalent={user && user.personId && user.personId.department === 'Talent'}
            />
          )}
          <div className={styles.body}>
            <div className={styles.content}>
              {isFairAndFestivals ? (
                <FairsAndFestivalsRoutes
                  acts={acts}
                  viewProps={viewProps}
                  project={project}
                  touringNotes={touringNotes}
                />
              ) : (
                <DefaultRoutes
                  literaryNotes={literaryNotes}
                  viewProps={viewProps}
                  project={project}
                  talentNotes={talentNotes}
                  desk={desk}
                  user={user}
                  canSeeIndieNotes={canSeeIndieNotes}
                />
              )}
            </div>
          </div>
        </div>
      </ExceptionHandler>
    );
  }
}

const withStyles = styled({
  container: {
    minWidth: 800,
  },
  header: {
    background: colors.contentBackground,
  },
  headerEdit: {
    background: '#90E2D3',
    display: 'flex',
  },
  headerButtons: {
    marginRight: '50px',
    marginLeft: 'auto',
    display: 'flex',
  },
  icon: {
    marginLeft: '10px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 48px)',
  },
  content: helpers.merge(sizes.container, {
    display: 'flex',
    flexDirection: 'column',
  }),
  menu: helpers.merge(elements.menu, {
    marginBottom: '5px',
  }),
  menuItem: elements.menuItem,
  activeItem: elements.activeMenuItem,
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginLeft: 'auto',
  },
  pageScrollArea: {
    marginBottom: '200px',
  },
  printButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: 1,
    marginLeft: '10px',
    padding: '5px 0',
    textTransform: 'uppercase',
    border: 'none',
    backgroundColor: 'unset',
    '&:focus': {
      outline: 'none',
    },
  },
});

const mapStateToProps = (store, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  const { user } = store;
  const { project } = store.projects;
  const { projectNotes } = store.projectNotes;
  const { profileTags, roles, repAgreements, salesFinancing } = store.projects;
  const { literaryNotes, touringNotes, talentNotes, acts } = store.projectNotes;

  return {
    user,
    projectId,
    project,
    notes: projectNotes,
    roles,
    repAgreements,
    salesFinancing,
    castings: store && store.castingSearch && store.castingSearch.castings,
    profileTags,
    literaryNotes,
    touringNotes,
    talentNotes,
    acts,
  };
};

export default connect(mapStateToProps)(withStyles(Project));
