import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { colors } from '@united-talent-agency/julius-frontend-components';
import { withStyles } from '@material-ui/core/styles';
import { loadActs, removeProjectNote } from '@united-talent-agency/julius-frontend-store';

import Act from '../../../../../../components/act/act.js';
import ProjectNoteForm from '../project-note-form';

const Acts = ({ project, classes }) => {
  const [isAddOpen, setOpen] = useState(false);
  const [isEdit, setIsEditing] = useState(false);
  const [focused, setFocused] = useState(0);
  const [note, setNote] = useState({});
  const acts = useSelector(state => state.projectNotes.acts);
  const dispatch = useDispatch();
  const focusedNote = (acts && acts[focused]) || {};
  useEffect(() => {
    dispatch(loadActs(project._id));
  }, [dispatch, project]);

  const noteType = 'Fairs and Festivals';
  const headers = ['Performance', 'Note'];
  return (
    <div>
      {isAddOpen && isEdit && (
        <div className={classes.addContainer}>
          <ProjectNoteForm
            isEdit={isEdit}
            project={project}
            noteType={noteType}
            note={note}
            onDone={() => {
              setOpen(false);
              dispatch(loadActs(project._id));
            }}
          />
        </div>
      )}
      <table id={'noteTable'} className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            {headers.map((header, index) => {
              return (
                <th scope="col" key={index} className={classes.thText}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        {acts && acts.length ? (
          <tbody>
            {acts
              .sort((a, b) => {
                const aCreated = a.created_date ? 1 : 0;
                const bCreated = b.created_date ? 1 : 0;
                if (aCreated === bCreated) {
                  return Date.parse(b.created_date) - Date.parse(a.created_date);
                } else {
                  return bCreated - aCreated;
                }
              })
              .map((act, index) => {
                if (act.status === 'Confirmed' && !act.archived) {
                  const isFocused = focusedNote._id === act._id;
                  return (
                    <Act
                      dispatch={dispatch}
                      act={act}
                      isEdit={isEdit}
                      index={index}
                      key={index}
                      onEditItem={async projectNote => {
                        setNote(projectNote);
                        setOpen(false); // force load person if add already open
                        setOpen(true);
                        setIsEditing(true);
                      }}
                      onDeleteItem={projectNote => {
                        dispatch(removeProjectNote(projectNote._id));
                        dispatch(loadActs(project._id));
                      }}
                      isFocused={isFocused}
                      setFocused={index => {
                        setFocused(index);
                      }}
                    />
                  );
                } else {
                  return undefined;
                }
              })}
          </tbody>
        ) : (
          <tbody />
        )}
      </table>
    </div>
  );
};

const styles = {
  addContainer: {
    width: '100%',
    maxWidth: '1000px',
    padding: '25px 10px 0 10px',
    margin: '0 auto',
    background: 'white',
    border: '1px black solid',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
};

export default withStyles(styles)(Acts);
