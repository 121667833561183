import React from 'react';
import Select, { components } from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import { getProfilesByType, isProfileReadOnly } from '../../support/items/profiles';

export const ClearIndicator = props => {
  return (
    <Tooltip title="Clear all">
      <div>
        <components.ClearIndicator {...props} />
      </div>
    </Tooltip>
  );
};
export const Multiselect = ({ selected, setSelected, setProfiles, project }) => {
  const customStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? null : isSelected ? 'grey' : isFocused ? 'grey' : null,
        color: isDisabled ? 'lightgrey' : isSelected ? 'white' : isFocused ? 'white' : 'black',
        cursor: 'default',
        '&:active': {
          backgroundColor: 'black',
          color: 'white',
        },
      };
    },
    control: provided => ({
      ...provided,
      '&:hover': {
        border: 'none',
      },
      border: 'none',
      boxShadow: 'none',
      borderRadius: 'none',
    }),
    multiValue: provided => ({ ...provided, margin: '5px' }),
    multiValueRemove: (provided, { data }) => {
      return isProfileReadOnly(data.value) ? { ...provided, display: 'none' } : provided;
    },
    placeholder: () => ({
      fontSize: '12px',
      fontWeight: '100',
      marginRight: '10px',
      paddingLeft: '10px',
      color: 'grey',
    }),
    singleValue: style => ({
      ...style,
      backgroundColor: 'grey',
    }),
    indicatorSeparator: () => {},
  };
  const profiles = getProfilesByType(project.type, { excludeReadOnly: true });
  const onChange = (selectedOptions = [], { action } = {}) => {
    if (action === 'clear') {
      selectedOptions.push(...selected.filter(profile => isProfileReadOnly(profile.value)));
    }
    setSelected(selectedOptions);
    setProfiles(selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(profile => profile.value) : []);
  };
  return (
    <Select
      isMulti
      isClearable={true}
      captureMenuScroll={false}
      placeholder={'Profiles'}
      components={{ ClearIndicator }}
      defaultValue={selected}
      styles={customStyles}
      value={selected}
      onChange={onChange}
      options={profiles}
      isSearchable={true}
    />
  );
};
