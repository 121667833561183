import React, { Component } from 'react';

import { Input } from 'reactstrap';

class Checkbox extends Component {
  // eslint-disable-next-line no-undef
  state = {
    value: this.props.value,
    checked: this.props.checked,
  };

  render() {
    const { checked, disabled, id, name, title, value, extraStyles = {} } = this.props;

    const _id = id || name;
    const _name = name || _id;

    const onChange = e => {
      const checked = e.target.checked;
      this.setState({ checked });
      this.props.onChange && this.props.onChange(id, checked);
    };

    return (
      <div className="mx-0 row">
        <span className="ml-3 mr-0">
          <Input
            id={_id}
            name={_name}
            className="p-0"
            type="checkbox"
            style={{ ...{ height: '14px', width: '14px' }, ...extraStyles }}
            onChange={onChange}
            checked={checked}
            value={value}
            disabled={disabled}
          />
          <p style={{ marginTop: '2px', fontWeight: 300 }} className="mx-0 mb-0 p-0">
            {title}
          </p>
        </span>
      </div>
    );
  }
}

export default Checkbox;
