import * as React from 'react';
import { styled } from 'react-free-style';
import classnames from 'classnames';

import { colors, Input, Dropdown } from '@united-talent-agency/julius-frontend-components';

class NameInput extends React.Component {
  // eslint-disable-next-line no-undef
  state = {
    ignoreBlur: false,
    focused: false,
  };

  onFocus(focused = true) {
    this.setState({ focused: true });
    if (this.props.onFocusChanged) {
      this.props.onFocusChanged(focused);
    }
  }

  render() {
    const {
      focus,
      inputRef,
      notRequired,
      onCancel,
      onChange,
      onSelect,
      results,
      styles,
      className,
      title,
      value,
      disabled,
      onBlurClear,
    } = this.props;

    const { focused } = this.state;

    const nameItems = this.buildNameItems(results, onSelect, styles);

    const nameInput = (
      <div className={classnames(styles.container, className)}>
        <Input
          title={title ? title : 'Name'}
          autocomplete="off"
          id="person-input-name"
          inputRef={el => {
            this.inputRef = el;
            if (inputRef) {
              inputRef(el);
            }
          }}
          inputClassName={!notRequired && !value && !disabled ? styles.inputInvalid : undefined}
          value={value}
          onChange={onChange}
          focus={focus}
          onFocus={() => this.onFocus()}
          onKeyDown={e => this.onKeyDown(e)}
          onBlurClear={onBlurClear !== undefined ? onBlurClear : true}
          onBlur={() => {
            if (!this.state.ignoreBlur && onCancel) onCancel();
            this.onFocus(false);
            this.setState({ ignoreBlur: false, focused: false });
          }}
          disabled={disabled}
        />
        {focused && nameItems.length > 0 && value.length > 0 && (
          <Dropdown className={styles.inlineMenu} items={nameItems} />
        )}
      </div>
    );

    return nameInput;
  }

  /**
   *
   *
   * @param {*} results must be an array of objects with names and their respective array of ids
   * @param {*} onSelect
   * @param {*} styles
   */
  buildNameItems(results, onSelect, styles) {
    if (!Array.isArray(results)) {
      return [];
    }

    const items = results.map(item => {
      return {
        name: item.name.toUpperCase(),
        key: item.name.toUpperCase(),
        onClick: () => {
          onSelect(item);
        },
        content: this.buildNameListItem(item.name, styles),
        ids: item.peopleIds,
      };
    });

    return items;
  }

  buildNameListItem(name, styles, alreadySelected = false) {
    return (
      <div className={styles.listItem}>
        <span className={alreadySelected ? styles.alreadySelectedText : styles.selectText}>{name}</span>
      </div>
    );
  }

  onKeyDown(event) {
    const { onCancel } = this.props;
    const { keyCode } = event;

    if (keyCode === 27) {
      onCancel && onCancel();
    }
  }
}

const withStyles = styled({
  container: {
    position: 'relative',
  },
  floatingMenu: {
    position: 'absolute',
    marginTop: -1,
  },
  inlineMenu: {
    maxHeight: '200px',
    overflowY: 'scroll',
    display: 'block',
  },
  inputInvalid: {
    '&&': {
      borderColor: colors.invalidBorder,
    },
  },
  selectText: {
    fontSize: 13,
    fontWeight: 300,
  },
  alreadySelectedText: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1,
    paddingTop: '4px',
  },
  listItem: {
    display: 'flex',
    paddingLeft: '10px',
    marginBottom: 1,
    paddingBottom: 1,
  },
});

export default withStyles(NameInput);
