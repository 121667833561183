import React from 'react';
import { Col, Row } from 'reactstrap';
import { Modal, Button, Box, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    padding: '2%',
    bgcolor: '#dedede',
    boxShadow: 24,
    p: 4,
};

const NewModal = ({ label, name, records, isModalOpen, onModalClose, onModalBtnPress }) => {

    const [selectedValue, setSelectedValue] = React.useState();

    return(
        <Modal
            open={isModalOpen}
            onClose={onModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Row>
                    <Col>
                        <h4>
                            <strong>
                                Are you sure you want to create a new {label}?
                            </strong>
                        </h4>    
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            You entered
                            <span className='ml-1'><strong>{name}.</strong></span>
                            { records?.length > 0 && <span className='ml-1'>Did you mean?</span> }
                        </p>    
                    </Col>
                </Row>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                >   
                    { records?.filter((v, i, a) => a.findIndex(v2 => (v2.label === v.label)) === i).slice(0, 5)?.map(record => <FormControlLabel key={record.label} value={record.label} control={<Radio />} label={record.label} onChange={e => setSelectedValue(e.target.value)} />) }
                </RadioGroup>
                <Row>
                    <Col className='d-flex justify-content-around'>
                        <Button
                            variant="contained"
                            onClick={() => { 
                                onModalBtnPress(name);
                            }}>
                                Create New
                        </Button>
                        { records?.length > 0 && <Button
                            variant="contained"
                            style={{ backgroundColor: 'gray', color: 'white' }}
                            disabled={!selectedValue}
                            onClick={() => { 
                                onModalBtnPress(selectedValue);
                                setSelectedValue();
                            }}>
                                Select
                        </Button>
                        }
                    </Col>
                </Row>

            </Box>
        </Modal>
    );
};

export default NewModal;
