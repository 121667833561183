import 'react-dates/initialize';
// Import CSS before React components.
import 'bootstrap/dist/css/bootstrap.css';
import 'normalize.css/normalize.css';
import 'react-day-picker/lib/style.css';
import 'react-dates/lib/css/_datepicker.css';
import 'font-awesome/css/font-awesome.min.css';
import 'fixed-data-table-2/dist/fixed-data-table.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-custom-scroll/dist/customScroll.css';
import 'react-simple-dropdown/styles/Dropdown.css';
import './styles/scroll.css';
import './styles/select.css';
import './styles/checkbox.css';
import './styles/calendar.css';
import './styles/data-grid.css';
import './styles/ag-grid.css';
import './containers/projects/project-view/views/auspices/styles.css';
import 'react-dates/lib/css/_datepicker.css';
import 'instantsearch.css/themes/satellite-min.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import Routes from './routes';

import { initializeDataDog } from './support/dataDog';
import { createStoreWithOptions } from '@united-talent-agency/julius-frontend-store';
import { OnyxThemeProvider } from '@united-talent-agency/components';
import { apiServerUrl } from './support/urls';

const store = createStoreWithOptions({ apiServerUrl });

/**
 * Data Dog RUM tracking initialization
 *
 * active host is interrogated against the allowable env flags using this map:
 *    "prod": phonesheet.unitedtalent.com
 *    "staging": phonesheet.staging.unitedtalent.com
 *    "dev": phonesheet.dev.unitedtalent.com
 *    "local": localhost
 */
initializeDataDog(window.location.hostname, ['local', 'staging', 'prod']);

function mount(App) {
  return render(
    <Provider store={store}>
      <OnyxThemeProvider>
        <App />
      </OnyxThemeProvider>
    </Provider>,
    document.getElementById('app')
  );
}

mount(Routes);
