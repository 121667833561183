import { helpers } from 'react-free-style';
import archiveUrl from '../assets/images/archive.svg';
import pencilUrl from '../assets/images/pencil.svg';
import crossBlackUrl from '../assets/images/cross-black.svg';
import crossRedUrl from '../assets/images/cross-red.svg';
import plusUrl from '../assets/images/plus.svg';
import groupByIconUrl from '../assets/images/groupby-icon.svg';

const icon = {
  display: 'inline-block',
  backgroundSize: 'cover',
};

export const archive = helpers.merge(
  {
    width: 12,
    height: 12,
    backgroundImage: helpers.url(archiveUrl),
  },
  icon
);

export const pencil = helpers.merge(
  {
    width: 12,
    height: 12,
    backgroundImage: helpers.url(pencilUrl),
  },
  icon
);

export const smallCrossBlack = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(crossBlackUrl),
  },
  icon
);

export const smallCrossRed = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(crossRedUrl),
  },
  icon
);

export const plus = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(plusUrl),
  },
  icon
);

export const groupByIcon = helpers.merge(
  {
    width: 14,
    height: 14,
    backgroundImage: helpers.url(groupByIconUrl),
  },
  icon
);

export const icons = {
  archive,
  pencil,
  smallCrossBlack,
  smallCrossRed,
  plus,
  groupByIcon,
};
