import { patch } from './index.js';

export const archiveProjects = async (payload, _patch = patch) => {
  try {
    const response = await _patch(`/v2/project/batch/update`, payload);
    if (!response.ok) {
      console.error('Handled API Error from /projects/batch/update', response);
      return Promise.reject(response);
    }
    return response;
  } catch (error) {
    console.error('Unhandled Error:', error);
    throw error;
  }
};
