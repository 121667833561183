// eslint-disable
import React from 'react';

const CollapseIcon = props => {
  const { iconDirection } = props;

  return (
    <span>
      <button
        style={{ borderWidth: 0, background: 'transparent' }}
        data-tip="custom show"
        data-event="mouseenter"
        data-delay-show="2500"
      >
        {iconDirection}
      </button>
    </span>
  );
};

export default CollapseIcon;
