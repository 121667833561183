import { requestSansStore } from '@united-talent-agency/julius-frontend-store';

// Could have added more APIs to JFES
//  Reasons to put these here:
//  - Move away from JFES as much as possible
//  - Easier editing
//  - Less conflicts with names that might be used for other onyx things
//  - Can write better app-wide error handling around these
//  Reasons against:
//  - If the other apps want to use these, they'll have to add their own functions to JFES
//  - If an API changes, we might make a change here and forget to change the JFES version

// Subset of the fetch Response, it automatically calls .json() and puts it in the body
// interface RequestSansStoreResponse {
//   body: never;
//   url: string;
//   status: number;
//   headers: Headers;
//   ok: boolean;
// }

export const get = async (path, query) => {
  return requestSansStore(path, 'GET', null, query);
};

export const post = async (path, payload, query) => {
  return requestSansStore(path, 'POST', payload, query);
};

export const put = async (path, payload, query) => {
  return requestSansStore(path, 'PUT', payload, query);
};

export const patch = (path, payload) => {
  return requestSansStore(path, 'PATCH', payload);
};

export const remove = (path, payload) => {
  return requestSansStore(path, 'DELETE', payload);
};
