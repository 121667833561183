import React from 'react';
import 'react-dates/initialize';
import { DateRangePicker as ReactDatesDateRangePicker } from 'react-dates';

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.startDate,
      endDate: props.startDate,
    };
  }

  render() {
    const { endDate, endDateId, onDatesChange, startDate, startDateId } = this.props;

    return (
      <ReactDatesDateRangePicker
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        onDatesChange={({ startDate, endDate }) => {
          this.setState({ startDate, endDate });
          onDatesChange && onDatesChange(startDate, endDate);
        }}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        isOutsideRange={() => false}
      />
    );
  }
}

export default DateRangePicker;
