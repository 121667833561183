import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { createProjectNote, loadIndieNotes, removeProjectNote, updateProjectNote } from '@united-talent-agency/julius-frontend-store';
import { colors } from '@united-talent-agency/julius-frontend-components';

import NoteButtons from '../note-buttons';
import NoteTableActionHeader from '../note-table-action-header';
import SortableColumnHeader from '../../../../../../components/sortable-column-header/sortable-column-header';
import { personProfileLink } from '../../../../../../support/person';
import AddIndieNoteForm from './addNoteForm';

const HEADERS = ['Person', 'Date', 'Type', 'Note', 'Actions'];
const SORTABLE_HEADERS = ['Person', 'Date', 'Type'];

const IndieNotesHeaders = ({ classes, sortState, setSortState }) => {
  const { field, direction } = sortState;
  return (
    <thead>
      <tr className={classes.tr}>
        {HEADERS.map((header, index) => {
          const displayedDirection = field === header ? (direction === 'ASC' ? 1 : -1) : 0;
          return (
            <th key={index} className={classes.thText}>
              {SORTABLE_HEADERS.includes(header) ? (
                <SortableColumnHeader
                  text={header}
                  direction={displayedDirection}
                  onClick={() => {
                    let newSortState = { field: header, direction: 'ASC' };
                    if (field === header) {
                      const newDirection = direction === 'ASC' ? 'DSC' : 'ASC';
                      newSortState = { ...newSortState, ...{ direction: newDirection } };
                    }
                    setSortState(newSortState);
                  }}
                />
              ) : (
                header
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const IndieNotes = ({ dispatch, desk, project, classes, user }) => {
  const [showArchived, setShowArchived] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [indieNotes, setIndieNotes] = useState([]);
  const [defaultNoteFields, setDefaultNoteFields] = useState({});
  const [sortState, setSortState] = useState({ field: 'Date', direction: 'ASC' });

  useEffect(() => {
    const retrieveIndieNotes = async () => {
      const indieNotes = await dispatch(loadIndieNotes(project._id));
      setIndieNotes(indieNotes.body);
    };

    retrieveIndieNotes();
  }, [dispatch, project]);

  const addNewNote = async indieProjectNote => {
    await dispatch(createProjectNote(indieProjectNote));
    setIsAdding(false);
    const indieNotes = await dispatch(loadIndieNotes(project._id));
    setIndieNotes(indieNotes.body);
  };

  const archiveNote = indieProjectNote => {
    indieProjectNote.archived = !indieProjectNote.archived;
    updateNote(indieProjectNote, indieProjectNote._id);
  };

  const updateNote = async (indieProjectNote, indieNoteId) => {
    await dispatch(updateProjectNote(indieNoteId, indieProjectNote));
    setIsUpdating(false);
    setDefaultNoteFields({});
    const indieNotes = await dispatch(loadIndieNotes(project._id));
    setIndieNotes(indieNotes.body);
  };

  const onReset = () => {
    isAdding && setIsAdding(false);
    isUpdating && setIsUpdating(false);
    setDefaultNoteFields({});
  };

  const getSorter = () => {
    const { field, direction } = sortState;
    const descending = direction !== 'ASC';
    let sort = 0;
    switch (field) {
      case 'Date':
        return (a, b) => {
          const aCreated = a.created_date ? 1 : 0;
          const bCreated = b.created_date ? 1 : 0;
          if (aCreated === bCreated) {
            sort = Date.parse(a.created_date) - Date.parse(b.created_date);
          } else {
            sort = bCreated - aCreated;
          }

          if (descending) {
            sort *= -1;
          }
          return sort;
        };
      case 'Type':
        return (a, b) => {
          const statusA = a.status;
          const statusB = b.status;
          if (statusA > statusB) {
            sort = 1;
          }
          if (statusB > statusA) {
            sort = -1;
          }
          if (descending) {
            sort *= -1;
          }
          return sort;
        };
      case 'Person':
        return (a, b) => {
          const personA = (a.personId && a.personId.name) || '';
          const personB = (b.personId && b.personId.name) || '';
          if (personA > personB) {
            sort = 1;
          }
          if (personB > personA) {
            sort = -1;
          }
          if (descending) {
            sort *= -1;
          }
          return sort;
        };
      default:
        throw Error('UNEXPECTED INDIE NOTE SORT STATE');
    }
  };

  const visibleNotes = showArchived ? indieNotes : indieNotes.filter(note => !note.archived);

  return (
    <React.Fragment>
      <NoteTableActionHeader
        isModalOpen={isAdding}
        toggleAdd={() => setIsAdding(!isAdding)}
        showArchived={showArchived}
        onShowArchiveChange={() => setShowArchived(!showArchived)}
      />
      {isAdding && (
        <AddIndieNoteForm
          dispatch={dispatch}
          desk={desk}
          onReset={onReset}
          projectId={project._id}
          onSubmit={addNewNote}
          user={user}
        />
      )}
      {isUpdating && !isAdding && (
        <AddIndieNoteForm
          dispatch={dispatch}
          desk={desk}
          onReset={onReset}
          projectId={project._id}
          onSubmit={updateNote}
          defaultNoteFields={defaultNoteFields}
          user={user}
        />
      )}
      <table id="indieNoteTable" className={classes.table}>
        <IndieNotesHeaders classes={classes} sortState={sortState} setSortState={setSortState} />
        {visibleNotes && visibleNotes.length > 0 ? (
          <tbody>
            {visibleNotes.sort(getSorter()).map((indieNote, index) => {
              const authorName = (indieNote.personId && indieNote.personId.name) || '';

              return (
                <tr key={index} className={classes.td}>
                  <td className={classes.personName}>
                    <a
                      className={classes.profileLink}
                      href={personProfileLink(indieNote.personId)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {authorName}
                    </a>
                  </td>
                  <td className={classes.column}>{moment(indieNote.created_date).format('YYYY-MM-DD') || ''}</td>
                  <td className={classes.column}>{indieNote.status}</td>
                  <td>
                    <span className={classes.note}>
                      {indieNote.note.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                      ))}
                    </span>
                  </td>
                  <td className={classes.column}>
                    <NoteButtons
                      note={indieNote}
                      onArchiveNote={archiveNote}
                      onEditItem={() => {
                        setDefaultNoteFields({
                          status: indieNote.status,
                          author: indieNote.personId,
                          note: indieNote.note,
                          noteId: indieNote._id,
                        });
                        setIsUpdating(true);
                      }}
                      onDeleteItem={async () => {
                        await dispatch(removeProjectNote(indieNote._id));
                        const indieNotes = await dispatch(loadIndieNotes(project._id));
                        setIndieNotes(indieNotes.body);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody />
        )}
      </table>
    </React.Fragment>
  );
};

const styles = {
  table: {
    width: '100%',
  },
  tr: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  td: {
    '&:hover': {
      borderBottom: `1px solid ${colors.border}`,
      backgroundColor: colors.focusBackground,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
    fontWeight: 100,
    fontSize: '11px',
  },
  personName: {
    width: '125px',
    padding: '10px',
  },
  column: {
    width: '100px',
    padding: '10px',
  },
  note: {
    flex: 1,
  },
  profileLink: {
    padding: '10px',
    fontSize: '11px',
    fontWeight: 400,
    color: colors.text,
    cursor: 'pointer',
  },
};

export default withStyles(styles)(IndieNotes);
