export default [
  'Open',
  'POC',
  'Asian',
  'Black',
  'Hispanic / Latino',
  'Middle Eastern',
  'Native American',
  'Southeast Asian',
  'White',
];
