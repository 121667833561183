import React, { Component } from 'react';
import { helpers, styled } from 'react-free-style';
import { Link } from 'react-router-dom';
import Checkbox from '../../../components/checkbox/checkbox';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { colors, elements } from '@united-talent-agency/julius-frontend-components';

export class Grids extends Component {
  // eslint-disable-next-line no-undef
  state = {
    isPrinting: [],
    firstLoad: true,
    selectedLocation: false,
    selectedDepartment: false,
    selectedTitle: false,
    showArchivedProjectGrids: false,
    showArchivedCastingGrids: false,
  };
  render() {
    const { styles = {}, projectGrids, castingGrids } = this.props;
    const { showArchivedProjectGrids, showArchivedCastingGrids } = this.state;

    return (
      <div className={styles.container}>
        <Helmet>
          <title>GRIDS</title>
        </Helmet>
        <div className={styles.body}>
          <div className={styles.content}>
            <div>
              <h2>Project Grids</h2>
              <div className={styles.showArchivedCheckbox}>
                <Checkbox
                  title="Show Archived"
                  checked={showArchivedProjectGrids}
                  onChange={() => this.setState({ showArchivedProjectGrids: !showArchivedProjectGrids })}
                />
              </div>
              <ul className={styles.gridList}>
                {projectGrids &&
                  projectGrids.length > 0 &&
                  projectGrids.map((grid, index) => {
                    const { isStrikeout, gridIcon } = grid.archived
                      ? { isStrikeout: styles.strikeout, gridIcon: 'fa-archive' }
                      : { isStrikeout: '', gridIcon: 'fa-print' };

                    return !showArchivedProjectGrids && grid.archived ? (
                      <div className="hiddenGrid" key={index} />
                    ) : (
                      <li className={classnames(styles.gridItem, isStrikeout)} key={index}>
                        <Link
                          to={{
                            pathname: `/grid/project_grid/${grid._id}`,
                          }}
                          target={grid._id}
                          style={{ color: 'black' }}
                        >
                          <i className={`fa fa-fw ${gridIcon}`} aria-hidden="true" />
                          {grid.name}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div>
              <h2>Casting Grids</h2>
              <div className={styles.showArchivedCheckbox}>
                <Checkbox
                  title="Show Archived"
                  checked={showArchivedCastingGrids}
                  onChange={() => this.setState({ showArchivedCastingGrids: !showArchivedCastingGrids })}
                />
              </div>
              <ul className={styles.gridList}>
                {castingGrids &&
                  castingGrids.length > 0 &&
                  castingGrids.map((grid, index) => {
                    const { isStrikeout, gridIcon } = grid.archived
                      ? { isStrikeout: styles.strikeout, gridIcon: 'fa-archive' }
                      : { isStrikeout: '', gridIcon: 'fa-print' };

                    return !showArchivedCastingGrids && grid.archived ? (
                      <div key={index} />
                    ) : (
                      <li className={classnames(styles.gridItem, isStrikeout)} key={index}>
                        <Link
                          to={{
                            pathname: `/grid/casting_grid/${grid._id}`,
                          }}
                          target={grid._id}
                          style={{ color: 'black' }}
                        >
                          <i className={`fa fa-fw ${gridIcon}`} aria-hidden="true" />
                          {grid.name}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    minWidth: 800,
  },
  header: {
    background: colors.contentBackground,
    marginBottom: 20,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
  },
  menu: helpers.merge(elements.menu, {
    marginBottom: 5,
  }),
  menuItem: elements.menuItem,
  activeItem: elements.activeMenuItem,
  gridList: {
    listStyle: 'none',
    width: '100%',
    marginLeft: '-30px',
    marginRight: '15px',
    marginTop: 10,
  },
  gridItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
  showArchivedCheckbox: {
    marginLeft: '15px',
  },
});

export default withStyles(Grids);
