import * as _ from 'lodash';

export function castingByType(casting) {
  switch (casting.type) {
    case 'Series: Lead':
      return 9;
    case 'Lead':
      return 8;
    case 'Supporting':
      return 7;
    case 'Series: Supporting':
      return 6;
    case 'Series Regular':
      return 5;
    case 'Recurring':
      return 4;
    case 'Cameo':
      return 3;
    case 'Ensemble':
      return 2;
    case 'Guest Star':
      return 1;
    default:
      return 0;
  }
}

// Priorities are a bit of a mess. They should be sorted numerically if they are numeric, otherwise last.
// This handles sort weirdness when a priority could be null or the key not set at all.
export function castingByPriority(casting) {
  return parseInt(casting.priority) || 999;
}

export function sortCastings(castings) {
  return _.orderBy(castings, [castingByType, castingByPriority, 'name'], ['desc', 'asc', 'asc']);
}
