import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AddButton } from '@united-talent-agency/julius-frontend-components';

import Checkbox from '../../../../../../components/checkbox/checkbox';

const NoteTableActionHeader = ({ isModalOpen, toggleAdd, showArchived, onShowArchiveChange, classes }) => (
  <div className={classes.tableActionHeaderContainer}>
    <AddButton label="Note" isOpen={isModalOpen} toggleAdd={toggleAdd} />
    <Checkbox checked={showArchived} onChange={onShowArchiveChange} title="Show Archived Notes" />
  </div>
);

const styles = {
  tableActionHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default withStyles(styles)(NoteTableActionHeader);
