const buildSelectItems = (field, items, currentValue, onClick, hasEmpty) => {
  const selectItems = items.map(item => {
    let key, content;
    key = content = item;

    if (item.length === 2) {
      key = item[0];
      content = item[1];
    }

    return _buildSelectItem(field, key, content, currentValue, onClick);
  });

  if (hasEmpty) {
    selectItems.unshift({
      key: '',
      content: ' ',
      onClick: () => onClick(field, ''),
    });
  }

  return selectItems;
};

const _buildSelectItem = (field, key, content, currentValue, onClick) => {
  return {
    key,
    content,
    active: currentValue === key,
    onClick: () => onClick(field, key, content),
  };
};

export default buildSelectItems;
