import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '@united-talent-agency/julius-frontend-components';
import { loadTouringNotes, removeProjectNote, updateProjectNote } from '@united-talent-agency/julius-frontend-store';

import NoteTableActionHeader from '../note-table-action-header';
import Note from '../note';
import ProjectNoteForm from '../project-note-form';

const HEADERS = ['Person', 'Date', 'Client', 'Status', 'Note', 'Actions'];

const TouringNotes = ({ project, classes }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEditing] = useState(false);
  const [focused, setFocused] = useState(0);
  const [note, setNote] = useState({});
  const [showArchived, setShowArchived] = useState(false);
  const touringNotes = useSelector(state => state.projectNotes.touringNotes);
  const dispatch = useDispatch();
  const focusedNote = (touringNotes && touringNotes[focused]) || {};

  const archiveNote = async projectNote => {
    projectNote.archived = !projectNote.archived;
    await dispatch(updateProjectNote(projectNote._id, projectNote));
    await dispatch(loadTouringNotes(project._id));
  };

  const toggleAdd = () => {
    setModalOpen(!isModalOpen);
    setIsEditing(false);
    setNote({});
  };

  useEffect(() => {
    dispatch(loadTouringNotes(project._id));
  }, [dispatch, project]);

  const visibleNotes = showArchived ? touringNotes : touringNotes.filter(note => !note.archived);

  return (
    <div>
      <NoteTableActionHeader
        isModalOpen={isModalOpen}
        toggleAdd={toggleAdd}
        showArchived={showArchived}
        onShowArchiveChange={() => setShowArchived(!showArchived)}
      />
      {isModalOpen && (
        <div className={classes.addContainer}>
          <ProjectNoteForm
            isEdit={isEdit}
            project={project}
            note={note}
            noteType="Fairs and Festivals"
            onDone={() => {
              setModalOpen(false);
              dispatch(loadTouringNotes(project._id));
            }}
          />
        </div>
      )}
      <table id="noteTable" className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            {HEADERS.map((header, index) => {
              return (
                <th key={index} className={classes.thText}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        {visibleNotes && visibleNotes.length > 0 ? (
          <tbody>
            {visibleNotes
              .sort((a, b) => {
                const aCreated = a.created_date ? 1 : 0;
                const bCreated = b.created_date ? 1 : 0;
                if (aCreated === bCreated) {
                  return Date.parse(b.created_date) - Date.parse(a.created_date);
                } else {
                  return bCreated - aCreated;
                }
              })
              .map((note, index) => {
                const isFocused = focusedNote._id === note._id;
                return (
                  <Note
                    dispatch={dispatch}
                    note={note}
                    isEdit={isEdit}
                    index={index}
                    onArchiveNote={archiveNote}
                    key={index}
                    onEditItem={async projectNote => {
                      setNote(projectNote);
                      setModalOpen(false); // force load person if add already open
                      setModalOpen(true);
                      setIsEditing(true);
                    }}
                    onDeleteItem={projectNote => {
                      dispatch(removeProjectNote(projectNote._id));
                      dispatch(loadTouringNotes(project._id));
                    }}
                    isFocused={isFocused}
                    setFocused={index => setFocused(index)}
                  />
                );
              })}
          </tbody>
        ) : (
          <tbody />
        )}
      </table>
    </div>
  );
};

const styles = {
  addContainer: {
    width: '100%',
    maxWidth: '1000px',
    padding: '25px 10px 0 10px',
    margin: '0 auto',
    background: 'white',
    border: '1px black solid',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
};

export default withStyles(styles)(TouringNotes);
