import React, { Component } from 'react';
import moment from 'moment';

import { styled } from 'react-free-style';

import NamesFilter from '../names-filter/names-filter';
import CollapseIcon from '../collapse-icon/collapse-icon';
import CompanyFilter from '../company-filter/company-filter';
import DateRangePicker from '../date-range-picker/date-range-picker';
import ProfileFilter from '../profile-filter/profile-filter';
import DropdownFilter from '../dropdown-filter/dropdown-filter';
import FreeTextFilter from '../free-text-filter/free-text-filter';
import buildSelectItems from '../../support/build-select-items';
import { Select } from '@united-talent-agency/julius-frontend-components';
import InfiniteScrollDropdown from '../infiniate-scroll-select/infinite-scroll-select';
import {
  projectSearchFilterStates,
  projectSearchFilterSalesFinancing,
} from '@united-talent-agency/julius-frontend-store';
import venueTypes from '../../support/items/venue-types';
import classifications from '../../support/items/classifications.js';
import capacities from '../../support/items/capacities.js';
import distributions from '../../support/items/distributions';
import { tvStatuses } from '../../support/items/tv-statuses';
import { hasAccess } from '../../support/permissions';
import { statuses as repAgreementStatuses } from '../../support/items/rep-agreements';
import indieStatuses from '../../support/items/indie-statuses';
import cypressTags from '../../support/cypressTags.js';

const { REACT_APP_PROJ_42_FEATURE } = process.env;

class ProjectFilterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deskId: props.deskId,
      showProfileTags: true,
      showStatusTags: true,
      showStudioTags: true,
      showNetworkTags: true,
      showProductionCompanyTags: true,
      showNameTags: true,
      showVenueTags: true,
      showPromoterTags: true,
      showParentCompanyTags: true,
      showTelevisionStatuses: true,
      showIndieStatus: true,
      locationStates: [],
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    this.setState({
      filterModel: this.props.filterModel,
    });
    return dispatch(projectSearchFilterStates())
      .then(results => {
        const locationStates = results && results.body && results.body.states;
        return this.setState({ locationStates: locationStates ? locationStates : [] });
      })
      .catch(err => {
        console.error(err);
      });
  }

  onFocusChanged(focused, type) {
    switch (type) {
      case 'Profile':
        this.setState({ showProfileTags: !focused });
        break;
      case 'Status':
        this.setState({ showStatusTags: !focused });
        break;
      case 'Studio':
        this.setState({ showStudioTags: !focused });
        break;
      case 'Network':
        this.setState({ showNetworkTags: !focused });
        break;
      case 'Production Company':
        this.setState({ showProductionCompanyTags: !focused });
        break;
      case 'Person':
        this.setState({ showNameTags: !focused });
        break;
      case 'Television Status':
        this.setState({ showTelevisionStatuses: !focused });
        break;
      case 'Rep Agreement Status':
        this.setState({ showRepAgreementStatuses: !focused });
        break;
      case 'Sales and Financing Co.':
        this.setState({ showSalesFinancing: !focused });
        break;
      case 'Indie Status':
        this.setState({ showIndieStatus: !focused });
        break;
      default:
        break;
    }
  }

  render() {
    const { collapseEvent, filterModel, searchNames, filterBadges, styles, dispatch } = this.props;

    const {
      showProfileTags,
      showStatusTags,
      showNetworkTags,
      showProductionCompanyTags,
      showStudioTags,
      showNameTags,
      showVenueTags,
      showPromoterTags,
      showParentCompanyTags,
      locationStates,
      showTelevisionStatuses,
    } = this.state;
    const projectStatusOptions = [
      { _id: 'active', title: 'Active' },
      { _id: 'notActive', title: 'Archived' },
      { _id: 'hasScript', title: 'Has Script' },
      { _id: 'newInformationOnly', title: 'New Information Only' },
    ];

    const venueTypesOptions = venueTypes.map(vt => {
      return { _id: vt, title: vt };
    });
    const classificationOptions = classifications.map(classification => {
      return { _id: classification, title: classification };
    });

    const locationStateOptions = locationStates.map(ls => {
      return { _id: ls, title: ls };
    });
    const capacityOptions = capacities.map(cap => {
      return { _id: cap, title: cap };
    });
    const distributionOptions = distributions.map(dist => {
      return { _id: dist, title: dist };
    });
    const type = (filterModel.types || []).length === 1 ? filterModel.types[0] : 'Any';
    const isAnyType = type === 'Any';
    const isFeature = type === 'Feature';
    const isRealityTV = type === 'Unscripted';
    const isTv = type === 'Television' || isRealityTV;
    const isTheatre = type === 'Theatre';
    const isTouring = type === 'Fairs and Festivals';

    const nameFilterTypes = ['All', 'Assigned Projects', 'Added Notes'];

    const dateSearchItems = buildSelectItems(
      'projectSearchDateType',
      ['Start Date', 'Last Updated', 'Created'],
      filterModel.projectSearchDateType,
      this.onEvent
    );

    const projectDateStart = filterModel.projectDateStart && moment(filterModel.projectDateStart),
      projectDateEnd = filterModel.projectDateEnd && moment(filterModel.projectDateEnd);

    const statusTagTypes = ['active', 'notActive', 'hasScript', 'newInformationOnly'];

    const profileTags = filterBadges && filterBadges.filter(tag => tag.property === 'profiles'),
      studioTags = filterBadges && filterBadges.filter(tag => tag.property === 'studios'),
      networkTags = filterBadges && filterBadges.filter(tag => tag.property === 'networks'),
      promoterTags = filterBadges && filterBadges.filter(tag => tag.property === 'promoters'),
      parentCompanyTags = filterBadges && filterBadges.filter(tag => tag.property === 'parentCompanies'),
      productionCompanyTags = filterBadges && filterBadges.filter(tag => tag.property === 'productionCompanies'),
      nameTags = filterBadges && filterBadges.filter(tag => tag.property === 'associatedNames'),
      venueTags = filterBadges && filterBadges.filter(tag => tag.property === 'venueType'),
      locationStateTags = filterBadges && filterBadges.filter(tag => tag.property === 'locationState'),
      classificationTags = filterBadges && filterBadges.filter(tag => tag.property === 'classification'),
      capacityTags = filterBadges && filterBadges.filter(tag => tag.property === 'capacity'),
      statusTags = filterBadges && filterBadges.filter(tag => statusTagTypes.includes(tag.property)),
      distributionTags = filterBadges && filterBadges.filter(tag => tag.property === 'distribution'),
      tvStatusTags = filterBadges && filterBadges.filter(tag => tag.property === 'tvStatus'),
      repAgreementStatusTags = filterBadges && filterBadges.filter(tag => tag.property === 'repAgreementStatus'),
      salesFinancingTags = filterBadges && filterBadges.filter(tag => tag.property === 'salesFinancing'),
      indieStatusTags = filterBadges && filterBadges.filter(tag => tag.property === 'indieStatus');

    return (
      <div className="m-0 py-0 pr-2 pl-0 col">
        <div className="m-0 p-0 col w-100">
          <div className="ml-1 mt-4 row">
            <h5 className="mx-0 mb-2 mt-0 p-0">Project Filters</h5>
            <div
              style={{ marginTop: 0, marginRight: 25, cursor: 'pointer' }}
              className="ml-auto"
              onClick={collapseEvent}
            >
              <CollapseIcon iconDirection="<" />
            </div>
          </div>
        </div>

        <div className="m-0 p-0 col w-100">
          {isTouring && (
            <div className="m-0 p-0 col w-100">
              <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                <DropdownFilter
                  title="Event Type"
                  results={venueTypesOptions}
                  selectedResults={venueTags}
                  onSelectStatus={venue => this.onEvent('venueType', venue)}
                  onFocusChanged={focused => this.onFocusChanged(focused, 'Venue')}
                />
              </div>

              {showVenueTags &&
                venueTags &&
                venueTags.length > 0 &&
                venueTags.map((tag, i) => (
                  <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                    <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                      <i className="fa fa-check-square fa-fw" />
                    </span>
                    {tag.name}
                  </div>
                ))}
            </div>
          )}
          {isTouring && (
            <div>
              <hr style={{ marginTop: '20px' }} />
              <DateRangePicker
                startDate={projectDateStart}
                startDateId="projectDateStart"
                endDate={projectDateEnd}
                endDateId="projectDateEnd"
                onDatesChange={this.onEventDateChange}
                focusInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
              />
              <hr style={{ marginTop: '20px' }} />
            </div>
          )}
          {isTouring && (
            <div className="m-0 p-0 col w-100">
              <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                <DropdownFilter
                  title="Location State"
                  results={locationStateOptions}
                  onSelectStatus={ls => this.onEvent('locationState', ls)}
                  onFocusChanged={focused => this.onFocusChanged(focused, 'Location State')}
                />
              </div>

              {locationStateTags &&
                locationStateTags.length > 0 &&
                locationStateTags.map((tag, i) => (
                  <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                    <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                      <i className="fa fa-check-square fa-fw" />
                    </span>
                    {tag.name}
                  </div>
                ))}
            </div>
          )}
          {isTouring && (
            <div className="m-0 p-0 col w-100">
              <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                <DropdownFilter
                  title="Classificationn"
                  results={classificationOptions}
                  onSelectStatus={ls => {
                    this.onEvent('classification', ls);
                  }}
                  onFocusChanged={focused => this.onFocusChanged(focused, 'Classification')}
                />
              </div>

              {classificationTags &&
                classificationTags.length > 0 &&
                classificationTags.map((tag, i) => (
                  <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                    <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                      <i className="fa fa-check-square fa-fw" />
                    </span>
                    {tag.name}
                  </div>
                ))}
            </div>
          )}
          {isTouring && (
            <div>
              <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                <InfiniteScrollDropdown
                  label="Parent Companies"
                  onSelectCompany={company => {
                    this.onEventCollectionWithExclude('parentCompanies', company, filterModel);
                  }}
                  companies={
                    filterModel.parentCompanies && filterModel.parentCompanies.include
                      ? filterModel.parentCompanies.include
                      : []
                  }
                />
              </div>
              <div>
                {showParentCompanyTags &&
                  parentCompanyTags &&
                  parentCompanyTags.length > 0 &&
                  parentCompanyTags.map((tag, i) => (
                    <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                      <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                        <i className="fa fa-check-square fa-fw" />
                      </span>
                      {tag.name}
                    </div>
                  ))}
              </div>
              <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                <InfiniteScrollDropdown
                  label="Promoter"
                  onSelectCompany={company => {
                    this.onEventCollectionWithExclude('promoters', company, filterModel);
                  }}
                  companies={
                    filterModel.promoterCompanies && filterModel.promoters.include ? filterModel.promoters.include : []
                  }
                />
              </div>
              <div>
                {showPromoterTags &&
                  promoterTags &&
                  promoterTags.length > 0 &&
                  promoterTags.map((tag, i) => (
                    <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                      <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                        <i className="fa fa-check-square fa-fw" />
                      </span>
                      {tag.name}
                    </div>
                  ))}
              </div>
              <div className="m-0 p-0 col w-100">
                <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                  <DropdownFilter
                    title="Capacity"
                    results={capacityOptions}
                    onSelectStatus={cap => this.onEvent('capacity', cap)}
                    onFocusChanged={focused => this.onFocusChanged(focused, 'Capacity')}
                  />
                </div>

                {capacityTags &&
                  capacityTags.length > 0 &&
                  capacityTags.map((tag, i) => (
                    <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                      <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                        <i className="fa fa-check-square fa-fw" />
                      </span>
                      {tag.name}
                    </div>
                  ))}
              </div>

              <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                <FreeTextFilter
                  title="Topline Budget"
                  onChange={budget => {
                    this.onEvent('budget', budget);
                  }}
                  onFocusChanged={focused => this.onFocusChanged(focused, 'Budget')}
                />
              </div>
            </div>
          )}
          {!isTouring && (
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <ProfileFilter
                label="Profiles (genre, status, etc)"
                onSelectProfile={profile => this.onEventCollectionWithExclude('profiles', profile, filterModel)}
                filterModel={filterModel}
                onFocusChanged={focused => this.onFocusChanged(focused, 'Profile')}
              />
            </div>
          )}
          {!isTouring &&
            showProfileTags &&
            profileTags &&
            profileTags.length > 0 &&
            profileTags.map((tag, i) => (
              <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                  <i className="fa fa-check-square fa-fw" />
                </span>
                {tag.name}
              </div>
            ))}
          {(isFeature || isRealityTV) && hasAccess('projectSearch', 'indieStatus') && (
            <div className="m-0 p-0 col w-100">
              <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                <DropdownFilter
                  title="Indie Status"
                  results={indieStatuses.map(status => {
                    return { _id: status, title: status };
                  })}
                  onSelectStatus={status => this.onEventArrayWithExclude('indieStatus', status, filterModel)}
                  onFocusChanged={focused => this.onFocusChanged(focused, 'Indie Status')}
                />
              </div>
              {(indieStatusTags || []).map((tag, i) => (
                <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                  <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                    <i className="fa fa-check-square fa-fw" />
                  </span>
                  {tag.name}
                </div>
              ))}
            </div>
          )}
          {isTv && (
            <DropdownFilter
              title="Television Status"
              results={tvStatuses.map(tvStatus => ({ _id: tvStatus, title: tvStatus }))}
              selectedResults={tvStatusTags}
              onSelectStatus={tvStatus => this.onEventArrayWithExclude('tvStatus', tvStatus, filterModel)}
              onFocusChanged={focused => this.onFocusChanged(focused, 'Television Status')}
            />
          )}
          {isTv &&
            showTelevisionStatuses &&
            tvStatusTags &&
            tvStatusTags.length > 0 &&
            tvStatusTags.map(tag => (
              <div key={tag.name} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                  <i className="fa fa-check-square fa-fw" />
                </span>
                {tag.name}
              </div>
            ))}
          <hr style={{ marginTop: '20px' }} />
        </div>
        {(isFeature || isRealityTV) && hasAccess('projectSearch', 'salesFinancing') && (
          <div className="m-0 p-0 col w-100">
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <CompanyFilter
                label="Sales and Financing Co."
                onSelectCompany={company => this.onEventCollectionWithExclude('salesFinancing', company, filterModel)}
                searchCompany={value => {
                  return dispatch(projectSearchFilterSalesFinancing(value)).then(results => {
                    return ((results.body || {}).companies || []).map(company => {
                      return { _id: company, name: company };
                    });
                  });
                }}
                companies={
                  filterModel.salesFinancing && filterModel.salesFinancing.include
                    ? filterModel.salesFinancing.include
                    : []
                }
                onFocusChanged={focused => this.onFocusChanged(focused, 'Sales and Financing Co.')}
              />
            </div>
            {(salesFinancingTags || []).map((tag, i) => (
              <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                  <i className="fa fa-check-square fa-fw" />
                </span>
                {tag.name}
              </div>
            ))}
          </div>
        )}
        {(isTv || isAnyType) && (
          <div>
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <InfiniteScrollDropdown
                label="Network"
                onSelectCompany={company => {
                  this.onEventCollectionWithExclude('networks', company, filterModel);
                }}
                companies={filterModel.networks && filterModel.networks.include ? filterModel.networks.include : []}
              />
            </div>
            {showNetworkTags &&
              networkTags &&
              networkTags.length > 0 &&
              networkTags.map((tag, index) => (
                <div key={index} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                  <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                    <i className="fa fa-check-square fa-fw" />
                  </span>
                  {tag.name}
                </div>
              ))}
          </div>
        )}

        {(isFeature || isTv || isAnyType) && (
          <div>
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <InfiniteScrollDropdown
                label="Studio"
                onSelectCompany={company => {
                  this.onEventCollectionWithExclude('studios', company, filterModel);
                }}
                companies={filterModel.studios && filterModel.studios.include ? filterModel.studios.include : []}
              />
            </div>
            {showStudioTags &&
              studioTags &&
              studioTags.length > 0 &&
              studioTags.map((tag, i) => (
                <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                  <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                    <i className="fa fa-check-square fa-fw" />
                  </span>
                  {tag.name}
                </div>
              ))}
          </div>
        )}

        {(isFeature || isTv || isTheatre || isAnyType) && (
          <div>
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <InfiniteScrollDropdown
                label="Production Co."
                onSelectCompany={company => {
                  this.onEventCollectionWithExclude('productionCompanies', company, filterModel);
                }}
                companies={
                  filterModel.productionCompanies && filterModel.productionCompanies.include
                    ? filterModel.productionCompanies.include
                    : []
                }
              />
            </div>
            {showProductionCompanyTags &&
              productionCompanyTags &&
              productionCompanyTags?.length > 0 &&
              productionCompanyTags?.map((tag, i) => (
                <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                  <span onClick={() => tag?.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                    <i className="fa fa-check-square fa-fw" />
                  </span>
                  {tag?.name}
                </div>
              ))}
            <hr style={{ marginTop: '20px' }} />
          </div>
        )}

        {(isTv || isAnyType) && (
          <div className="m-0 p-0 col w-100">
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <DropdownFilter
                title="Distribution"
                results={distributionOptions}
                onSelectStatus={dist => this.onEventArrayWithExclude('distribution', dist, filterModel)}
                onFocusChanged={focused => this.onFocusChanged(focused, 'Distribution')}
              />
            </div>

            {distributionTags &&
              distributionTags.length > 0 &&
              distributionTags.map((tag, i) => (
                <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                  <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                    <i className="fa fa-check-square fa-fw" />
                  </span>
                  {tag.name}
                </div>
              ))}
            <hr style={{ marginTop: '20px' }} />
          </div>
        )}

        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
          <NamesFilter
            label="Person (Agents, Execs, Staff, Talent)"
            onSelect={async person => {
              const ops = ['All', 'Assigned Projects'];
              const projectRoles = person.projectRoles.map(role => role.k);
              if (projectRoles.includes('agent')) {
                ops.push('Added Notes');
              }
              this.onEventCollectionWithExclude(
                'associatedNames',
                { ...person, options: ops, custom: ops.length > 1 ? 'All' : ops[0] },
                filterModel
              );
            }}
            searchNames={searchNames}
            names={
              filterModel.associatedNames && filterModel.associatedNames.include
                ? filterModel.associatedNames.include
                : []
            }
            onFocusChanged={focused => {
              this.onFocusChanged(focused, 'Name');
            }}
            onBlurClear={false}
          />
          {showNameTags &&
            nameTags &&
            nameTags.length > 0 &&
            nameTags.map((tag, i) => (
              <div key={i} style={{ paddingLeft: 10, paddingTop: 5, fontSize: 13, fontWeight: 300 }}>
                <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                  <i className="fa fa-check-square fa-fw" />
                </span>
                {tag.name}
                {REACT_APP_PROJ_42_FEATURE === true.toString() && tag?.options?.length > 1 && (
                  <Select
                    items={
                      tag?.options?.map(item => ({
                        key: item,
                        content: item,
                        active: item === tag.custom,
                        onClick: () => this.onChangeNameFilterType(i, tag, item),
                      })) ||
                      nameFilterTypes.map(item => ({
                        key: item,
                        content: item,
                        active: item === tag.custom,
                        onClick: () => this.onChangeNameFilterType(i, tag, item),
                      }))
                    }
                    className={styles.selectInput}
                  >
                    {tag.custom || 'All'}
                  </Select>
                )}
              </div>
            ))}
          <hr style={{ marginTop: '20px' }} />
        </div>

        <div className="m-0 p-0 col w-100">
          <div style={{ marginTop: '5px', marginBottom: '5px' }}>
            <DropdownFilter
              title="Project Status"
              results={projectStatusOptions}
              selectedResults={statusTags}
              onSelectStatus={status => this.onEvent(status, true)}
              onFocusChanged={focused => this.onFocusChanged(focused, 'Status')}
              dataCy={cypressTags.PROJECT_FILTERS.PROJECT_STATUS_DROPDOWN}
            />
          </div>
          {showStatusTags &&
            statusTags &&
            statusTags.length > 0 &&
            statusTags.map((tag, i) => (
              <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                  <i className="fa fa-check-square fa-fw" />
                </span>
                {tag.label}
              </div>
            ))}
        </div>

        {!isTouring && (
          <div className="m-0 p-0 col w-100">
            <hr style={{ marginTop: '20px' }} />
            <Select title="Date Search" items={dateSearchItems} className={styles.selectInput}>
              {filterModel.projectSearchDateType || 'Start Date'}
            </Select>

            <DateRangePicker
              startDate={projectDateStart}
              startDateId="projectStartDate"
              endDate={projectDateEnd}
              endDateId="projectEndDate"
              onDatesChange={this.onEventDateChange}
              focusInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
            />
          </div>
        )}
        {isFeature && hasAccess('projectSearch', 'repAgreements') && (
          <div className="m-0 p-0 col w-100">
            <hr style={{ marginTop: '20px' }} />
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <DropdownFilter
                title="Rep Agreement Status"
                results={repAgreementStatuses.map(status => ({ _id: status, title: status }))}
                selectedResults={repAgreementStatusTags}
                onSelectStatus={status => this.onEventArrayWithExclude('repAgreementStatus', status, filterModel)}
                onFocusChanged={focused => this.onFocusChanged(focused, 'Rep Agreement Status')}
              />
            </div>
            {repAgreementStatusTags &&
              repAgreementStatusTags.length > 0 &&
              repAgreementStatusTags.map((tag, i) => (
                <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
                  <span onClick={() => tag.onRemoveBadge()} style={{ cursor: 'pointer' }}>
                    <i className="fa fa-check-square fa-fw" />
                  </span>
                  {tag.name}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }

  // eslint-disable-next-line no-undef
  onEvent = async (field, value) => {
    const { filterModel, recordEvent } = this.props;
    filterModel[field] = value;

    recordEvent({ data: `${field}_${value}` });
    await this.props.filterProjects(filterModel);
  };

  onChangeNameFilterType = async (index, tag, value) => {
    const { filterModel, recordEvent } = this.props;
    if (filterModel['associatedNames'].include.length > index)
      filterModel['associatedNames'].include[index].custom = value;
    else filterModel['associatedNames'].exclude[index - filterModel['associatedNames'].include.length].custom = value;

    recordEvent({ data: `associatedNames.${tag.name}_filterType_${value}` });
    await this.props.filterProjects(filterModel);
  };

  onEventCollectionWithExclude = async (collection, item, filterModel) => {
    const { recordEvent } = this.props;
    if (filterModel[collection]) {
      filterModel[collection].include = filterModel[collection].include || [];
      filterModel[collection].include = [
        ...filterModel[collection].include.filter(obj => (obj._id ? obj._id !== item._id : obj.name !== item.name)),
        item,
      ];
      filterModel[collection].exclude = filterModel[collection].exclude || [];
      filterModel[collection].exclude.filter(obj => (obj._id ? obj._id !== item._id : obj.name !== item.name));
      recordEvent({ data: `${collection}.${'include'}_${item && item.name}` });
    } else {
      filterModel[collection] = { include: [item] };
    }

    await this.props.filterProjects(filterModel);
  };

  // eslint-disable-next-line no-undef
  onEventArrayWithExclude = async (collection, item, filterModel) => {
    const { recordEvent } = this.props;

    if (!filterModel[collection]) {
      filterModel[collection] = { include: [] };
    }

    filterModel[collection].include = filterModel[collection].include
      .filter(obj => {
        return obj !== item;
      })
      .concat(item);

    if (filterModel[collection].exclude) {
      filterModel[collection].exclude = filterModel[collection].exclude.filter(obj => {
        return obj !== item;
      });
    }
    recordEvent({ data: `${collection}.${'include'}_${item}` });

    await this.props.filterProjects(filterModel);
  };

  // eslint-disable-next-line no-undef
  onEventDateChange = async (startDate, endDate) => {
    const { filterModel } = this.props;
    const isDiffStartDate = filterModel.projectDateStart !== startDate;
    const isDiffEndDate = filterModel.projectDateEnd !== endDate;

    if (isDiffStartDate || isDiffEndDate) {
      filterModel.projectDateStart = startDate;
      filterModel.projectDateEnd = endDate;
      this.setState({ ...filterModel });
      isDiffStartDate && this.onEvent('projectDateStart', startDate);
      isDiffEndDate && this.onEvent('projectDateEnd', endDate);
      await this.props.filterProjects(filterModel);
    }
  };
}

const withStyles = styled({
  selectInput: {
    marginTop: 3,
    marginBottom: 5,
  },
});

export default withStyles(ProjectFilterForm);
