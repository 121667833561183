import React, { Component } from 'react';
import { helpers, styled } from 'react-free-style';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { OnClickOutside, colors, sizes } from '@united-talent-agency/julius-frontend-components';
import DeskSelector from './desk-selector';
import { Icons } from '@united-talent-agency/components';

const popoverBottom = (styles, showReleaseNotes) => (
  <Popover id="popover-positioned-bottom" positionTop="100" title="Menu">
    <ul className={styles.menuList}>
      <li className={styles.menuItem}>
        <div onClick={showReleaseNotes}>
          <i className="fa fa-fw fa-sticky-note" aria-hidden="true" />
          Release Notes
        </div>
      </li>
      <li className={styles.menuItem}>
        <div
          onClick={() => {
            localStorage.removeItem('uta.auth');
            window.location = '/login';
          }}
        >
          <i className="fa fa-fw fa-sign-out" aria-hidden="true" />
          Sign Out
        </div>
      </li>
    </ul>
  </Popover>
);

class UserMenu extends Component {
  render() {
    const { styles, showReleaseNotes } = this.props;
    return (
      <div className={styles.user}>
        <div className={styles.userContent}>
          <div className={styles.imageColumn}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              delay={0}
              ref={ref => (this.trigger = ref)}
              onBlur={() => this.trigger.hide()}
              overlay={popoverBottom(styles, showReleaseNotes)}
            >
              <OnClickOutside onClickOutside={() => this.trigger.hide()}>
                <Icons.HamburgerMenuIcon className={styles.userImage} />
              </OnClickOutside>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  }
}

export const Header = ({ styles, desks, desk, setDesk, user, showReleaseNotes }) => (
  <div className={styles.headerContainer}>
    <div className={styles.leftColumn}>
      <div className={styles.desk}>
        <DeskSelector desks={desks} desk={desk} setDesk={setDesk} styles={styles} />
      </div>
    </div>
    <div className={styles.userColumn}>
      <div className={styles.header}>
        <UserMenu styles={styles} user={user} showReleaseNotes={showReleaseNotes} />
      </div>
    </div>
  </div>
);

const withStyles = styled({
  headerContainer: {
    background: colors.contentBackground,
    marginBottom: 10,
    height: 60,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    justifyContent: 'center',
  },
  body: helpers.merge(
    {
      display: 'flex',
      flexDirection: 'column',
    },
    sizes.container
  ),
  desk: {
    display: 'flex',
    marginLeft: 5,
    marginTop: 10,
  },
  // TODO: used in child component
  deskSelect: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    minWidth: '170px',
    textOverflow: 'ellipsis',
  },
  menuList: {
    listStyle: 'none',
    width: '200%',
    marginLeft: '-30px',
    marginRight: '15px',
    marginTop: 10,
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
    padding: '5px',
  },
  user: {
    display: 'flex',
    flex: 0.2,
    alignItems: 'center',
    marginLeft: '30',
  },
  userContent: {
    marginLeft: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  userImage: {
    borderRadius: '50%',
    height: 35,
    width: 35,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  // TODO: used in a child component
  selectItem: {
    fontSize: 14,
    fontWeight: 300,
    padding: 10,
    color: 'black',
  },
  imageColumn: {
    display: 'flex',
    flex: 0.4,
    marginRight: 10,
    marginTop: 3,
    flexDirection: 'column',
  },
  leftColumn: {
    display: 'flex',
    flex: 0.25,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'flex-start',
  },
  userColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 5,
  },
});

export default withStyles(Header);
