import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-modal';

Modal.setAppElement(document.getElementById('delete-modal'));

const ArchiveOption = () => <i className="fa fa-archive" aria-hidden="true"></i>;
const UnarchiveOption = () => <i className="fa fa-undo" aria-hidden="true"></i>;

export const Actions = ({ archiveAction, isActive, deleteAction }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="auspices-table-action-menu-container" style={{ color: 'black' }}>
      <div onClick={archiveAction} className="auspices-table-action-menu-entry">
        {isActive ? <ArchiveOption /> : <UnarchiveOption />}
      </div>
      <div className="auspices-table-action-menu-entry" onClick={() => setIsModalOpen(true)}>
        <i className="fa fa-trash" aria-hidden="true" style={{ paddingLeft: 20 }}></i>
      </div>
      <Modal
        contentLabel="Delete Position"
        onRequestClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        style={{
          content: {
            top: '50%',
            left: '50%',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            fontFamily: 'aktiv-grotesk',
            border: '1px solid #141414',
          },
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>Are you sure you want to delete this auspice?</div>
          <Button className="auspices-table-modal-button" onClick={deleteAction} bsStyle="danger">
            Yes
          </Button>
          <Button className="auspices-table-modal-button" onClick={() => setIsModalOpen(false)}>
            No
          </Button>
        </div>
      </Modal>
      <div id="delete-modal" />
    </div>
  );
};
