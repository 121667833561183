/**
 * Grid/View displays the results of a search performed with a Grid. This view
 * loads the Grid, overwriting any existing filter set stored in the user/desk
 * session.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { updateProjectGrid } from '@united-talent-agency/julius-frontend-store';
import SearchProjectsView from '../../../projects/projects-view/views/search-projects';
import GridName from '../../../../components/grid-name/grid-name';
import withStyles from '@material-ui/styles/withStyles';
import { EditGrid } from '../edit-grid';
import cypressTags from '../../../../support/cypressTags';

export class ProjectGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddOpen: false,
      notes: [],
      isNotesOpen: false,
      filterBadges: {},
      deskId: null,
      filterModel: props.filterModel,
    };
    this.show = notify.createShowQueue();
    this.saveGrid = this.saveGrid.bind(this);
  }
  render() {
    const { grid, filterModel, user, desk, classes } = this.props;
    const canEdit = grid.deskId === desk._id;
    return (
      <React.Fragment>
        <Helmet>
          <title>{grid && grid.name}</title>
        </Helmet>
        <div>
          <div style={{ marginLeft: 'auto', marginRight: 0 }} data-cy={cypressTags.PROJECT_ACTIONS.GRID_NAME}>
            <GridName
              canEdit={canEdit}
              creatingDesk={grid.deskId}
              archived={grid.archived || false}
              name={grid.name}
              saveChanges={async name => {
                grid.name = name;
                await this.saveGrid(grid);
              }}
            />
          </div>
          {canEdit && <EditGrid saveGrid={this.saveGrid} classes={classes} grid={grid} show={this.show} />}
        </div>

        <SearchProjectsView
          updateGrid={async grid => await this.saveGrid(grid)}
          canEdit={canEdit}
          user={user}
          desk={desk}
          filterModel={filterModel}
          grid={grid}
        />
      </React.Fragment>
    );
  }

  // eslint-disable-next-line no-undef
  saveGrid = async grid => {
    this.setState({ isSaving: true });
    const { desk } = this.props;
    const originalGrid = this.props.grid || {};
    const currentDeskId = desk._id;
    if (originalGrid.deskId === currentDeskId) {
      await this.props.updateProjectGrid(grid._id, currentDeskId, grid);
      this.show(`${grid.name} updated`, 'custom', 1000, {
        background: '#000000',
        text: '#FFFFFF',
      });
    }
    this.setState({ isSaving: false });
    return grid;
  };
}

const styles = {
  button: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    fontSize: 14,
    lineHeight: 1,
    marginLeft: 10,
    backgroundColor: 'unset',
    padding: '5px 0',
    textTransform: 'uppercase',
  },
  icon: {
    marginRight: 10,
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 15,
  },
};

export default withRouter(connect(null, { updateProjectGrid })(withStyles(styles)(ProjectGrid)));
