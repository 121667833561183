import React, { useState, Fragment } from 'react';
import { EditButton, colors, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import RoleListItem from './role-list-item';
import { styled } from 'react-free-style';
import classnames from 'classnames';

const RoleListCard = props => {
  const {
    items,
    finder,
    title,
    onNew,
    onSave,
    onCancel,
    onChange,
    styles,
    isDirty,
    onDelete,
    projectType,
    readonly,
  } = props;
  const [searchIndex, setSearchIndex] = useState(null);
  const [searchResults, setSearchResults] = useState(null);

  const renderHeader = () => {
    return (
      <div className={classnames(styles.header, isDirty ? styles.headerEdit : undefined)}>
        <h3 className={styles.title}>{title}</h3>
        {!readonly && isDirty && onCancel && (
          <EditButton
            type={buttonTypes.cancel}
            className={styles.icon}
            onClick={() => {
              onCancel();
            }}
          />
        )}
        {!readonly && isDirty && onSave && (
          <EditButton
            type={buttonTypes.save}
            className={styles.icon}
            onClick={() => {
              onSave();
            }}
          />
        )}
      </div>
    );
  };

  const renderFooter = () => {
    if (!onNew || readonly) {
      return null;
    }

    return (
      <div className={styles.create}>
        <EditButton type={buttonTypes.new} onClick={() => onNew()} />
      </div>
    );
  };

  const renderColumnHeaders = () => {
    return (
      <div
        style={{
          fontSize: 12,
          fontWeight: 100,
          marginTop: 2,
          color: 'gray',
          width: '100%',
          display: 'flex',
          marginLeft: 20,
        }}
      >
        <span style={{ width: '30%' }}>AUSPICE</span>
        <span style={{ width: '28%' }}>STATUS</span>
        <span style={{ width: '42%' }}>PERSON</span>
      </div>
    );
  };

  return (
    <div className={styles.pane}>
      {renderHeader()}
      {renderColumnHeaders()}
      <div className={styles.body}>
        {_buildItemRows({
          items,
          searchResults,
          onChange: (text, index) => {
            finder(text).then(results => {
              setSearchResults(results);
            });
            items[index].personId = { _id: text, name: text };
            onChange && onChange(items[index], index);
          },
          onSelect: (item, index) => {
            setSearchResults([]);
            onChange && onChange(item, index);
          },
          searchIndex,
          setSearchIndex,
          onDelete,
          projectType,
          readonly,
        })}
      </div>
      {renderFooter()}
    </div>
  );
};
const _buildItemRows = ({
  items,
  searchResults,
  onChange,
  onSelect,
  searchIndex,
  setSearchIndex,
  onDelete,
  projectType,
  readonly,
}) => {
  const itemsWithNew = items ? JSON.parse(JSON.stringify(items)) : [];
  if (
    !readonly &&
    !itemsWithNew.some(i => {
      return (!i.type || i.type === '') && i.status === 'Open' && i.active === true;
    })
  ) {
    itemsWithNew.push({ active: true, status: 'Open' });
  }

  return (
    <div>
      {itemsWithNew.map((item, index) => {
        return (
          item.active &&
          _buildItemInfo({
            position: item.type,
            status: item.status,
            person: item.personId && item.personId.name,
            personType: item.personId && item.personId.type,
            index,
            searchResults: index === searchIndex && searchResults,
            onChange: (field, text, index) => {
              setSearchIndex(index);
              onChange(text, index);
            },
            onSelect: (field, value, index) => {
              item[field] = value;
              onSelect(item, index);
            },
            onDelete: !(!item.type && item.status === 'Open' && item.active === true) && onDelete,
            projectType,
            readonly,
          })
        );
      })}
    </div>
  );
};

const _buildItemInfo = ({
  position,
  status,
  person,
  personType,
  index,
  searchResults,
  onChange,
  onSelect,
  onDelete,
  projectType,
  readonly,
}) => {
  return (
    <Fragment key={index}>
      <hr style={{ marginLeft: 20, marginRight: 20, marginTop: 0, marginBottom: 0 }} />
      <RoleListItem
        key={`position-${index}`}
        projectType={projectType}
        position={position}
        status={status}
        person={person}
        personType={personType}
        short={true}
        people={searchResults}
        onPositionSelect={item => {
          onSelect('type', item.value, index);
        }}
        onStatusSelect={item => {
          onSelect('status', item.value, index);
        }}
        onPersonSelect={item => {
          onSelect('personId', item, index);
        }}
        onPersonChange={text => {
          onChange('personId', text, index);
        }}
        onDelete={
          onDelete &&
          function() {
            onDelete(index);
          }
        }
        readonly={readonly}
      />
    </Fragment>
  );
};

const withStyles = styled({
  pane: {
    background: colors.contentBackground,
    marginBottom: 20,
  },
  button: {
    float: 'right',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px 0px 15px',
  },
  headerEdit: {
    background: '#90E2D3',
  },
  body: {
    padding: '5px 15px',
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    margin: '5px 0',
    textTransform: 'uppercase',
  },
  create: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 10px 20px',
  },
  icon: {
    marginLeft: 10,
  },
});

export default withStyles(RoleListCard);
