import React, { Fragment } from 'react';
import { EditButton, colors, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import { ErrorSummary } from '@united-talent-agency/components';
import ReadByItemSelector from '../readby-item-selector/readby-item-selector';
import { styled } from 'react-free-style';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';

const Footer = ({ onClick, styles }) => (
  <div className={styles.create}>
    <EditButton type={buttonTypes.new} onClick={onClick} />
  </div>
);

const ReadByCard = props => {
  const {
    items,
    finder,
    onNew,
    onSave,
    onCancel,
    onChange,
    onSelect,
    styles,
    isDirty,
    onDelete,
    invalidItemInCard,
  } = props;
  const [searchIndex, setSearchIndex] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState(null);

  const renderHeader = () => {
    return (
      <div className={classnames(styles.header, isDirty ? styles.headerEdit : undefined)}>
        <h3 className={styles.title}>Read By</h3>
        {isDirty && onCancel && (
          <EditButton
            type={buttonTypes.cancel}
            className={styles.icon}
            onClick={() => {
              onCancel();
            }}
          />
        )}
        {isDirty && onSave && (
          <EditButton
            type={buttonTypes.save}
            className={styles.icon}
            disabled={invalidItemInCard}
            onClick={() => {
              onSave();
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles.pane}>
      {renderHeader()}
      <div className={styles.body}>
        <Row className={styles.tableHeader}>
          {['Agent Name', 'Date'].map((tableHeader, index) => {
            return (
              <Col key={tableHeader} style={{ textTransform: 'uppercase', marginRight: index * 9 }}>
                {tableHeader}
              </Col>
            );
          })}
        </Row>
        {_buildItemRows({
          items,
          searchResults,
          onChange: (itemName, index) => {
            finder(itemName).then(results => {
              setSearchResults(results);
            });
            onChange && onChange(itemName, index);
          },
          onSelect: (item, index) => {
            setSearchResults([]);
            onSelect && onSelect(item, index);
          },
          searchIndex,
          setSearchIndex,
          onDelete,
        })}
      </div>
      {onNew && <Footer onClick={() => onNew()} styles={styles} />}
    </div>
  );
};

const _buildItemRows = ({ items, searchResults, onChange, onSelect, searchIndex, setSearchIndex, onDelete }) => {
  const itemsWithNew = items ? JSON.parse(JSON.stringify(items)) : [];
  if (
    !itemsWithNew.some(i => {
      return i._id === '';
    })
  ) {
    itemsWithNew.push({ _id: '', name: '' });
  }
  return (
    <div>
      {itemsWithNew.map((item, index) => {
        const invalidItem = !item._id && item.name && searchResults && searchResults.length < 1;
        return _buildItemInfo({
          invalid: invalidItem,
          itemName: item.name,
          person: item.reader?.name,
          createdAt: item.createdAt,
          index,
          searchResults: index === searchIndex && searchResults,
          onChange: (text, index) => {
            setSearchIndex(index);
            onChange(text, index);
          },
          onSelect: (item, index) => {
            onSelect(item, index);
          },
          onDelete,
        });
      })}
    </div>
  );
};

const _buildItemInfo = ({
  invalid,
  itemName,
  person,
  createdAt,
  index,
  searchResults,
  onChange,
  onSelect,
  onDelete,
}) => {
  return (
    <Fragment key={`item-${index}-frag`}>
      <hr key={`item-${index}-divider`} style={{ marginTop: 4, marginBottom: 4 }} />
      <ErrorSummary message={invalid ? 'Invalid Agent Assignment' : ''} style={{ width: '100%' }}>
        <ReadByItemSelector
          key={`item-${index}`}
          text={itemName}
          person={person}
          createdAt={createdAt}
          short={true}
          items={searchResults}
          onSelect={item => {
            onSelect(item, index);
          }}
          onChange={text => {
            onChange(text, index);
          }}
          onDelete={
            onDelete &&
            person &&
            person.trim() !== '' &&
            function() {
              onDelete(index);
            }
          }
          placeholder={`Add Agent`}
          onBlurComplete
          invalid={invalid}
        />
      </ErrorSummary>
    </Fragment>
  );
};

const withStyles = styled({
  pane: {
    background: colors.contentBackground,
    marginBottom: 20,
  },
  button: {
    float: 'right',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px 0px 15px',
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: 100,
    marginTop: 2,
    marginBottom: 2,
    color: 'gray',
    width: 'calc(100% - 10px)',
  },
  headerEdit: {
    background: '#90E2D3',
  },
  body: {
    padding: '5px 15px',
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    margin: '5px 0',
    textTransform: 'uppercase',
  },
  create: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 10px 20px',
  },
  icon: {
    marginLeft: 10,
  },
});

export default withStyles(ReadByCard);
