//checks if the input is a creatable talent agency
export const optionIsValidCreation = (inputValue, options, synonym) => {
  let isValid = true;
  if (inputValue === '') {
    isValid = false;
  } else {
    let trimmedInput = inputValue.trim();
    options.forEach(agency => {
      if (
        agency?.value?.name === trimmedInput ||
        agency?.value?.name?.toLowerCase() === trimmedInput?.toLowerCase() ||
        trimmedInput?.toLowerCase() === synonym?.toLowerCase()
      )
        isValid = false;
    });
  }
  return isValid;
};
