import React, { Component } from 'react';
import { styled } from 'react-free-style';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';

import { Dropdown } from '@united-talent-agency/julius-frontend-components';

import { icons } from '../../styles/icons';
import { iconStyles, userOptionsStyles, getUserTypeStyles } from '../../support/user-type-util';
class ReadByItemSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ignoreBlur: false,
      focused: false,
      text: this.props.text,
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.selected = false;
  }

  onKeyDown(event) {
    const { results, value, onCancel, onChange } = this.props;
    const { keyCode } = event;

    if (keyCode === 27) onCancel && onCancel();
    if (keyCode === 40 && !results) onChange(value);
  }

  onFocus(focused = true) {
    const { onFocusChanged } = this.props;
    this.setState({ focused: true });
    onFocusChanged && onFocusChanged(focused);
    this.selected = false;
  }

  onBlur() {
    if (this.selected) {
      return null;
    }
    const { items, onBlur, onBlurClear, onBlurComplete, onSelect } = this.props;
    let { text } = this.state;
    if (onBlurComplete === true) {
      if (items && items.length > 0) {
        onSelect && onSelect(items[0]);
      }
    }
    if (onBlurClear === true) {
      text = '';
      this.onChange(text);
    }
    this.setState({ focused: false });
    onBlur && onBlur(text);
  }

  onSelect(item) {
    const { onSelect } = this.props;
    this.selected = true;
    this.setState({ text: item.name });
    onSelect && onSelect(item);
  }
  onChange(text) {
    const { onChange } = this.props;
    this.selected = false;
    this.setState({ text });
    onChange && onChange(text);
  }
  render() {
    const {
      onDelete,
      styles,
      key,
      items,
      text,
      person,
      createdAt,
      name,
      readOnly,
      maxLength,
      disabled,
      onKeyUp,
      onKeyDown,
      onKeyPress,
      style,
      placeholder,
      invalid,
    } = this.props;
    const { focused } = this.state;
    const getInitial = item => {
      return item && item?.type?.charAt(0).toUpperCase();
    };
    const dropdownItems =
      items &&
      items.map(item => {
        return (
          item && {
            key: item._id ? item._id : item.name,
            className: styles.item,
            onClick: () => {
              this.onSelect(item);
            },
            content: (
              <div className="d-flex flex-row align-items-baseline flex-nowrap pt-1 pb-2">
                {getInitial(item) && (
                  <div className={userOptionsStyles.icon}>
                    <span
                      onClick={() => {}}
                      style={{
                        ...iconStyles,
                        ...{
                          color: getUserTypeStyles(getInitial(item))?.color,
                          backgroundColor: getUserTypeStyles(getInitial(item))?.background,
                        },
                      }}
                    >
                      {getInitial(item)}
                    </span>
                  </div>
                )}
                <span className={styles.selectText}>{item.name}</span>
              </div>
            ),
          }
        );
      });

    const localCreatedAt = createdAt ? moment(createdAt).format('ddd, MM-DD-YYYY hh:mm A') : '';

    return (
      <div className={styles.container} key={key}>
        <div className={styles.container}>
          <Row style={{ width: '100%' }}>
            <Col>
              {person ? (
                <span className={styles.input}>{person}</span>
              ) : (
                <input
                  autoComplete="off"
                  onChange={e => {
                    return this.onChange(e.target.value);
                  }}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  value={text || ''}
                  maxLength={maxLength}
                  className={classnames(
                    styles.input,
                    !disabled && !invalid && styles.inputHover,
                    invalid && styles.inputInvalid
                  )}
                  type="text"
                  placeholder={!disabled ? placeholder : ''}
                  name={name}
                  readOnly={readOnly}
                  disabled={disabled}
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
                  onKeyPress={onKeyPress}
                  style={style}
                />
              )}
            </Col>
            <Col>
              <span className={styles.input} style={{ paddingLeft: 0 }}>
                {localCreatedAt}
              </span>
            </Col>
          </Row>
        </div>
        {!this.selected && focused && items && items.length > 0 && (
          <div>
            <Dropdown key={`${key}-items`} items={dropdownItems} className={styles.floatingMenu} />
          </div>
        )}
        {onDelete ? (
          <div
            className={classnames(styles.delete, styles.section)}
            onClick={() => {
              onDelete && onDelete();
            }}
            key={`${key}-delete`}
          >
            <i className={styles.deleteIcon} />
          </div>
        ) : (
          <div className={classnames(styles.hidden, styles.section)} key={`${key}-delete`}>
            x
          </div>
        )}
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 30,
    marginBottom: 3,
    position: 'relative',
    alignContent: 'top',
    alignItems: 'center',
  },
  input: {
    position: 'relative',
    display: 'flex',
    marginRight: 10,
    padding: 10,
    fontWeight: 100,
    fontSize: 12,
    border: 'none',
    width: '95%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  inputHover: {
    '&:hover': {
      border: '1px solid rgb(87, 140, 247)',
    },
  },
  inputInvalid: {
    border: '1px solid #E95344',
    outline: 'none',
  },
  floatingMenu: {
    display: 'flex',
    marginTop: 32,
    paddingLeft: 5,
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: 1,
    fontSize: 12,
  },
  inputShort: {
    flex: '1 1 auto',
    marginRight: 10,
  },
  section: {
    '&+&': {
      marginLeft: 10,
    },
    marginRight: 10,
  },
  delete: {
    cursor: 'pointer',
    padding: '10px 0',
  },
  hidden: {
    color: 'rgba(0,0,0,0)',
  },
  deleteIcon: icons.smallCrossBlack,
});

export default withStyles(ReadByItemSelector);
