import React, { Component } from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { sortCastings } from '../../../../../support/helpers';
import { AddButton, colors, icons, sizes } from '@united-talent-agency/julius-frontend-components';
import { notify } from 'react-notify-toast';
import {
  updateProject,
  removeProjectCasting,
  setProjectCastingForm,
  getProjectCastings,
  updateProjectCasting,
} from '@united-talent-agency/julius-frontend-store';

import Casting from '../../../../../components/casting/casting';
import Checkbox from '../../../../../components/checkbox/checkbox';
import ProjectCastingForm from '../../../project-casting-form/project-casting-form';
import DataTableLoader from '../../../../../components/content-loader/content-loader';
import cypressTags from '../../../../../support/cypressTags';

class Castings extends Component {
  // eslint-disable-next-line no-undef
  state = {
    isAddOpen: false,
    isEdit: false,
    editingId: 0,
    focused: 0,
    showArchived: true,
  };

  componentDidMount() {
    const projectId = this.props.projects.project._id;
    projectId && this.props.dispatch(getProjectCastings(projectId));
  }

  render() {
    const { styles, projectCastings } = this.props;
    const { showArchived } = this.state;
    const castings =
      projectCastings?.projectCastings?.length > 0
        ? sortCastings(
            projectCastings?.projectCastings?.filter(casting => {
              return showArchived || casting.active;
            })
          )
        : [];
    // TODO - this may not be required, but we're not sure what's going on with priorities yet
    const castingsByType = castings?.reduce((prev, cur) => {
      if (prev?.length === 0 || prev[prev?.length - 1][0] !== cur?.type) {
        prev.push([cur.type, [cur]]);
      } else {
        prev[prev?.length - 1][1].push(cur);
      }
      return prev;
    }, []);
    const upArrow = String.fromCharCode(8679);
    if (!this.props.projects.project._id && castingsByType?.length === 0) {
      notify.show('Unable to load content, Please try later!', 'custom', 2000, {
        background: '#000000',
        text: '#FFFFFF',
      });
    }
    return (
      <React.Fragment>
        <div className={styles.menu} data-cy={cypressTags.PROJECT.ADD_CASTING_BUTTON}>
          <AddButton label="Casting" isOpen={this.state.isAddOpen} toggleAdd={this.toggleAdd} />
          <span style={{ marginLeft: 'auto', marginTop: 'auto' }}>
            <Checkbox
              checked={showArchived}
              onChange={(id, checked) => {
                this.setState({ showArchived: checked });
              }}
              title="Show Archived Castings"
            />
          </span>
        </div>
        {this.state.isAddOpen && !this.state.editingId && this.renderCastingForm()}
        <table id={'castingTable'} className={styles.table}>
          {!this.props.projects.project._id && castingsByType?.length === 0 ? (
            <React.Fragment>
              <thead>
                <tr>
                  <td colSpan={6} style={{ padding: 5 }}>
                    Type: --
                  </td>
                </tr>
                <tr className={styles.th}>
                  <th className={styles.thText}>Role</th>
                  <th className={styles.thText}>Type</th>
                  <th className={styles.thText} style={{ width: 10 }}>
                    {upArrow}
                  </th>
                  <th className={styles.thText}>Job Status</th>
                  <th className={styles.thText}>Description</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr className={styles.addContainer}>
                  {Array.from(Array(5).keys()).map(item => {
                    return (
                      <td key={item} className="p-2">
                        <DataTableLoader />
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {castingsByType.map((castingsBlock, i) => {
                return (
                  <React.Fragment key={castingsBlock[0]}>
                    <thead>
                      <tr>
                        <td colSpan={6} style={{ paddingTop: i > 0 ? 20 : 0 }}>
                          Type: {castingsBlock[0]}
                        </td>
                      </tr>
                      <tr className={styles.th}>
                        <th className={styles.thText}>Role</th>
                        <th className={styles.thText}>Type</th>
                        <th className={styles.thText} style={{ width: 10 }}>
                          {upArrow}
                        </th>
                        <th className={styles.thText}>Job Status</th>
                        <th className={styles.thText}>Description</th>
                        <th className={styles.thText} />
                      </tr>
                    </thead>
                    <tbody>
                      {projectCastings?.projectCastings?.length > 0 ? (
                        <>{this.renderList(castingsBlock[1])}</>
                      ) : (
                        <React.Fragment>
                          <tr className={styles.addContainer}>
                            {Array.from(Array(6).keys()).map(item => {
                              return (
                                <td key={item} className="p-2">
                                  <DataTableLoader />
                                </td>
                              );
                            })}
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          )}
        </table>
      </React.Fragment>
    );
  }

  renderCastingForm() {
    const { dispatch, project, styles, originals } = this.props;
    const { isEdit } = this.state;
    return (
      <div className={styles.addContainer} style={{ marginBottom: !isEdit ? 20 : 0 }}>
        <ProjectCastingForm
          isEdit={isEdit}
          project={project}
          person={(project._id && project._id.personId) || {}}
          onDone={() => {
            this.setState({ isAddOpen: false, editingId: 0 });
            this.props.dispatch(getProjectCastings(project._id)).then(results => {
              originals.castings = JSON.parse(JSON.stringify(results.body));
            });
            dispatch(setProjectCastingForm({}));
          }}
        />
      </div>
    );
  }

  renderList(castings) {
    const { dispatch, project, projects, originals } = this.props;
    const { focused, isEdit } = this.state;
    const _focused = projects.castings[focused];
    let lastCastingType = '';

    return castings.map(item => {
      let showTypeHeader = lastCastingType !== item.type;
      if (showTypeHeader) {
        lastCastingType = item.type;
      }
      if (this.state.editingId === item._id) {
        return (
          <tr key={item._id}>
            <td colSpan="6">{this.renderCastingForm()}</td>
          </tr>
        );
      } else {
        return (
          <Casting
            index={item._id}
            key={item._id}
            dispatch={dispatch}
            casting={item}
            isEdit={isEdit}
            onEditItem={async projectCasting => {
              await dispatch(setProjectCastingForm(projectCasting));
              this.setState({ isAddOpen: false }); // force load person if add already open
              this.setState({ isAddOpen: true, isEdit: true, editingId: item._id });
            }}
            onDeleteItem={async projectCasting => {
              const confirm = window.confirm(
                `Are you sure you want to delete ${projectCasting.name}? This can't be undone.`
              );
              if (confirm) {
                await dispatch(removeProjectCasting(projectCasting._id));
                const castings = project.castings && project.castings.filter(casting => casting !== projectCasting._id);
                dispatch(getProjectCastings(project._id)).then(results => {
                  originals.castings = JSON.parse(JSON.stringify(results.body));
                });
                dispatch(updateProject(project._id, { castings }));
                notify.show(`${projectCasting.name} deleted`);
              }
            }}
            onArchiveItem={casting => {
              dispatch(updateProjectCasting(casting._id, casting)).then(result => {
                const casting = result.body;
                originals.castings.find(c => {
                  return c._id === casting._id;
                }).active = casting.active;
              });
            }}
            onRestoreItem={casting => {
              dispatch(updateProjectCasting(casting._id, casting)).then(result => {
                const casting = result.body;
                originals.castings.find(c => {
                  return c._id === casting._id;
                }).active = casting.active;
              });
            }}
            isFocused={_focused && _focused._id === item._id}
            setFocused={index => {
              this.setState({ focused: index });
            }}
            isActive={item.active || item.active === undefined}
          />
        );
      }
    });
  }

  // eslint-disable-next-line no-undef
  toggleAdd = () => {
    const { dispatch } = this.props;
    const { isAddOpen } = this.state;

    this.setState({ isAddOpen: !isAddOpen, isEdit: false, editingId: 0 });
    dispatch(setProjectCastingForm({}));
  };
}

const withStyles = styled({
  addContainer: helpers.merge(sizes.container, {
    paddingTop: 25,
    background: 'white',
    border: '1px black solid',
  }),
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.black,
    fontSize: 12,
    textTransform: 'uppercase',
    backgroundColor: '#d5d5d5',
    border: '1px black solid',
  },
  thText: {
    textAlign: 'left',
    paddingLeft: 15,
    paddingBottom: 5,
    paddingTop: 5,
  },
  starActiveIcon: icons.starActive,
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
  },
  loading: {
    textAlign: 'center',
    padding: 10,
  },
  menu: helpers.merge(
    {
      display: 'flex',
      marginBottom: 20,
    },
    sizes.container
  ),
});

const withState = connect(state => {
  return state;
});
export default withState(withStyles(Castings));
