import React, { Component } from 'react';
import { loadProfiles } from '../../support/items/profiles';

// TODO use this? Or just Input
import { CompanyInput } from '@united-talent-agency/julius-frontend-components';

class ProfileFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterTypes: null,
      results: [],
      value: '',
    };
  }

  async componentDidMount() {
    this.performSearch(this.state.value);
  }

  componentDidUpdate() {
    const {
      filterModel: { types },
    } = this.props;
    if (this.state.filterTypes !== types) {
      this.setState({ filterTypes: types });
      this.performSearch(this.state.value);
    }
  }

  performSearch(value) {
    const { filterModel } = this.props;
    const results = loadProfiles(filterModel.types, value);
    this.setState({ value, results });
  }

  render() {
    const { label, onSelectProfile, filterModel, onFocusChanged = () => {} } = this.props;
    const { results, value } = this.state;
    const selectedProfiles = filterModel && filterModel.profiles && filterModel.profiles.include;

    return (
      <div className="m-0 p-0 col w-100">
        <CompanyInput
          value={value}
          title={label}
          results={results}
          selectedResults={selectedProfiles}
          onChange={value => {
            this.performSearch(value);
          }}
          onSelect={profile => {
            onSelectProfile(profile);
            this.performSearch('');
          }}
          onFocusChanged={onFocusChanged}
          dropDownMode="inline"
        />
      </div>
    );
  }
}

export default ProfileFilter;
