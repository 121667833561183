import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { notify } from 'react-notify-toast';
import {
  projectLoadRoles,
  updateRole as storeUpdateRole,
  createRole as storeCreateRole,
  projectDeleteRole as storeProjectDeleteRole,
} from '@united-talent-agency/julius-frontend-store';
import { AddButton } from '@united-talent-agency/julius-frontend-components';

import { icons } from '../../../../../styles/icons';
import { AuspiceAdder } from './auspice-adder';
import { GroupByAuspicesTable } from './groupby-auspice-table';
import { AuspicesTable } from './auspices-table';
import Checkbox from '../../../../../components/checkbox/reactstrap-free-checkbox';
import cypressTags from '../../../../../support/cypressTags';
const determineLabel = term => {
  switch (term) {
    case 'type':
      return 'Auspice';
    case 'status':
      return 'Status';
    case 'person':
      return 'Person';
    case 'agency':
      return 'Agency';
    default:
      throw Error('UNSUPPORTED GROUP BY LABEL PRESENTED');
  }
};

const possibleGroupByTerms = ['type', 'status', 'person', 'agency'].map(term => ({
  value: term,
  label: determineLabel(term),
}));

const groupBySelectStyles = {
  container: provided => ({
    ...provided,
    marginRight: '3px',
    minWidth: '100px',
    cursor: 'pointer',
    '&:hover': {
      border: 'none',
    },
  }),
  control: provided => ({
    ...provided,
    cursor: 'pointer',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: '',
    color: '',
    '&:hover': {
      border: 'none',
    },
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer',
    minWidth: '144px',
  }),
  menuList: provided => ({
    ...provided,
    minWidth: '144px',
    fontSize: '14px',
  }),
  menu: provided => ({
    ...provided,
    minWidth: '144px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    display: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'black',
  }),
};

const CustomOption = props => {
  const { data, selectProps } = props;
  const display =
    selectProps.value === data.value ? (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {data.label}
        <i className="fa fa-check" aria-hidden="true" />
      </div>
    ) : (
      data.label
    );
  return <components.Option {...props}>{display}</components.Option>;
};

const CustomControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span>
        <i
          aria-hidden="true"
          style={{ ...icons.groupByIcon, ...{ height: '14px', width: '14px', marginRight: '3px', marginLeft: '15px' } }}
        />
        GROUP
      </span>
      {children}
    </components.Control>
  );
};

const AuspiceSettings = ({ showArchivedPositions, setShowArchivedPositions, setGroupByTerm, groupByTerm }) => {
  return (
    <span className="positions-tab-settings-container">
      <Select
        onChange={({ value }) => {
          value === groupByTerm ? setGroupByTerm(null) : setGroupByTerm(value);
        }}
        options={possibleGroupByTerms}
        placeholder=""
        value={groupByTerm}
        styles={groupBySelectStyles}
        controlShouldRenderValue={false}
        isSearchable={false}
        components={{ Control: CustomControl, Option: CustomOption }}
      />
      <Checkbox
        checked={showArchivedPositions}
        onChange={() => setShowArchivedPositions(!showArchivedPositions)}
        title="Show Archived Auspices"
        extraStyles={{ cursor: 'pointer' }}
      />
    </span>
  );
};

export const AuspicesView = props => {
  const dispatch = useDispatch();
  const { roles, project } = props;
  if (!project?._id && roles?.length === 0) {
    notify.show('Unable to load content, Please try later!', 'custom', 2000, {
      background: '#000000',
      text: '#FFFFFF',
    });
  }

  const [roleState, setRoleState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loadedRoles: [],
    allRoles: [],
  });
  const [showArchivedPositions, setShowArchivedPositions] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [groupByTerm, setGroupByTerm] = useState(null);

  const createRole = async role => await dispatch(storeCreateRole(role));

  const loadRoles = async () => {
    await dispatch(projectLoadRoles(project._id)).then(({ body }) => {
      if (body) {
        if (showArchivedPositions) {
          setRoleState({ loadedRoles: body, allRoles: body });
        } else {
          setRoleState({ loadedRoles: body.filter(role => role.active), allRoles: body });
        }
      }
    });
  };

  const updateRole = async (roleId, role) => await dispatch(storeUpdateRole(roleId, role));

  const projectDeleteRole = async roleId => await dispatch(storeProjectDeleteRole(roleId));

  const toggleAdd = () => {
    setIsAddOpen(prevState => !prevState);
  };

  useEffect(() => {
    showArchivedPositions
      ? setRoleState({ loadedRoles: roles, allRoles: roles })
      : setRoleState({ loadedRoles: roles.filter(role => role.active), allRoles: roles });
  }, [roles, showArchivedPositions]);

  return (
    <React.Fragment>
      <div className="auspices-header" data-cy={cypressTags.PROJECT.ADD_AUSPICE_BUTTON}>
        <AddButton label="Auspice" isOpen={isAddOpen} toggleAdd={toggleAdd} />
        <AuspiceSettings
          showArchivedPositions={showArchivedPositions}
          setShowArchivedPositions={setShowArchivedPositions}
          groupByTerm={groupByTerm}
          setGroupByTerm={setGroupByTerm}
        />
      </div>
      {isAddOpen && (
        <AuspiceAdder
          loadRoles={loadRoles}
          project={project}
          createRole={createRole}
          allRoles={roleState.allRoles}
          onDone={toggleAdd}
        />
      )}

      {groupByTerm === null ? (
        <AuspicesTable
          roles={roleState.loadedRoles}
          loadRoles={loadRoles}
          project={project}
          updateRole={updateRole}
          projectDeleteRole={projectDeleteRole}
          allRoles={roleState.allRoles}
        />
      ) : (
        <GroupByAuspicesTable
          roles={roleState.loadedRoles}
          loadRoles={loadRoles}
          project={project}
          updateRole={updateRole}
          projectDeleteRole={projectDeleteRole}
          allRoles={roleState.allRoles}
          groupByTerm={groupByTerm}
        />
      )}
    </React.Fragment>
  );
};
