import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { colors } from '@united-talent-agency/julius-frontend-components';
import { loadLiteraryNotes, removeProjectNote, updateProjectNote } from '@united-talent-agency/julius-frontend-store';
import { withStyles } from '@material-ui/core/styles';

import NoteTableActionHeader from '../note-table-action-header';
import Note from '../note';
import ProjectNoteForm from '../project-note-form';
import { notify } from 'react-notify-toast';
import DataTableLoader from '../../../../../../components/content-loader/content-loader';

const HEADERS = ['Person', 'Date', 'Client', 'Status', 'Note', 'Internal', 'Action'];

const LiteraryNotes = ({ project, classes }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [isEdit, setIsEditing] = useState(false);
  const [focused, setFocused] = useState(0);
  const [note, setNote] = useState({});
  const literaryNotes = useSelector(state => state.projectNotes.literaryNotes);
  const dispatch = useDispatch();
  const focusedNote = (literaryNotes && literaryNotes[focused]) || {};

  const toggleAdd = () => {
    setModalOpen(!isModalOpen);
    setIsEditing(false);
    setNote({ internal: true });
  };

  const archiveNote = async projectNote => {
    projectNote.archived = !projectNote.archived;
    await dispatch(updateProjectNote(projectNote._id, projectNote));
    await dispatch(loadLiteraryNotes(project._id));
  };

  useEffect(() => {
    project?._id && dispatch(loadLiteraryNotes(project._id));
  }, [dispatch, project]);

  const visibleNotes = showArchived ? literaryNotes : literaryNotes.filter(note => !note.archived);
  if (!project?._id && visibleNotes?.length === 0) {
    notify.show('Unable to load content, Please try later!', 'custom', 2000, {
      background: '#000000',
      text: '#FFFFFF',
    });
  }

  return (
    <React.Fragment>
      <NoteTableActionHeader
        isModalOpen={isModalOpen}
        toggleAdd={toggleAdd}
        showArchived={showArchived}
        onShowArchiveChange={() => setShowArchived(!showArchived)}
      />

      {isModalOpen && (
        <div className={classes.addContainer}>
          <ProjectNoteForm
            isEdit={isEdit}
            project={project}
            note={note}
            noteType="Literary"
            onDone={() => {
              setModalOpen(false);
              dispatch(loadLiteraryNotes(project._id));
            }}
          />
        </div>
      )}
      <table id="noteTable" className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            {HEADERS.map((header, index) => {
              return (
                <th key={index} className={classes.thText}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!project?._id ? (
            <React.Fragment>
              <tr className={classes.td}>
                {Array.from(Array(7).keys(7)).map(item => {
                  return (
                    <td key={item} className="px-2">
                      <DataTableLoader />
                    </td>
                  );
                })}
              </tr>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {visibleNotes
                .sort((a, b) => {
                  const aCreated = a.created_date ? 1 : 0;
                  const bCreated = b.created_date ? 1 : 0;
                  if (aCreated === bCreated) {
                    return Date.parse(b.created_date) - Date.parse(a.created_date);
                  } else {
                    return bCreated - aCreated;
                  }
                })
                .map((note, index) => {
                  const isFocused = focusedNote._id === note._id;
                  return (
                    <Note
                      dispatch={dispatch}
                      showInternalColumn
                      note={note}
                      isEdit={isEdit}
                      index={index}
                      key={index}
                      onEditItem={async projectNote => {
                        setNote(projectNote);
                        setModalOpen(false); // force load person if add already open
                        setModalOpen(true);
                        setIsEditing(true);
                      }}
                      onArchiveNote={archiveNote}
                      onDeleteItem={projectNote => {
                        dispatch(removeProjectNote(projectNote._id));
                        dispatch(loadLiteraryNotes(project._id));
                      }}
                      isFocused={isFocused}
                      setFocused={index => {
                        setFocused(index);
                      }}
                    />
                  );
                })}
            </React.Fragment>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

const styles = {
  addContainer: {
    width: '100%',
    maxWidth: '1000px',
    padding: '25px 10px 0 10px',
    margin: '0 auto',
    background: 'white',
    border: '1px black solid',
  },
  tableActionHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  td: {
    '&:hover': {
      borderBottom: `1px solid ${colors.border}`,
      backgroundColor: colors.focusBackground,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
    fontWeight: 100,
    fontSize: '11px',
  },
};
export default withStyles(styles)(LiteraryNotes);
