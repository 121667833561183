import React, { useState, useEffect } from 'react';
import { styled } from 'react-free-style';
import * as _ from 'lodash';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import {
  loadTrackings,
  createTracking,
  addToTracking,
  projectSearchProjects,
} from '@united-talent-agency/julius-frontend-store';
import { Input } from '@united-talent-agency/julius-frontend-components';
import { Spinner } from '@united-talent-agency/components';
import cypressTags from '../../support/cypressTags';

const ListManager = props => {
  const {
    desk,
    dispatch,
    projectsFunc,
    onCancel,
    onCreated,
    onUpdated,
    styles,
    user,
    selectAll,
    limit,
    filters,
  } = props;

  const [title, setTitle] = useState('Untitled');
  const [trackings, setTrackings] = useState([]);
  const [clientTrackings, setClientTrackings] = useState([]);
  const [sharedTrackings, setSharedTrackings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const personId = user.personId && user.personId._id;
    dispatch(loadTrackings(desk._id, personId)).then(trackingsResult => {
      const tList = [],
        cList = [],
        sList = [];
      if (trackingsResult && trackingsResult.body) {
        trackingsResult.body.forEach(list => {
          switch (list.type) {
            case 'Desk':
              tList.push(list);
              break;
            case 'Client':
              {
                const keysToValidate = ['id', 'name', 'clientName'];
                if (keysToValidate.some(key => list[key])) {
                  cList.push(list);
                }
              }
              break;
            case 'Shared':
              sList.push(list);
              break;
            default:
              break;
          }
        });
      }
      setTrackings(tList);
      setClientTrackings(cList);
      setSharedTrackings(sList);
      setIsLoading(false);
    });
  }, [desk, dispatch, user]);

  const createList = async () => {
    const payload = {
      deskId: desk._id,
      name: title,
      projects: projectsFunc(),
    };

    let filter = filters || user.projectFilter;
    const isLimit = limit >= 100;

    // if select all fetch all projects associated with current user filter
    if (selectAll && isLimit) {
      filter.limit = limit || 100;
      filter.skip = 0;
      const { body } = await dispatch(projectSearchProjects(filter));
      payload.projects = await (body?.data ? body.data?.map(item => item?._id) : payload.projects);
      await dispatch(createTracking(payload)).then(response => {
        onCreated && onCreated(response.body);
      });
    } else {
      await dispatch(createTracking(payload)).then(response => {
        onCreated && onCreated(response.body);
      });
    }
  };

  const addToList = async id => {
    let payloadProjects = projectsFunc();
    let filter = filters || user.projectFilter;
    const isLimit = limit >= 100;
    if (selectAll && isLimit) {
      filter.limit = limit || 100;
      filter.skip = 0;
      await dispatch(addToTracking(id, { projects: filter })).then(response => {
        onUpdated && onUpdated(response.body);
      });
    } else {
      await dispatch(addToTracking(id, { projects: { projects: payloadProjects } })).then(response => {
        onUpdated && onUpdated(response.body);
      });
    }
  };

  const submitOnEnter = evt => {
    if (evt.which === 13) {
      createList();
    }
  };

  return (
    <div className={styles.container}>
      <div className={`ml-1 row`}>
        <h5 className="mx-0 mb-2 mt-0 p-0">Add to List</h5>
        <div
          style={{ cursor: 'pointer' }}
          className={`ml-auto mr-3`}
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          <i className={'fa fa-fw fa-window-close'} aria-hidden="true" />
        </div>
      </div>

      {/* My Lists */}
      <ListGroup>
        <b style={{ margin: 8, color: 'gray' }}>MY LISTS</b>
        <ListGroupItem style={{ paddingLeft: 0, marginBottom: '5px' }}>
          <div className={`row ml-2`}>
            <div data-cy={cypressTags.PROJECT_ACTIONS.LIST_NAME_INPUT}>
              <Input
                title="New List"
                value={title}
                onChange={value => {
                  setTitle(value);
                }}
                onKeyDown={submitOnEnter}
              />
            </div>
            <div
              data-cy={cypressTags.PROJECT_ACTIONS.CREATE_LIST_BUTTON}
              className={`ml-auto mr-2`}
              style={{ marginTop: '12px', cursor: 'pointer' }}
            >
              <Tooltip title="Create a New List">
                <AddCircleOutlineIcon onClick={createList} />
              </Tooltip>
            </div>
          </div>
        </ListGroupItem>
        {isLoading && <Spinner />}
        {!isLoading &&
          _.sortBy(trackings, [listItem => listItem.name.toLowerCase()]).map((list, index) => {
            return list.archived ? null : (
              <ListGroupItem key={index} style={{ paddingLeft: 0, marginBottom: '5px' }}>
                <div className={`row ml-2`} data-cy={cypressTags.PROJECT_ACTIONS.EXISTING_LISTS}>
                  <div
                    onClick={function() {
                      window.open(`/list/${list._id}`, list._id);
                    }}
                    style={{ cursor: 'pointer', paddingRight: 10 }}
                  >
                    <Tooltip title="Open List Page">
                      <Typography>{list.name}</Typography>
                    </Tooltip>
                  </div>
                  <div
                    className={`ml-auto mr-2`}
                    style={{ cursor: 'pointer' }}
                    data-cy={cypressTags.PROJECT_ACTIONS.ADD_TO_EXISTING_LIST_BUTTON}
                  >
                    <Tooltip title="Add to List">
                      <AddCircleIcon
                        onClick={event => {
                          event.stopPropagation();
                          addToList(list._id);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </ListGroupItem>
            );
          })}
      </ListGroup>
      <br />
      {/* Client Lists */}
      {!isLoading && (
        <ListGroup>
          <b style={{ margin: 8, color: 'gray' }}>CLIENT LISTS</b>
          {_.sortBy(clientTrackings, [
            listItem => listItem?.clientName?.toLowerCase() + listItem?.name?.toLowerCase(),
          ]).map((list, index) => {
            return list?.archived ? null : (
              <ListGroupItem key={index} style={{ paddingLeft: 0, marginBottom: '5px' }}>
                <div className={`row ml-2`}>
                  <div
                    onClick={function() {
                      window.open(`/list/${list?._id}`, list?._id);
                    }}
                    style={{ cursor: 'pointer', paddingRight: 10 }}
                  >
                    <Tooltip title="Open List Page">
                      <Typography>
                        {list?.clientName}
                        <br /> - {list?.name}
                      </Typography>
                    </Tooltip>
                  </div>
                  <div className={`ml-auto mr-2`} style={{ cursor: 'pointer' }}>
                    <Tooltip title="Add to List">
                      <AddCircleIcon
                        onClick={event => {
                          event.stopPropagation();
                          addToList(list?._id);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      )}
      <br />
      {/* Shared Lists */}
      {!isLoading && sharedTrackings.length > 0 && (
        <ListGroup>
          <b style={{ margin: 8, color: 'gray' }}>SHARED LISTS</b>
          {_.sortBy(sharedTrackings, [listItem => listItem.name.toLowerCase()]).map((list, index) => {
            return list.archived ? null : (
              <ListGroupItem key={index} style={{ paddingLeft: 0, marginBottom: '5px' }}>
                <div className={`row ml-2`}>
                  <div
                    onClick={function() {
                      window.open(`/list/${list._id}`, list._id);
                    }}
                    style={{ cursor: 'pointer', paddingRight: 10 }}
                  >
                    <Tooltip title="Open List Page">
                      <Typography>{list.name}</Typography>
                    </Tooltip>
                  </div>
                  <div
                    className="ml-auto mr-2"
                    style={{ cursor: 'pointer' }}
                    data-cy={cypressTags.PROJECT_ACTIONS.ADD_TO_CURRENT_LIST_BUTTON}
                  >
                    <Tooltip title="Add to List">
                      <AddCircleIcon
                        onClick={event => {
                          event.stopPropagation();
                          addToList(list._id);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      )}
    </div>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 5,
  },
});

export default withStyles(ListManager);
