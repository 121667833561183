import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import PrintOptions from '../print-options/print-options';
import cypressTags from '../../support/cypressTags';

export class PrintManager extends Component {
  constructor(props) {
    super(props);
    this.state = props.savedOptions || {
      executives: true,
      includeLiteraryExecs: true,
      includeTalentExecs: true,
      staff: true,
      staffByPerson: false,
      includeStaffAgencies: false,
      cast: true,
      index: true,
      talentNotes: false,
      literaryNotes: false,
      indieNotes: false,
      includeInternalLiteraryNotes: false,
      includeDatesLiteraryNotes: false,
      budget: true,
      internalHeader: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      agents: true,
      talentAgents: true,
      literaryAgents: true,
      indieAgents: true,
      budgetNotes: false,
      sortBy: '',
    };
  }

  print() {
    this.props.print(this.state);
    this.props.collapseEvent();
  }

  // eslint-disable-next-line no-undef
  onChange = selections => {
    this.setState(selections);
  };

  render() {
    const {
      collapseEvent,
      classes,
      indexOptionsDisabled,
      userInTalent,
      savedOptions,
      updateGrid,
      grid,
      canEdit,
      isCastingResults,
      docCount,
      showSortOptions,
    } = this.props;
    return (
      <div className={classes.container}>
        <div className="ml-1 row">
          <h5 className="p-0 mx-0 mt-0 mb-2">Print</h5>
          <div style={{ cursor: 'pointer' }} className="ml-auto mr-3" onClick={collapseEvent}>
            <i className="fa fa-fw fa-window-close" aria-hidden="true" />
          </div>
        </div>

        <PrintOptions
          savedOptions={savedOptions}
          onSelectionsChanged={this.onChange}
          indexOptionsDisabled={indexOptionsDisabled}
          userInTalent={userInTalent}
          isCastingResults={isCastingResults}
          showSortOptions={showSortOptions}
        />
        {grid && canEdit && (
          <button
            className={'saveToGrid ' + classes.saveToGrid}
            onClick={async e => {
              e.preventDefault();
              grid.printOptions = this.state;
              await updateGrid(grid);
            }}
          >
            <i className={`fa fa-w fa-save ` + classes.icon} />
            Save print settings to grid
          </button>
        )}

        <div className={classes.buttonContainer}>
          <button onClick={collapseEvent} className={classes.button}>
            <i className={`fa fa-w fa-ban ${classes.icon}`} />
            Cancel
          </button>

          <button
            onClick={this.print.bind(this)}
            className={classes.button}
            data-cy={cypressTags.PROJECT_ACTIONS.PRINT_PROJECTS}
          >
            <i className={`fa fa-w fa-print ${classes.icon}`} />
            Print
          </button>
        </div>
        {docCount && Number(docCount) > 5000 ? (
          <p>
            <span>&#9888; </span>
            please print documents less than or equal to 5000, get a more accurate pdf.
          </p>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F5F5F5',
    right: 0,
    marginTop: '-19px',
    minWidth: '250px',
    width: '25%',
    borderLeft: '1px solid #dcdcdc',
    borderBottom: '1px solid #dcdcdc',
    position: 'absolute',
    padding: '15px 15px 0 15px',
    top: 'auto',
    minHeight: '100%',
    zIndex: 1,
  },
  icon: {
    marginRight: 5,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 15,
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 'none',
    fontSize: 14,
    lineHeight: 1,
    backgroundColor: 'unset',
    textTransform: 'uppercase',
  },
  saveToGrid: {
    backgroundColor: 'unset',
    textTransform: 'uppercase',
    border: 'none',
    fontSize: 14,
    margin: 15,
  },
};

export default withStyles(styles)(PrintManager);
