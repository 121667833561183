import React, { Component } from 'react';

import NameInput from '../name-input/name-input';

class NamesFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      value: '',
    };
  }

  async componentDidMount() {
    const { searchNames } = this.props;
    const { value } = this.state;
    const results = await searchNames(value);

    this.setState({ results });
  }

  render() {
    const { label, onSelect, searchNames, onFocusChanged,onBlurClear } = this.props;
    const { results, value } = this.state;

    return (
      <div className={`m-0 p-0 col w-100`}>
        <NameInput
          value={value}
          title={label}
          results={results}
          focus
          notRequired={true}
          onChange={async v => {
            this.setState({ value: v });
            const res = await searchNames(v);
            this.setState({ results: res });
          }}
          onSelect={onSelect}
          onFocusChanged={onFocusChanged}
          dropDownMode="inline"
          onBlurClear={onBlurClear}
        />
      </div>
    );
  }
}

export default NamesFilter;
