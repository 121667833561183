import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { styled } from 'react-free-style';
import { Input, EditButton, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import classnames from 'classnames';

class ListName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  cancel() {
    this.setState({
      isEditing: false,
      name: this.props.name,
    });
  }

  save() {
    if (this.state.name && this.state.name !== this.props.name) {
      this.props.saveChanges(this.state.name.trim());
      this.setState({ isEditing: false });
    }
  }

  render() {
    const { styles, archived } = this.props;
    const { isEditing } = this.state;

    if (isEditing)
      return (
        <div className={styles.container}>
          <Input
            type="text"
            title="Name"
            value={this.state.name || this.props.name}
            onChange={name => this.setState({ name })}
          />
          <EditButton type={buttonTypes.cancel} className={styles.icon} onClick={() => this.cancel()} />
          <EditButton type={buttonTypes.save} className={styles.icon} onClick={() => this.save()} />
        </div>
      );

    return (
      <div className={styles.container}>
        <span className={classnames(styles.name, archived ? styles.strikeout : null)}>
          {this.state.name || this.props.name}
        </span>
        <EditButton
          type={buttonTypes.edit}
          className={styles.icon}
          onClick={() => {
            this.setState({ isEditing: true });
          }}
        />
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    margin: '5px 0 0px 0',
    alignItems: 'center',
  },
  name: {
    fontSize: 24,
    fontWeight: 300,
  },
  icon: {
    marginLeft: 10,
  },
  strikeout: {
    textDecorationLine: 'line-through',
  },
});

export default withRouter(withStyles(ListName));
