import React, { Fragment } from 'react';
import { EditButton, colors, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import RepAgreementsItem from './rep-agreements-item';
import RepAgreementItem from './rep-agreement-item';
import { styled } from 'react-free-style';
import classnames from 'classnames';

const RepAgreementsCard = props => {
  const { items = [], onSave, onCancel, onChanged, styles, isDirty, onDelete } = props;
  const { REACT_APP_FEATURE_MULTIPLE_REP_AGREEMENTS } = process.env;
  const renderHeader = () => {
    return (
      <div className={classnames(styles.header, isDirty ? styles.headerEdit : undefined)}>
        <h3 className={styles.title}>Rep Agreements</h3>
        {isDirty && onCancel && (
          <EditButton
            type={buttonTypes.cancel}
            className={styles.icon}
            onClick={() => {
              onCancel();
            }}
          />
        )}
        {isDirty && onSave && (
          <EditButton
            type={buttonTypes.save}
            className={styles.icon}
            onClick={() => {
              onSave();
            }}
          />
        )}
      </div>
    );
  };

  const renderFooter = () => {
    if (REACT_APP_FEATURE_MULTIPLE_REP_AGREEMENTS !== true.toString()) {
      return null;
    }
    return (
      <div className={styles.create}>
        <EditButton
          type={buttonTypes.new}
          onClick={() => onChanged({ active: true, status: '', summary: '', fileLocation: '' }, items.length)}
        />
      </div>
    );
  };

  const renderColumnHeaders = () => {
    return (
      <div
        style={{
          fontSize: 12,
          fontWeight: 100,
          marginTop: 2,
          color: 'gray',
          width: '100%',
          display: 'flex',
          marginLeft: 20,
        }}
      >
        <span style={{ width: '30%' }}>LINK</span>
        <span style={{ width: '28%' }}>STATUS</span>
        <span style={{ width: '42%' }}>SUMMARY</span>
      </div>
    );
  };

  const activeItemFinder = i => i.active;

  const singleMode = items.filter(activeItemFinder).length <= 1;
  const singleItem = items.find(activeItemFinder) || { active: true };
  const singleItemPos = items.findIndex(activeItemFinder);
  const singleItemIndex = singleItemPos === -1 ? items.length : singleItemPos;
  return (
    <div className={styles.pane}>
      {renderHeader()}
      {!singleMode && renderColumnHeaders()}
      <div className={styles.body}>
        {!singleMode &&
          _buildItemRows({
            items,
            onChanged,
            onDelete,
          })}
        {singleMode && (
          <RepAgreementItem
            key="rep-agreement"
            fileLocation={singleItem.fileLocation}
            onFileLocationChanged={value => {
              singleItem.fileLocation = value;
              onChanged && onChanged(singleItem, singleItemIndex);
            }}
            status={singleItem.status}
            onStatusChanged={value => {
              singleItem.status = value;
              onChanged && onChanged(singleItem, singleItemIndex);
            }}
            summary={singleItem.summary}
            onSummaryChanged={value => {
              singleItem.summary = value;
              onChanged && onChanged(singleItem, singleItemIndex);
            }}
            executionDate={singleItem.executionDate}
            onExecutionDateChanged={date => {
              singleItem.executionDate = date;
              onChanged && onChanged(singleItem, singleItemIndex);
            }}
            amended={singleItem.amended}
            onAmendedChanged={value => {
              singleItem.amended = value;
              onChanged && onChanged(singleItem, singleItemIndex);
            }}
          />
        )}
      </div>
      {singleMode && renderFooter()}
    </div>
  );
};
const _buildItemRows = ({ items, onChanged, onDelete }) => {
  const itemsWithNew = items || [];
  if (
    !itemsWithNew.some(i => {
      return i.fileLocation === '' && i.summary === '' && i.status === '' && i.active === true;
    })
  ) {
    itemsWithNew.push({ active: true, status: '', summary: '', fileLocation: '' });
  }

  return (
    <div>
      {itemsWithNew.map((item, index) => {
        return (
          item.active &&
          _buildItemInfo({
            item,
            index,
            onChanged: (field, text, index) => {
              item[field] = text;
              onChanged && onChanged(item, index);
            },
            onDelete:
              index < itemsWithNew.length - 1 &&
              (index => {
                onDelete && onDelete(index);
              }),
          })
        );
      })}
    </div>
  );
};

const _buildItemInfo = ({ item, index, onChanged, onDelete }) => {
  return (
    <Fragment key={index}>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <RepAgreementsItem
        key={`rep-agreement-${index}`}
        fileLocation={item.fileLocation}
        onFileLocationChanged={value => {
          onChanged && onChanged('fileLocation', value, index);
        }}
        status={item.status}
        onStatusChanged={value => {
          onChanged && onChanged('status', value, index);
        }}
        summary={item.summary}
        onSummaryChanged={value => {
          onChanged && onChanged('summary', value, index);
        }}
        onDelete={
          onDelete &&
          (() => {
            onDelete(index);
          })
        }
        executionDate={item.executionDate}
        onExecutionDateChanged={date => {
          onChanged && onChanged('executionDate', date, index);
        }}
        amended={item.amended}
        onAmendedChanged={value => {
          onChanged && onChanged('amended', value, index);
        }}
      />
    </Fragment>
  );
};

const withStyles = styled({
  pane: {
    background: colors.contentBackground,
    marginBottom: 20,
  },
  button: {
    float: 'right',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px 0px 15px',
  },
  headerEdit: {
    background: '#90E2D3',
  },
  body: {
    padding: '5px 15px',
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    margin: '5px 0',
    textTransform: 'uppercase',
  },
  create: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 10px 20px',
  },
  icon: {
    marginLeft: 10,
  },
});

export default withStyles(RepAgreementsCard);
