import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { loadTrackings } from '@united-talent-agency/julius-frontend-store';
import { Spinner } from '@united-talent-agency/julius-frontend-components';
import Checkbox from '../../../../../components/checkbox/checkbox';

const Lists = props => {
  const { classes, user, desk, dispatch } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [showArchivedMyLists, setShowArchivedMyLists] = useState(false);
  const [showArchivedClientLists, setShowArchivedClientLists] = useState(false);
  const [showArchivedSharedLists, setShowArchivedSharedLists] = useState(false);
  const [trackings, setTrackings] = useState([]);
  const [clientTrackings, setClientTrackings] = useState([]);
  const [sharedLists, setSharedLists] = useState([]);
  useEffect(() => {
    if (!user || !desk) {
      return;
    }

    let userPersonID = user.personId?._id;

    dispatch(loadTrackings(desk._id, userPersonID)).then(trackingsResult => {
      const tList = [],
        cList = [],
        sList = [];
      if (trackingsResult && trackingsResult.body) {
        trackingsResult.body.forEach(list => {
          switch (list.type) {
            case 'Desk':
              tList.push(list);
              break;
            case 'Client':
              {
                const keysToValidate = ['id', 'name', 'clientName'];
                if (keysToValidate.some(key => list[key])) {
                  cList.push(list);
                }
              }
              break;
            case 'Shared':
              sList.push(list);
              break;
            default:
              break;
          }
        });
      }
      setTrackings(tList);
      setClientTrackings(cList);
      setSharedLists(sList);
      setIsLoading(false);
    });
  }, [desk, user, dispatch]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {/* MY LISTS */}
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <div className={classes.listSection}>
              <h2>My Lists</h2>
              <div className={classes.showArchivedCheckbox}>
                <Checkbox
                  title={'Show Archived'}
                  checked={showArchivedMyLists}
                  onChange={() => setShowArchivedMyLists(!showArchivedMyLists)}
                />
              </div>
              <ul className={!isLoading ? classes.gridList : classes.loaderStyle}>
                <Fragment>
                  {isLoading ? (
                    <div className="d-flex align-items-center h-100 w-100">
                      <Spinner size={25} />
                    </div>
                  ) : (
                    <Fragment>
                      {trackings &&
                        trackings
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((tracking, index) => {
                            const { isStrikeout, listIcon } = tracking.archived
                              ? { isStrikeout: classes.strikeout, listIcon: 'fa-archive' }
                              : { isStrikeout: '', listIcon: 'fa-list' };
                            return !showArchivedMyLists && tracking.archived ? (
                              <div key={index} />
                            ) : (
                              <li className={classnames(classes.gridItem, isStrikeout)} key={index}>
                                <Link
                                  to={{ pathname: `/list/${tracking._id}` }}
                                  target={tracking._id}
                                  style={{ color: 'black' }}
                                >
                                  <i className={`fa fa-fw ${listIcon}`} aria-hidden="true" />
                                  {tracking.name}
                                </Link>
                              </li>
                            );
                          })}
                    </Fragment>
                  )}
                </Fragment>
              </ul>
            </div>
          </Paper>
        </Grid>

        {/* CLIENT LISTS */}
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <div className={classes.listSection}>
              <h2>Client Lists</h2>
              <div className={classes.showArchivedCheckbox}>
                <Checkbox
                  title={'Show Archived'}
                  checked={showArchivedClientLists}
                  onChange={() => setShowArchivedClientLists(!showArchivedClientLists)}
                />
              </div>
              <ul className={!isLoading ? classes.gridList : classes.loaderStyle}>
                <Fragment>
                  {isLoading ? (
                    <div className="d-flex align-items-center h-100 w-100">
                      <Spinner size={25} />
                    </div>
                  ) : (
                    <Fragment>
                      {clientTrackings &&
                        clientTrackings?.length > 0 &&
                        clientTrackings
                          ?.sort((a, b) =>
                            a?.clientName?.toLowerCase() + a?.name?.toLowerCase() >
                            b?.clientName?.toLowerCase() + b?.name?.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((tracking, index) => {
                            const { isStrikeout, listIcon } = tracking?.archived
                              ? { isStrikeout: classes.strikeout, listIcon: 'fa-archive' }
                              : { isStrikeout: '', listIcon: 'fa-list' };
                            return !showArchivedClientLists && tracking?.archived ? (
                              <div key={index} />
                            ) : (
                              <li className={classnames(classes.gridItem, isStrikeout)} key={index}>
                                <Link
                                  to={{ pathname: `/list/${tracking?._id}` }}
                                  target={tracking?._id}
                                  style={{ color: 'black' }}
                                >
                                  <i className={`fa fa-fw ${listIcon}`} aria-hidden="true" />
                                  {tracking?.clientName} - {tracking.name}
                                </Link>
                              </li>
                            );
                          })}
                    </Fragment>
                  )}
                </Fragment>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {sharedLists && sharedLists.length > 0 && (
        <Grid container spacing={4}>
          {/* SHARED LISTS */}
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <div className={classes.listSection}>
                <h2>Shared Lists</h2>
                <div className={classes.showArchivedCheckbox}>
                  <Checkbox
                    title={'Show Archived'}
                    checked={showArchivedSharedLists}
                    onChange={() => setShowArchivedSharedLists(!showArchivedSharedLists)}
                  />
                </div>
                <ul className={!isLoading ? classes.gridList : classes.loaderStyle}>
                  <Fragment>
                    {isLoading ? (
                      <div className="d-flex align-items-center h-100 w-100">
                        <Spinner size={25} />
                      </div>
                    ) : (
                      <Fragment>
                        {sharedLists &&
                          sharedLists
                            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                            .map((tracking, index) => {
                              const { isStrikeout, listIcon } = tracking.archived
                                ? { isStrikeout: classes.strikeout, listIcon: 'fa-archive' }
                                : { isStrikeout: '', listIcon: 'fa-list' };
                              return !showArchivedSharedLists && tracking.archived ? (
                                <div key={index} />
                              ) : (
                                <li className={classnames(classes.gridItem, isStrikeout)} key={index}>
                                  <Link
                                    to={{ pathname: `/list/${tracking._id}` }}
                                    target={tracking._id}
                                    style={{ color: 'black' }}
                                  >
                                    <i className={`fa fa-fw ${listIcon}`} aria-hidden="true" />
                                    {tracking.name}
                                  </Link>
                                </li>
                              );
                            })}
                      </Fragment>
                    )}
                  </Fragment>
                </ul>
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const styles = {
  root: {
    flexGrow: 1,
    margin: 10,
    marginTop: 20,
  },
  paper: {
    padding: 10,
    height: '100%',
  },
  listSection: {
    height: '100%',
  },
  gridList: {
    listStyle: 'none',
    width: '200%',
    marginLeft: '-30px',
    marginRight: '15px',
    marginTop: 10,
  },
  showArchivedCheckbox: {
    marginLeft: '15px',
  },
  gridItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
    marginTop: 5,
    marginBottom: 5,
  },
  loaderStyle: {
    paddingLeft: '30px',
    paddingTop: '5px',
    width: '100%',
    height: '150px',
  },
};

const mapStateToProps = state => {
  const { user, desk } = state;
  return {
    user,
    desk: desk.current,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Lists));
