import React from 'react';

const EditGrid = ({ grid, saveGrid, show, classes }) => {
  return (
    <div className={classes.rightAlign}>
      {grid.archived ? (
        <button
          className={'unarchive ' + classes.button}
          onClick={async () => {
            grid.archived = !grid.archived;
            await saveGrid(grid);
            show(`${grid.name} updated`, 'custom', 1000, {
              background: '#000000',
              text: '#FFFFFF',
            });
          }}
        >
          <i className={classes.icon + ' fa fa-fw fa-undo'} />
          Unarchive
        </button>
      ) : (
        <button
          className={'archive ' + classes.button}
          onClick={async () => {
            grid.archived = !grid.archived;
            await saveGrid(grid);
            show(`${grid.name} updated`, 'custom', 1000, {
              background: '#000000',
              text: '#FFFFFF',
            });
          }}
        >
          <i className={'fa fa-fw fa-archive ' + classes.icon} />
          Archive
        </button>
      )}
      <button
        className={'save ' + classes.button}
        onClick={async () => {
          await saveGrid(grid);
          show(`${grid.name} updated`, 'custom', 1000, {
            background: '#000000',
            text: '#FFFFFF',
          });
        }}
      >
        <i className="fa fa-fw fa-save" />
        Save
      </button>
    </div>
  );
};

export { EditGrid };
