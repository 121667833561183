export default [
  'Adult Contemporary',
  'Country',
  'Dance',
  'Hip-Hop',
  'Indie',
  'Jam / Americana',
  'Latin',
  'Pop',
  'R&B',
  'Rock',
];
