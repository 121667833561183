import React, { Component } from 'react';
import { Input } from '@united-talent-agency/julius-frontend-components';

class FreeTextFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterType: 'Any',
      results: [],
      value: '',
    };
  }

  async componentDidMount() {
    this.performSearch(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const { filterModel } = this.props;
    if (filterModel && filterModel.type && filterModel.type !== prevProps.filterModel.type) {
      this.performSearch(this.state.value);
    }
  }

  performSearch(value) {
    this.setState({ value });
    this.props.onChange(value);
  }

  render() {
    const { title, onFocusChanged } = this.props;
    const { value } = this.state;

    return (
      <div className="m-0 p-0 col w-100">
        <Input
          value={value}
          title={title}
          onChange={v => {
            this.performSearch(v);
          }}
          onFocusChanged={onFocusChanged}
        />
      </div>
    );
  }
}

export default FreeTextFilter;
