import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { Multiselect } from '../multi-select/multi-select';
import { updateProject } from '@united-talent-agency/julius-frontend-store';
import { useDispatch } from 'react-redux';
import { notify } from 'react-notify-toast';
import { icons } from '@united-talent-agency/julius-frontend-components';

const useStyles = makeStyles({
  card: {
    overflow: 'unset',
    marginBottom: '20px',
    boxShadow: 'none',
  },
  editCard: {
    backgroundColor: 'rgb(144, 226, 211)',
  },
  title: {
    fontWeight: '300',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    padding: '15px 0 5px 15px',
  },
  button: {
    background: 'none',
    border: 0,
    outline: 0,
    margin: 0,
    cursor: 'pointer',
    lineHeight: 0,
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    '&:focus': {
      outline: 'none',
    },
  },
  MuiCardActions: {
    root: {
      padding: 0,
    },
  },
  icon: {
    marginRight: 5,
    fontWeight: 900,
  },
  confirmIcon: {
    ...icons.checkBlack,
    height: '10px',
  },
  declineIcon: icons.smallCrossBlack,
});

export const ProfileCard = ({ projectProfiles, project }) => {
  const dispatch = useDispatch();
  const setInitialState = () => {
    return projectProfiles && projectProfiles.length > 0
      ? projectProfiles.map(profile => {
          return { label: profile, value: profile };
        })
      : [];
  };
  const selectedOptions = setInitialState();
  const [selected, setSelected] = useState(selectedOptions);
  const [profiles, setProfiles] = useState(
    selected && selected.length > 0 ? selected.map(profile => profile.value) : []
  );
  const classes = useStyles();
  const sendProfileUpdate = () => {
    dispatch(updateProject(project._id, { projectProfiles: profiles }));
    notify.show(`Profiles Saved`, 'custom', 2000, { background: '#000000', text: '#FFFFFF' });
  };
  const cancelProfileUpdate = () => {
    setSelected(selectedOptions);
    setProfiles(selectedOptions.map(profile => profile.value));
  };

  const profilesToCompare = projectProfiles ? projectProfiles : [];
  const noChange =
    profilesToCompare.length === profiles.length &&
    profilesToCompare.every(profile => profiles.includes(profile)) === true;
  return (
    <Card className={classnames(noChange ? classes.card : classes.editCard, classes.card)}>
      <CardContent className={classnames(!noChange && classes.cardContent)}>
        <div className={classes.header}>
          <Typography className={classes.title}>Profiles</Typography>
          <CardActions style={{ padding: 'unset' }}>
            {noChange ? null : (
              <React.Fragment>
                <button data-testid="profile-cancel" className={classes.button} onClick={() => cancelProfileUpdate()}>
                  <i className={classnames(classes.icon, classes.declineIcon)} />
                  Cancel
                </button>
                <button data-testid="profile-save" className={classes.button} onClick={() => sendProfileUpdate()}>
                  <i className={classnames(classes.icon, classes.confirmIcon)} />
                  Save
                </button>
              </React.Fragment>
            )}
          </CardActions>
        </div>
      </CardContent>
      <Multiselect
        projectProfiles={projectProfiles}
        project={project}
        selected={selected}
        setSelected={setSelected}
        setProfiles={setProfiles}
      />
    </Card>
  );
};
