import React from 'react';
import ContentLoader from 'react-content-loader';

const DataTableLoader = () => {
  return (
    <ContentLoader speed={2} backgroundColor="#f5f5f5" width={100} foregroundColor="#ecebeb" uniqueKey={'loader'}>
      <rect x="0" y="10" rx="3" ry="3" width="140" height="10" />
      <rect x="0" y="40" rx="3" ry="3" width="80" height="10" />
      <rect x="0" y="70" rx="3" ry="3" width="60" height="10" />
    </ContentLoader>
  );
};

export default DataTableLoader;
