import * as React from 'react';
import { styled } from 'react-free-style';

import LogLineCopyToClipboard from '../copy-to-clipboard/copy-to-clipboard';

import { Input, EditButton, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import classNames from 'classnames';

export class LogLine extends React.Component {
  // eslint-disable-next-line no-undef
  state = {
    showSaved: false,
    isShowAll: true,
    logLine: this.props ? this.props.project && this.props.project.log_line : '',
  };

  resetState() {
    return {
      isEditing: false,
      logLine: this.props.project.log_line,
    };
  }

  cancel() {
    this.setState(this.resetState());
    this.props.setEdit(false);
  }

  save() {
    if (this.state.logLine !== this.props.project.log_line || !this.props.project.log_line) {
      this.props.saveChanges({
        updates: {
          project: {
            [this.props.project._id]: {
              log_line: this.state.logLine,
            },
          },
        },
      });
    }
    this.props.setEdit(false);
    this.setState(this.resetState());
  }

  render() {
    const { styles, editing } = this.props;
    const logLine = this.props.project.log_line;
    const { isEditing } = this.state;

    if (isEditing || editing) {
      return (
        <React.Fragment>
          <Input
            id="logLine"
            type="text"
            title="Log Line"
            value={logLine}
            className={styles.input}
            placeholderText="enter a logline"
            inputClassName={styles.inputClassName}
            onChange={logLine => this.setState({ logLine })}
          />
          <div className={styles.actionButtons}>
            <EditButton
              type={buttonTypes.cancel}
              className={classNames(styles.icon, 'cancelButton')}
              onClick={() => this.cancel()}
            />
            <EditButton
              type={buttonTypes.save}
              className={classNames(styles.icon, 'saveButton')}
              onClick={e => {
                e.preventDefault();
                this.save();
              }}
            />
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className={styles.wrapper}>
        <div
          className={styles.container}
          onClick={() => {
            this.setState({ isEditing: true });
          }}
        >
          <label className={styles.inputLabel}>Log Line</label>
          <span className={styles.logLine} onDoubleClick={() => this.setState({ isEditing: true })}>
            {logLine}
          </span>
        </div>
        <div className={styles.copyIcon}>
          <LogLineCopyToClipboard logLine={logLine} />
        </div>
      </div>
    );
  }
}

const withStyles = styled({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  containerInput: {},
  inputLabel: {
    flex: 1,
    fontSize: '12px',
    fontWeight: 300,
    margin: '5px 0',
    textTransform: 'uppercase',
  },
  logLineInput: {
    dataPlaceholder: 'Make a new tag',
  },
  input: {
    flex: 1,
  },
  inputClassName: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    borderRadius: 0, // Remove iOS rounded corners.
    outline: 0,
    width: '100%',
    '&[disabled]': {
      background: '#F5F5F5',
    },
  },
  logLine: {
    position: 'relative',
    fontSize: 12,
    fontWeight: 300,
    marginRight: '20px',
    width: '100%',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '15%',
  },
});

export default withStyles(LogLine);
