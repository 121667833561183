import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';

function SimpleBadge({ tag }) {
  const [backgroundColor] = useState('primary');
  return (
    <div className="badge">
      <Chip
        color={backgroundColor}
        label={
          tag.property === 'createdStart' || tag.property === 'createdEnd' || tag.name === true ? tag.label : tag.name
        }
        onDelete={() => {
          tag.onRemoveBadge();
        }}
        onClick={() => {
          tag.onRemoveBadge();
        }}
      />
    </div>
  );
}
export { SimpleBadge };
