import React from 'react';
import { SimpleBadge } from './simple-badge';
import { styled } from 'react-free-style';

const CastingBadgesSansStyles = props => {
  return props && props.badges && props.badges.length > 0 ? (
    <div className="badges">
      <button label="reset" className={props.styles.clearFiltersButton} onClick={() => props.resetFilters()}>
        Reset
      </button>
      {props.badges && props.badges.length > 0 && props.badges.map((tag, i) => <SimpleBadge key={i} tag={tag} />)}
    </div>
  ) : null;
};
const withStyles = styled({
  clearFiltersButton: {
    marginTop: '0.5em',
    fontSize: '80%',
    color: 'gray',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none !important',
  },
});
const CastingBadges = withStyles(CastingBadgesSansStyles);

export { CastingBadges };
