import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { helpers } from 'react-free-style/dist/react-free-style';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';

import { personProfileLink } from '../../../support/person';

import { colors, icons, sizes } from '@united-talent-agency/julius-frontend-components';

const Activity = ({ tracking, styles }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    setChanges(tracking?.changes?.reduce((acum, cv) => {
      if('addedTracking' in (cv?.delta || {})){
        acum.push({ ...cv, project: cv.delta.addedTracking, action: 'Added' });
      }
      else if('removedTracking' in (cv?.delta || {})){
        acum.push({ ...cv, project: cv.delta.removedTracking, action: 'Removed' });
      }
      return acum;
    }, []) || []);
    setIsLoading(!tracking);
  }, [tracking]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <table id={'scriptTable'} className={isLoading ? 'd-flex flex-column w-100' : styles.table}>
          <thead>
            <tr className={styles.th}>
              <th className={isLoading ? 'pr-5' : styles.thText}>Date</th>
              <th className={isLoading ? 'px-5' : styles.thText}>Project Name</th>
              <th className={isLoading ? 'px-5' : styles.thText}>Action</th>
              <th className={isLoading ? 'px-5' : styles.thText}>User</th>
            </tr>
          </thead>
          <tbody>
            { !isLoading && changes.sort((a, b) => b.timestamp - a.timestamp).map(change => (
              <tr key={change?._id} className={styles.td}>
                <td>{moment(new Date(change.timestamp)).format('YYYY-MM-DD')}</td>
                <td>{change.project}</td>
                <td>{change.action}</td>
                <td>
                  <p className={styles.person}>
                    {change.userId._id !== undefined ? (
                      <a
                        className={styles.profileLink}
                        href={personProfileLink(change.userId._id, false)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {change.userId?.first_name + ' ' + change.userId?.last_name}
                      </a>
                    ) : (
                      change.userId?.first_name + ' ' + change.userId?.last_name || ''
                    )}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};


const withStyles = styled({
  content: helpers.merge(sizes.container, {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
  }),
  container: {
    backgroundColor: colors.background
  },
  addContainer: helpers.merge(sizes.container, {
    paddingTop: 25,
    background: 'white',
    border: '1px black solid',
  }),
  spinnerContainer: {
    position: 'absolute',
    left: '50vw',
    top: '50vh',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  td: {
    '> td': {
      padding: 10,
      fontSize: 11,
      fontWeight: 300,
      color: colors.text,
    },
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
    '&:hover': {
      border: `1px solid ${colors.focusBorder}`,
      backgroundColor: colors.focusBackground,
    },
  },
  person: {
    margin: 0,
    width: '150px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
  },
  profileLink: {
    cursor: 'pointer',
    color: '#000',
  },
  starActiveIcon: icons.starActive,
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
  },
  loading: {
    textAlign: 'center',
    padding: 10,
  },
  menu: helpers.merge(
    {
      display: 'flex',
      marginBottom: 20,
    },
    sizes.container
  ),
});

const withState = connect(state => {
  return state;
});

export default React.memo(withState(withStyles(Activity)));