import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Linkify from 'react-linkify';
import { colors } from '@united-talent-agency/julius-frontend-components';

import NoteButtons from '../note-buttons';
import { personProfileLink } from '../../../../../../support/person';
import Verification from '../../../../../../components/verification/verification';
import { searchObject } from '../../../../../../support/algolia/algolia-search-client';
import { getPeopleIndex } from '../../../../../../support/algolia/get-index';

const Note = ({ note, onDeleteItem, onEditItem, onArchiveNote, classes, showInternalColumn }) => {
  const name = note && note.personId ? note.personId.name : '';
  const client = note && note.pertainingToId && note.pertainingToId.name ? note.pertainingToId.name : '';
  const date = note && note.created_date ? note.created_date.split('T')[0] : 'N/A';
  let _note = note && note.note ? note.note : '';
  _note = _note.split('\n');

  const isSteward = false;

  return (
    <tr className={classes.td} onDoubleClick={() => onEditItem(note)} key={note ? note._id : ''}>
      <td className={classes.personName}>
        {name ? (
          <a
            className={classes.profileLink}
            href={personProfileLink(note.personId, isSteward)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        ) : (
          ''
        )}
      </td>
      <td className={classes.column}>{date}</td>
      <td className={classes.column}>
        <ClientInput note={note} client={client} />
      </td>
      <td className={`${classes.column} pl-2`}>{note.status}</td>
      <td>
        <span className={classes.note}>
          {_note.map((note, index) => (
            <div key={index}>
              <Linkify>{note}</Linkify>
            </div>
          ))}
        </span>
      </td>

      {showInternalColumn && (
        <td className={classes.column}>{note.internal === undefined ? '' : note.internal ? 'Yes' : 'No'}</td>
      )}
      <td className={classes.column}>
        <NoteButtons note={note} onEditItem={onEditItem} onDeleteItem={onDeleteItem} onArchiveNote={onArchiveNote} />
      </td>
    </tr>
  );
};

const styles = {
  td: {
    '&:hover': {
      borderBottom: `1px solid ${colors.border}`,
      backgroundColor: colors.focusBackground,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
    fontWeight: 100,
    fontSize: '11px',
  },
  personName: {
    width: '125px',
    padding: '10px 0',
  },
  column: {
    width: '85px',
    padding: '10px 0',
  },
  note: {
    flex: 1,
  },
  profileLink: {
    padding: '10px',
    fontSize: '11px',
    fontWeight: 400,
    color: colors.text,
    cursor: 'pointer',
  },
};

export default withStyles(styles)(Note);

const ClientInput = ({ note, client }) => {
  return <>{!note?.pertainingToId ? <p>{client}</p> : <VerificationComponent note={note} name={client} />}</>;
};

const VerificationComponent = ({ note, name }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (note.pertainingToId && note.pertainingToId.verifiedBy) {
      fetchData();
    }
  }, [note?.pertainingToId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getVerificationData = async () => {
    const index = getPeopleIndex();
    const res = await searchObject(index, note.pertainingToId._id);
    return res;
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await getVerificationData();
    setData(data);
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      {loading ? (
        <div>{''}</div>
      ) : (
        <div className="d-flex">
          <span>{name} </span> {data && <Verification data={data} containerStyles="ml-1" />}
        </div>
      )}
    </React.Fragment>
  );
};
