import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import helper from 'immutability-helper';
import classnames from 'classnames';
import { EditButton } from '../edit-button/edit-button';
import { SaveButton } from '../save-button/save-button';
import { CancelButton } from '../cancel-button/cancel-button';
import { NewButton } from '../new-button/new-button';
import { isValidUrl } from '../project-info/edit-project-info';
import { notify } from 'react-notify-toast';
class Card extends Component {
  // eslint-disable-next-line no-undef
  state = {
    isEditing: false,
    creates: {},
    updates: {},
    deletes: {},
  };

  updateBy(type, id, data) {
    return this.setState({
      updates: helper(this.state.updates, {
        [type]: {
          $apply: x =>
            helper(x || {}, {
              [id]: {
                $apply: x => helper(x || {}, { $merge: data }),
              },
            }),
        },
      }),
    });
  }

  createBy(type, index, data) {
    if (typeof index === 'number') {
      return this.setState({
        creates: helper(this.state.creates, {
          [type]: {
            [index]: {
              $merge: data,
            },
          },
        }),
      });
    }

    return this.setState({
      creates: helper(this.state.creates, {
        [type]: {
          $apply: x => helper(x || [], { $push: [data] }),
        },
      }),
    });
  }

  deleteBy(type, id) {
    return this.setState({
      deletes: helper(this.state.deletes, {
        [type]: {
          $apply: x =>
            helper(x || {}, {
              [id]: {
                $set: true,
              },
            }),
        },
      }),
    });
  }

  reset() {
    this.setState({ creates: {}, updates: {}, deletes: {}, isEditing: false });
    this.props.setIsEditing(false);
  }

  save() {
    const { creates, updates, deletes } = this.state;

    // check if script is a valid URL
    let isValid = true;
    if (updates && updates.project) {
      Object.values(updates.project).forEach(project => {
        const scriptUrl = String(project.script || '');
        if (!isValidUrl(scriptUrl) && scriptUrl !== '') {
          notify.show('Script needs to be a Box URL', 'custom', 2000, {
            background: ' #FF0000',
            text: '#F0F0F0',
          });
          isValid = false;
          return;
        }
      });
    }
    if (!isValid) {
      return; // If invalid, stop execution of save
    }
    this.props.saveChanges({ creates, updates, deletes });
    this.reset();
  }

  renderHeader() {
    const { canEdit, onCancel, title, classes, setIsEditing } = this.props;
    const { isEditing } = this.state;

    if (!canEdit && !title) return null;

    return (
      <div className={classnames(classes.header, isEditing ? classes.headerEdit : undefined)}>
        <h3 className={classes.title}>{title}</h3>
        {!canEdit || isEditing ? (
          undefined
        ) : (
          <EditButton
            testId="card-edit-button"
            onClick={() => {
              setIsEditing(true);
              this.setState({ isEditing: true });
            }}
          />
        )}
        {isEditing && (
          <CancelButton
            testId="card-cancel-button"
            onClick={() => {
              onCancel && onCancel();
              this.reset();
            }}
          />
        )}
        {isEditing && <SaveButton testId="card-save-button" onClick={() => this.save()} />}
      </div>
    );
  }

  renderFooter(childProps) {
    const { onCreate, classes } = this.props;
    const { isEditing } = this.state;

    if (!isEditing || !onCreate) return null;

    return (
      <div className={classes.create}>
        <NewButton testId="card-new-button" onClick={() => onCreate(childProps)} />
      </div>
    );
  }

  render() {
    const { children, classes } = this.props;

    const childProps = {
      creates: this.state.creates,
      updates: this.state.updates,
      deletes: this.state.deletes,
      isEditing: this.state.isEditing,
      updateBy: (type, id, data) => this.updateBy(type, id, data),
      createBy: (type, index, data) => this.createBy(type, index, data),
      deleteBy: (type, id) => this.deleteBy(type, id),
    };

    return (
      <div className={classes.pane}>
        {this.renderHeader()}
        <div className={classes.body}>{children(childProps)}</div>
        {this.renderFooter(childProps)}
      </div>
    );
  }
}

const styles = {
  pane: {
    background: '#FFF',
    marginBottom: 20,
  },
  button: {
    float: 'right',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px 0px 15px',
  },
  headerEdit: {
    background: '#90E2D3',
  },
  body: {
    padding: '5px 15px',
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    margin: '5px 0',
    textTransform: 'uppercase',
  },
  create: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderTop: '1px dotted #D9D9D9',
  },
  icon: {
    marginLeft: 10,
  },
};

export default withStyles(styles)(Card);
