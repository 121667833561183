import * as React from 'react';
import { styled } from 'react-free-style';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notify } from 'react-notify-toast';

export class LogLineCopyToClipboard extends React.Component {
  render() {
    const { styles } = this.props;

    return (
      <span>
        {this.props.logLine ? (
          <div className={styles.buttons}>
            <CopyToClipboard onCopy={this.onCopy} text={this.props.logLine}>
              <div
                className={`fa fa-clipboard ${styles.clipboard}`}
                onClick={() => {
                  notify.show('Copied Log Line', 'success', 5000);
                }}
              />
            </CopyToClipboard>
          </div>
        ) : null}
      </span>
    );
  }
}

const withStyles = styled({
  buttons: {
    marginBottom: 'auto',
  },
  clipboard: {
    color: 'black',
    cursor: 'pointer',
    marginLeft: 10,
  },
});

export default withStyles(LogLineCopyToClipboard);
