import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import saveUrl from '../../assets/images/check-uta-black.svg';

const SaveButton = props => {
  const { onClick, testId } = props;
  const classes = useStyles();

  return (
    <div data-testid={testId} onClick={onClick} className={classnames(classes.container, classes.icon)}>
      <i className={classnames(classes.icon, classes.saveIcon)} />
      <span>Save</span>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: 1,
    padding: '5px 0',
    textTransform: 'uppercase',
  },
  icon: {
    marginRight: 5,
    marginLeft: 10,
  },
  saveIcon: {
    width: 10,
    height: 7,
    backgroundImage: `url(${saveUrl})`,
    display: 'inline-block',
    backgroundSize: 'cover',
  },
});

export { SaveButton };
