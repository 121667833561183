import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '@united-talent-agency/julius-frontend-components';
import { notify } from 'react-notify-toast';

import { loadTalentNotes, removeProjectNote, updateProjectNote } from '@united-talent-agency/julius-frontend-store';

import NoteTableActionHeader from '../note-table-action-header';
import Note from '../note';
import ProjectNoteForm from '../project-note-form';
import DataTableLoader from '../../../../../../components/content-loader/content-loader';

const HEADERS = ['Person', 'Date', 'Client', 'Status', 'Note', 'Actions'];

const TalentNotes = ({ project, classes }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEditing] = useState(false);
  const [note, setNote] = useState({});
  const [showArchived, setShowArchived] = useState(false);
  const talentNotes = useSelector(state => state.projectNotes.talentNotes);
  const dispatch = useDispatch();

  const toggleAdd = () => {
    setModalOpen(!isModalOpen);
    setIsEditing(false);
    setNote({});
  };

  const archiveNote = async projectNote => {
    projectNote.archived = !projectNote.archived;
    await dispatch(updateProjectNote(projectNote._id, projectNote));
    await dispatch(loadTalentNotes(project._id));
  };

  useEffect(() => {
    project?._id && dispatch(loadTalentNotes(project._id));
  }, [dispatch, project]);

  const visibleNotes = showArchived ? talentNotes : talentNotes.filter(note => !note.archived);
  if (!project?._id && visibleNotes?.length === 0) {
    notify.show('Unable to load content, Please try later!', 'custom', 2000, {
      background: '#000000',
      text: '#FFFFFF',
    });
  }
  return (
    <React.Fragment>
      <NoteTableActionHeader
        isModalOpen={isModalOpen}
        toggleAdd={toggleAdd}
        showArchived={showArchived}
        onShowArchiveChange={() => setShowArchived(!showArchived)}
      />

      {isModalOpen && (
        <div className={classes.addContainer}>
          <ProjectNoteForm
            isEdit={isEdit}
            project={project}
            noteType="Talent"
            note={note}
            onDone={() => {
              setModalOpen(false);
              dispatch(loadTalentNotes(project._id));
            }}
          />
        </div>
      )}
      <table id="literaryNoteTable" className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            {HEADERS.map((header, index) => {
              return (
                <th key={index} className={classes.thText}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        {!project?._id ? (
          <tbody>
            <tr className="bg-white">
              {Array.from(Array(6).keys(7)).map(item => {
                return (
                  <td key={item} className="px-2">
                    <DataTableLoader />
                  </td>
                );
              })}
            </tr>
          </tbody>
        ) : (
          <tbody>
            {visibleNotes
              .sort((a, b) => {
                const aCreated = a.created_date ? 1 : 0;
                const bCreated = b.created_date ? 1 : 0;
                if (aCreated === bCreated) {
                  return Date.parse(b.created_date) - Date.parse(a.created_date);
                } else {
                  return bCreated - aCreated;
                }
              })
              .map((note, index) => {
                return (
                  <Note
                    dispatch={dispatch}
                    note={note}
                    isEdit={isEdit}
                    key={index}
                    onArchiveNote={archiveNote}
                    onEditItem={projectNote => {
                      setNote(projectNote);
                      setModalOpen(false); // force load person if add already open
                      setModalOpen(true);
                      setIsEditing(true);
                    }}
                    onDeleteItem={projectNote => {
                      dispatch(removeProjectNote(projectNote._id));
                      dispatch(loadTalentNotes(project._id));
                    }}
                  />
                );
              })}
          </tbody>
        )}
      </table>
    </React.Fragment>
  );
};

const styles = {
  addContainer: {
    width: '100%',
    maxWidth: '1000px',
    padding: '25px 10px 0 10px',
    margin: '0 auto',
    background: 'white',
    border: '1px black solid',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
};

export default withStyles(styles)(TalentNotes);
