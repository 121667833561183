import React, { Fragment } from 'react';
import { styled } from 'react-free-style';
import { Col, Row } from 'reactstrap';

import { Card } from '@united-talent-agency/julius-frontend-components';
import { VerificationIcon } from '@united-talent-agency/components';
import { getPeopleIndex } from '../../support/algolia/get-index';
import { searchClient } from '../../support/algolia/algolia-search-client';

/*
There is no need to include the groupBy in the 'cols' prop; by definition, it _must_ be the first element
in that list. The component makes sure of that. sortBy, however, can be whatever you want!
*/
const GroupByInfoListCard = ({ styles, title, groupBy, sortBy, weights = {}, cols = [], items = [] }) => {

  const [verificationObj, setVerificationObj] = React.useState({});
  const [sortedGroupItems, setSortedGroupItems] = React.useState([]);

  React.useEffect(() => {
    const verificationPromises = [];

    const groupedItems = items.reduce((groupedItems, currentItem) => {
      const currentGroup = currentItem[groupBy].text;
      const newItem = cols.reduce((acc, col) => {
        acc[col] = currentItem[col].text;
        return acc;
      }, {});
      const personStyle = currentItem[groupBy].style || {};
      if (groupedItems[currentGroup]) {
        groupedItems[currentGroup].value.push(newItem);
      } else {
        groupedItems[currentGroup] = { style: personStyle, value: [newItem] };
      }
      groupedItems[currentGroup].personId = currentItem[groupBy].personId;
      return groupedItems;
    }, {});
  
    const _sortedGroupItems = Object.keys(groupedItems).reduce((acc, key) => {
      const itemsToBeSorted = groupedItems[key].value;
      const sortedGroupItem = {
        ...groupedItems[key],
        ...{
          value: itemsToBeSorted.sort((a, b) => {
            return weights[a[sortBy]] - weights[b[sortBy]];
          }),
        },
      };
      acc[key] = sortedGroupItem;
      return acc;
    }, {});

    for(const value of Object.values(_sortedGroupItems)){
      const { personId } = value;
      const index = getPeopleIndex();
      const indexSetting = {
        filters: `(objectID: ${personId})`,
        hitsPerPage: 1,
        page: 0,
      };
      verificationPromises.push(searchClient(index, '', indexSetting)
        .then(({ hits: results }) => {
          setVerificationObj((prevVerificationObj) => {
            const verifiedOn = results[0]?.verifiedOn;
            const verifiedBy = results[0]?.verifiedBy?.first_name + ' ' + results[0]?.verifiedBy?.last_name;
            prevVerificationObj[personId] = {
              verifiedOn,
              verifiedBy,
            };
            return prevVerificationObj;
          });
        })
      );
    }
    if(verificationPromises.length > 0)
      Promise.all(verificationPromises).finally(() => setSortedGroupItems(_sortedGroupItems));
    else
      setSortedGroupItems(_sortedGroupItems);
    cols.unshift(groupBy);
  }, [cols, items, groupBy, sortBy, weights]);


  return (
    <Card title={title}>
      {() => {
        return (
          <div>
            <Row className={styles.header}>
              {cols.map((col, index) => (
                <Col key={index} style={{ textTransform: 'uppercase' }}>
                  {col}
                </Col>
              ))}
            </Row>
            {Object.keys(sortedGroupItems)
              .sort((a, b) => {
                const aAuspice = ((sortedGroupItems[a].value || [])[0] || {}).auspice;
                const aWeight = weights[aAuspice] || Object.keys(weights).length;
                const bAuspice = ((sortedGroupItems[b].value || [])[0] || {}).auspice;
                const bWeight = weights[bAuspice] || Object.keys(weights).length;
                return aWeight - bWeight;
              })
              .map((key, index) => {
                const entry = sortedGroupItems[key];
                return (
                  <Fragment key={index}>
                    <hr style={{ margin: 0 }} />
                    <Row className={styles.rows} key={index}>
                      {cols.map((col, index) => {
                        const iterableKey = `entry-header-${index}`;
                        if (col === groupBy) {
                          return (
                            <Col key={iterableKey}>
                              <div style={{ ...entry.style }}>
                              <span className='mr-1'>{ key || '-' }</span>
                              { verificationObj[entry.personId]?.verifiedBy && verificationObj[entry.personId]?.verifiedOn ? (
                                <VerificationIcon
                                  color="verifiedPersonIcon"
                                  verifiedBy={verificationObj[entry.personId]?.verifiedBy}
                                  verifiedOn={verificationObj[entry.personId]?.verifiedOn}
                                  identifier={verificationObj[entry.personId]?.personId}
                                  includeExplanation
                                />
                              ) : null }
                              </div>
                            </Col>
                          );
                        }
                        return (
                          <Col key={iterableKey}>
                            {entry.value.map((e, index) => (
                              <div key={`entry-${index}`}>{e[col]}</div>
                            ))}
                          </Col>
                        );
                      })}
                    </Row>
                  </Fragment>
                );
              })}
          </div>
        );
      }}
    </Card>
  );
};

const withStyles = styled({
  header: {
    fontSize: 12,
    fontWeight: 100,
    marginTop: 2,
    marginBottom: 2,
    color: 'gray',
  },
  rows: {
    fontWeight: 300,
    fontSize: 12,
    marginTop: 6,
    marginBottom: 6,
  },
  castingDetails: {
    fontWeight: 300,
  },
  boldedCastingName: {
    fontWeight: 800,
  },
});

export default withStyles(GroupByInfoListCard);
