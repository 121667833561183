import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { styled } from 'react-free-style';

import ActionButtons from '../../../../../components/action-buttons/action-buttons';

const TableHeader = props => {
  const { buttons = [], styles } = props;

  return (
    <Container className={styles.searchTagsActionsContainer}>
      <Row>
        <Col xs={11} md={11} lg={11}>
          <ActionButtons buttons={buttons} />
        </Col>
      </Row>
    </Container>
  );
};

const withStyles = styled({
  searchTagsActionsContainer: {
    maxWidth: '3000px',
  },
});

export default withStyles(TableHeader);
