import React from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { colors } from '@united-talent-agency/julius-frontend-components';
import { styled } from 'react-free-style';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: '',
      focused: false,
      date: props.value ? moment(props.value) : null,
    };
  }

  render() {
    const { styles, disabled, isError, title, value } = this.props;
    const { date, focused } = this.state;

    const float = focused || date || value ? styles.float : undefined;

    return (
      <div className={'position-relative'}>
        <div className={`pl-2 position-absolute small font-weight-light ${styles.label} ${float}`}>{title}</div>
        <SingleDatePicker
          date={date}
          onDateChange={date => {
            this.setState({ date }, () => this.props.onChange(date));
          }}
          focused={focused}
          block={true}
          displayFormat="YYYY-MM-DD"
          noBorder={!isError}
          disabled={disabled}
          showDefaultInputIcon={false}
          isOutsideRange={() => false}
          numberOfMonths={1}
          inputIconPosition="after"
          placeholder={''}
          onFocusChange={({ focused }) => this.setState({ focused })}
        />
      </div>
    );
  }
}

const withStyles = styled({
  label: {
    pointerEvents: 'none',
    top: '50%',
    marginTop: '-0.5em',
    color: colors.contentColor,
    transition: '0.05s ease-in',
    zIndex: 10,
  },
  float: {
    marginTop: 0,
    top: '0.8em',
    fontSize: '0.5em',
    textTransform: 'uppercase',
  },
});

export default withStyles(Calendar);
