import React, { Component } from 'react';

import { CompanyInput } from '@united-talent-agency/julius-frontend-components';

class CompanyFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      value: '',
    };
  }

  async componentDidMount() {
    const { searchCompany, label } = this.props;
    const { value } = this.state;

    const results = await searchCompany(value, label);
    this.setState({ results });
  }

  render() {
    const { label, onSelectCompany, searchCompany, companies, onFocusChanged = () => {} } = this.props;
    const { results, value } = this.state;
    return (
      <div className="m-0 p-0 col w-100">
        <CompanyInput
          value={value}
          title={label}
          results={results}
          selectedResults={companies}
          onChange={async value => {
            this.setState({ value });
            const results = await searchCompany(value, label);
            this.setState({ results });
          }}
          onSelect={async company => {
            onSelectCompany(company);
            const value = '';
            const results = await searchCompany(value, label);
            this.setState({ value, results });
          }}
          onFocusChanged={onFocusChanged}
          dropDownMode="inline"
        />
      </div>
    );
  }
}

export default CompanyFilter;
